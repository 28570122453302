<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole" ref="total">
        <div class="makeHole1">
        <kefu @toptop="topTop"></kefu>
          <div class="topback"  :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}">
            <headtip  :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
          </div>
            <div @click="changeheadVisable">
        <div class="background">
            <!--     文字-->
            <div class="titlle1">智能采油气解决方案</div>
            <div class="title4">*该解决方案是现场的油气开发技术服务与人工智能模型融合，通过现场的解决方案为客户提供服务，如需对该方案进一步了解合作，请随时与我们联系。</div>
            <div class="titlle3" @click="dialogVisible=true">
              解决方案咨询
            </div>


          </div>

          <div class="screenmake0">
            <div class="heighLight">智能采油气服务总览</div>
            <div class="heightLight2">安东是专业的采油气技术一体化服务提供商，拥有高素质和经验丰富的采油气技术服务团队、专业设计软件、国际化标准管理体系，通过全球采油技术资源整合，为国内外油田提供全生命周期的排水采气工艺技术服务，油田化学产品及技术服务，人工举升产品及技术与油田运维服务、特色采油技术服务等</div>

            <div class="heightLight3">采油气技术</div>
            <div class="line0"></div>
            <div class="grid0">
              <div class="card">
                <div class="heightLight3">排水采气技术</div>
              </div>
              <div class="card">
                <div class="line1"></div>
                <div class="heightLight3">人工举升技术</div>
              </div>
              <div class="card">
                <div class="heightLight3">采油工艺技术</div>
              </div>


            </div>

          </div>
          <div class="screenmake">
            <div class="heighLight">
              <span style="background-color: #FF6400;border-radius: 50%; width:2.2060658579vw;font-size:2vw;height:2.2060658579vw;display: flex;justify-content: center;align-items: center;color: white;  ">1</span>
              <span>&nbsp;&nbsp;智能采油工程</span>
            </div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">压裂施工参数优化示意图</div>
                    <div class="Title2">基于同区块多井次地质参数、工程参数完成模型训练，建立地质、工程参数与EUR映射关系。</div>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">智能分层注采</div>
                    <div class="Title2">应用于注采井组，解决笼统注水水驱效率低、注采比失衡、层间矛盾突出等问题。</div>
                  </div>
                </div>

              </div>
              <div class="card2">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">智能人工举升</div>
                    <div class="Title2">应用于依靠人工举升的油井，解决举升参数不合理、举升效率低等问题。</div>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div class="screenmake2">
            <div class="heighLight">
              <span style="background-color: #FF6400;border-radius: 50%; width:2.2060658579vw;font-size:2vw;height:2.2060658579vw;display: flex;justify-content: center;align-items: center;color: white;  ">2</span>
              <span>&nbsp;&nbsp;智能采气工程</span>
            </div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">智能井群能量优化管理</div>
                    <div class="Title2">应用于平台丛式气井，解决井组内低压气井水淹难复产、间歇气举、单一工艺排水效率低等问题。</div>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">智能柱塞排水采气</div>
                    <div class="Title2">应用于各类产水气井，解决气井积液水淹、井筒液体举升过程中滑脱严重、柱塞运行制度不合理等问题。</div>
                  </div>
                </div>

              </div>
              <div class="card2">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">智能泡沫排水采气</div>
                    <div class="Title2">应用于气井采全生命周期各个阶段，解决传统加药人工成本高、加药制度不合理等问题。</div>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div class="screenmake3">
            <div class="heighLight">
              <span style="background-color: #FF6400;border-radius: 50%; width:2.2060658579vw;font-size:2vw;height:2.2060658579vw;display: flex;justify-content: center;align-items: center;color: white;  ">3</span>
              <span>&nbsp;&nbsp;智能采油气解释</span>
            </div>
            <div class="grid">
              <div class="card">
                <div class="back" @click="dialog = true">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">低渗透油井含水上升规律解释</div>
                    <div class="Title2">通过收集大量低渗透油井含水数据与测录井静态数据、压裂改造数据、生产参数等数据做归一化拟合处理，得到低渗透油井含水上升规律解释图版，为低渗透油井控水生产提供理论基础。</div>
                  </div>
                  <div class="backconsult" @click="dialog = true">
                    产品咨询
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size:1.55414091470952vw;margin-left: 1vw;" class="el-icon-right"></i>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back" @click="dialog = true">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">气井积液规律解释</div>
                    <div class="Title2">通过收集大量气井积液速度、积液高度与产层、压力、产气量、管径大小等数据进行归一化拟合处理，得到气井积液规律解释图版，可以预测单井积液速度及高度，提前预防气井水淹。</div>
                  </div>
                  <div class="backconsult" @click="dialog = true">
                    产品咨询
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size:1.55414091470952vw;margin-left: 1vw;" class="el-icon-right"></i>
                  </div>
                </div>


              </div>
              <div class="card2">
                <div class="back" @click="dialog = true">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">气井全生命周期规律解释</div>
                    <div class="Title2">通过大量气井生产周期规律拟合，得出致密气、页岩气不同生命周期长短的解释图版，可以预测单井生命周期，为选择工艺配套提供理论基础。</div>
                  </div>
                  <div class="backconsult" @click="dialog = true">
                    产品咨询
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size:1.55414091470952vw;margin-left: 1vw;" class="el-icon-right"></i>
                  </div>
                </div>


              </div>
              <div class="card3">
                <div class="back" @click="dialog = true">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">水平井流入动态规律解释</div>
                    <div class="Title2">通过收集大量水平井产量与流压数据，重新绘制水平井IPR曲线解释图版，为水平井稳产提供理论基础。</div>
                  </div>
                  <div class="backconsult" @click="dialog = true">
                    产品咨询
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size:1.55414091470952vw;margin-left: 1vw;" class="el-icon-right"></i>
                  </div>
                </div>


              </div>
              <div class="card4">
                <div class="back" @click="dialog = true">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">低渗透油田液量递减规律解释</div>
                    <div class="Title2">通过收集大量低渗透油井产液量数据与测录井静态数据、压裂改造数据、生产参数等数据做归一化拟合处理，得到低渗透油井液量递减规律解释图版，为低渗透油井控液生产提供理论基础。</div>
                  </div>
                  <div class="backconsult" @click="dialog = true">
                    产品咨询
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size:1.55414091470952vw;margin-left: 1vw;" class="el-icon-right"></i>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake4">
            <div class="heighLight">
              <span style="background-color: #FF6400;border-radius: 50%; width:2.2060658579vw;font-size:2vw;height:2.2060658579vw;display: flex;justify-content: center;align-items: center;color: white;  ">4</span>
              <span>&nbsp;&nbsp;智能采油气设计</span>
            </div>
            <div class="grid">
              <div class="card" >
                <div class="back" @click="dialog = true">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">分层注采联动优化<br>设计</div>
                    <div class="Title2"> <li>分注分采层段设计</li>
                      <li>注采参数设计</li>
                      <li>注采联动设计</li></div>
                  </div>
                  <div class="backconsult" @click="dialog = true">
                    产品咨询
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size:1.55414091470952vw;margin-left: 1vw;" class="el-icon-right"></i>
                  </div>
                </div>


              </div>

              <div class="card1">
                <div class="back" @click="dialog = true">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">人工举升优化设计</div>
                    <div class="Title2" style="text-align: center">
                      <li>举升方式优化</li>
                      <li>举升参数优化</li>
                      <li>管柱结构优化</li>
                    </div>
                  </div>
                  <div class="backconsult" @click="dialog = true">
                    产品咨询
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size:1.55414091470952vw;margin-left: 1vw;" class="el-icon-right"></i>
                  </div>
                </div>


              </div>
              <div class="card2">
                <div class="back" @click="dialog = true">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">气井井群能量优化<br>管理设计</div>
                    <div class="Title2" style="text-align: center">
                      <li>能量优化管网设计</li>
                      <li>能量优化制度设计</li>
                      <li>复合排水工艺设计</li>
                    </div>
                  </div>
                  <div class="backconsult" @click="dialog = true">
                    产品咨询
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size:1.55414091470952vw;margin-left: 1vw;" class="el-icon-right"></i>
                  </div>
                </div>


              </div>
              <div class="card3">
                <div class="back" @click="dialog = true">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">柱塞排水采气设计</div>
                    <div class="Title2 " >
                      <li>柱塞类型优化</li>
                      <li>柱塞运行制度优化</li>
                      <li>卡定器下深优化</li>
                    </div>
                  </div>
                  <div class="backconsult" @click="dialog = true">
                    产品咨询
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size:1.55414091470952vw;margin-left: 1vw;" class="el-icon-right"></i>
                  </div>
                </div>


              </div>
              <div class="card4">
                <div class="back" @click="dialog = true">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">&nbsp;&nbsp;泡沫排水采气设计&nbsp;&nbsp;</div>
                    <div class="Title2" style="text-align: center"> <li>药剂配比优化</li>
                      <li>加药制度优化</li>
                      <li>加药方式优化</li></div>
                  </div>
                  <div class="backconsult" @click="dialog = true">
                    产品咨询
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size:1.55414091470952vw;margin-left: 1vw;" class="el-icon-right"></i>
                  </div>
                </div>


              </div>

            </div>

          </div>
          <div class="screenmake5">
            <div class="heighLight">成功案例</div>
            <div class="grid">
              <el-carousel indicator-position="outside" arrow="always" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img loading="lazy" :src="item.img"/>
                      </div>
                      <div class="titleDesign">
                        <div class="title1">{{item.title1}}</div>

                        <div class="title2" v-html="item.title3">  </div>
                        <div class="title1" style="margin-top: 25px">{{item.title2}}</div>
                        <div class="title2" v-html="item.title4"></div>

                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <div style="z-index: 999999999999">
            <bottomTip></bottomTip></div>
          </div>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialog"
            @hideDialog="hideConsult"
        ></consultTip>
        <resultTip
            :dialogVisible="dialogVisible"
            @hideDialog="hideDialog"
        ></resultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel" ref="totalModel">
        <div class="makeHole1">
        <kefuMobile @topMobile="topTopmobile"></kefuMobile>
          <div class="background">
            <!--      logo  you气通GPT社区 合作伙伴  登录-->
            <headtip @showDialog="showPartner"></headtip>
            <!--     文字-->
            <div class="titlle1">智能采油气解决方案</div>
            <div class="title4">*该解决方案是现场的油气开发技术服务与人工智能模型融合，通过现场的解决方案为客户提供服务，如需对该方案进一步了解合作，请随时与我们联系。</div>
            <div class="titlle3" @click="dialogVisible=true">
              解决方案咨询
            </div>


          </div>

          <div class="screenmake0">
            <div class="heighLight">智能采油气服务总览</div>
            <div class="heightLight2">安东是专业的采油气技术一体化服务提供商，拥有高素质和经验丰富的采油气技术服务团队、专业设计软件、国际化标准管理体系，通过全球采油技术资源整合，为国内外油田提供全生命周期的排水采气工艺技术服务，油田化学产品及技术服务，人工举升产品及技术与油田运维服务、特色采油技术服务等</div>

            <div class="heightLight3">采油气技术</div>
            <div class="line0"></div>
            <div class="grid0">
              <div class="card">
                <div class="heightLight3">排水采气技术</div>
              </div>
              <div class="card">
                <div class="line1"></div>
                <div class="heightLight3">人工举升技术</div>
              </div>
              <div class="card">
                <div class="heightLight3">采油工艺技术</div>
              </div>


            </div>

          </div>
          <div class="screenmake">
            <div class="heighLight">
              <span style="background-color: #FF6400;border-radius: 50%; width:21px;font-size:21px;height:21px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 3px   ">1</span>
              <span>&nbsp;&nbsp;智能采油工程</span>
            </div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">压裂施工参数优化示意图</div>
                    <div class="Title2">基于同区块多井次地质参数、工程参数完成模型训练，建立地质、工程参数与EUR映射关系。</div>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">智能分层注采</div>
                    <div class="Title2">应用于注采井组，解决笼统注水水驱效率低、注采比失衡、层间矛盾突出等问题。</div>
                  </div>
                </div>

              </div>
              <div class="card2">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">智能人工举升</div>
                    <div class="Title2">应用于依靠人工举升的油井，解决举升参数不合理、举升效率低等问题。</div>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div class="screenmake2">
            <div class="heighLight">
              <span style="background-color: #FF6400;border-radius: 50%; width:21px;font-size:21px;height:21px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 3px   ">2</span>
              <span>&nbsp;&nbsp;智能采气工程</span>
            </div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">智能井群能量优化管理</div>
                    <div class="Title2">应用于平台丛式气井，解决井组内低压气井水淹难复产、间歇气举、单一工艺排水效率低等问题。</div>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">智能柱塞排水采气</div>
                    <div class="Title2">应用于各类产水气井，解决气井积液水淹、井筒液体举升过程中滑脱严重、柱塞运行制度不合理等问题。</div>
                  </div>
                </div>

              </div>
              <div class="card2">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">智能泡沫排水采气</div>
                    <div class="Title2">应用于气井采全生命周期各个阶段，解决传统加药人工成本高、加药制度不合理等问题。</div>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div class="screenmake3">
            <div class="heighLight">
              <span style="background-color: #FF6400;border-radius: 50%; width:21px;font-size:21px;height:21px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 3px   ">3</span>
              <span>&nbsp;&nbsp;智能采油气解释</span>
            </div>
            <div class="grid">
              <div class="card"  @click="dialog = true">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">低渗透油井含水上升规律解释</div>
                    <div class="Title2">通过收集大量低渗透油井含水数据与测录井静态数据、压裂改造数据、生产参数等数据做归一化拟合处理，得到低渗透油井含水上升规律解释图版，为低渗透油井控水生产提供理论基础。</div>
                  </div>

                </div>


              </div>
              <div class="card1"  @click="dialog = true">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">气井积液规律解释</div>
                    <div class="Title2">通过收集大量气井积液速度、积液高度与产层、压力、产气量、管径大小等数据进行归一化拟合处理，得到气井积液规律解释图版，可以预测单井积液速度及高度，提前预防气井水淹。</div>
                  </div>

                </div>


              </div>
              <div class="card2"  @click="dialog = true">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">气井全生命周期规律解释</div>
                    <div class="Title2">通过大量气井生产周期规律拟合，得出致密气、页岩气不同生命周期长短的解释图版，可以预测单井生命周期，为选择工艺配套提供理论基础。</div>
                  </div>

                </div>


              </div>
              <div class="card3"  @click="dialog = true">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">水平井流入动态规律解释</div>
                    <div class="Title2">通过收集大量水平井产量与流压数据，重新绘制水平井IPR曲线解释图版，为水平井稳产提供理论基础。</div>
                  </div>

                </div>


              </div>
              <div class="card4"  @click="dialog = true">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">低渗透油田液量递减规律解释</div>
                    <div class="Title2">通过收集大量低渗透油井产液量数据与测录井静态数据、压裂改造数据、生产参数等数据做归一化拟合处理，得到低渗透油井液量递减规律解释图版，为低渗透油井控液生产提供理论基础。</div>
                  </div>

                </div>


              </div>
            </div>

          </div>
          <div class="screenmake4">
            <div class="heighLight">
              <span style="background-color: #FF6400;border-radius: 50%; width:21px;font-size:21px;height:21px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 3px   ">4</span>
              <span>&nbsp;&nbsp;智能采油气设计</span>
            </div>
            <div class="grid">
              <div class="card"  @click="dialog = true">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">分层注采联动优化设计</div>
                    <div class="Title2"> <li>分注分采层段设计</li>
                      <li>注采参数设计</li>
                      <li>注采联动设计</li></div>
                  </div>

                </div>


              </div>

              <div class="card1"  @click="dialog = true">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">人工举升优化设计</div>
                    <div class="Title2">
                      <li>举升方式优化</li>
                      <li>举升参数优化</li>
                      <li>管柱结构优化</li>
                    </div>
                  </div>

                </div>


              </div>
              <div class="card2"  @click="dialog = true">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">气井井群能量优化管理设计</div>
                    <div class="Title2">
                      <li>能量优化管网设计</li>
                      <li>能量优化制度设计</li>
                      <li>复合排水工艺设计</li>
                    </div>
                  </div>

                </div>


              </div>
              <div class="card3"  @click="dialog = true">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">柱塞排水采气设计</div>
                    <div class="Title2 " >
                      <li>柱塞类型优化</li>
                      <li>柱塞运行制度优化</li>
                      <li>卡定器下深优化</li>
                    </div>
                  </div>

                </div>


              </div>
              <div class="card4"  @click="dialog = true">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">&nbsp;&nbsp;泡沫排水采气设计&nbsp;&nbsp;</div>
                    <div class="Title2"> <li>药剂配比优化</li>
                      <li>加药制度优化</li>
                      <li>加药方式优化</li></div>
                  </div>

                </div>


              </div>

            </div>

          </div>
          <div class="screenmake5">
            <div class="heighLight">成功案例</div>
            <div class="grid">
              <el-carousel indicator-position="outside" arrow="always" interval="5000">
                <el-carousel-item v-for="item in successMobel" :key="item">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img loading="lazy" :src="item.img"/>
                      </div>
                      <div class="titleDesign">
                        <div class="title1">{{item.title1}}</div>

                        <div class="title2" v-html="item.title3">  </div>
                        <div class="title1">{{item.title2}}</div>
                        <div class="title2" v-html="item.title4"></div>

                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialog"
            @hideDialog="hideConsult"
        ></consultTip>
        <resultTip
            :dialogVisible="dialogVisible"
            @hideDialog="hideDialog"
        ></resultTip>
      </div>
    </div>
  </div>


</template>


<script>

import headtip from "@/views/headTip.vue";
import kefu from "@/views/kefu.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import bottomTip from "@/views/bottomTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import resultTip from "@/components/ResultTip.vue";
import consultTip from "@/components/consultTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {resultTip,consultTip, PartnerTip, headtip,bottomTip,kefu,kefuMobile},

  data() {
    return {
      changeVisable:1,
      pageTitle: '智能采油气解决方案_油气开发作业GPT应用项目服务-油气通GPT',
      pageDescription: '安东是专业的采油气技术一体化服务提供商，拥有高素质和经验丰富的采油气技术服务团队、专业设计软件、国际化标准管理体系，通过全球采油技术资源整合，为国内外油田提供全生命周期的排水采气工艺技术服务，油田化学产品及技术服务，人工举升产品及技术与油田运维服务、特色采油技术服务等。',
      pageKeywords: '智能采油气，智能采油工程，压裂施工参数优化，智能分层注采，智能人工举升，智能采气工程，智能井群能量优化管理，智能柱塞排水采气，智能泡沫排水采气，智能采油气解释，智能采油气设计，油气开发作业GPT，油气通GPT',

      dialog:false, scrollback:false,
      model:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/success5.png"),
        title1:"智能分层注采服务",
        title2:"应用业绩",
        title3:"针对注水开发区块，采用NSGA—Ⅲ井下智能测控分层注采系统进行精细分层注采，实时采集监测注水井组井下分层压力温度、分层流量等数据，输入到NSGA—Ⅲ井下智能测控分层注采工艺脑，智能输出调整水嘴及对应油嘴大小，精准优化注采比，保持注采平衡，减缓层间矛盾，较少无效注水，提高水驱采油效率，同步通过离线数据库和知识库数据读取和拟合，优化分层注采设计。",
        title4: "在胜利油田、辽河油田开展智能分层注采工艺现场应用测试，水嘴及油嘴智能调整成功率100%，井组增产明显。",
      },{
        img:require("@/assets/success6.png"),
        title1:"智能井群能量优化管理服务",
        title2:"应用业绩",
        title3:"通过采集气井动态生产数据和压力、积液速度、积液高度等数据，输入到智能井群能量优化管理工艺脑，智能优化分配井组能量，利用平台内高压气井为低压气井进行气举排液，同时配套井口智能泡排装置和井筒智能柱塞工艺助排，精准优化泡排加药制度并自动加药助排，根据产气量、压力、积液高度，智能优化井筒柱塞运行制度，提高排水采气效率。\n",
         title4: "该系统在长宁公司页岩气平台试用，成功激活多口因积液停产的低压气井，平台日产气量平均增加33%，日产水增加28%。",

      },
      ],
      successMobel:[{
        img:require("@/assets/success5.png"),
        title1:"智能分层注采服务",
        title2:"应用业绩",
        title3:"采集数据输入工艺脑，智能优化井组能量分配，配套装置和工艺助排，精准优化运行制度，提高排水采气效率。",
        title4: "该系统在长宁公司页岩气平台试用，日产气量平均增加33%，日产水增加28%。",
      },{
        img:require("@/assets/success6.png"),
        title1:"智能井群能量优化管理服务",
        title3:"在注水开发区块，利用NSGA—Ⅲ井下智能测控系统精细管理分层注采。系统实时采集井下压力、温度、流量等数据，通过智能分析，精准调整水嘴和油嘴大小，优化注采比，保持注采平衡。此举有效缓解层间矛盾，减少无效注水，提升水驱采油效率。同时，结合离线数据库和知识库，持续优化分层注采设计，实现高效开采。\n" +
            "\n" +
            "应用业绩：在胜利油田和辽河油田，智能分层注采工艺测试显示水嘴及油嘴智能调整成功率达100%，井组增产效果显著。\n",

      },
      ],
    }

  },

  methods: {     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    topTop() {
      console.log("fuzujianchuanzhi")
      const element = this.$refs.total;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    hideConsult(){
      this.dialog = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    handleScroll() {
      if(this.model===false){
        this.scrollHeight = this.$refs.total.scrollTop;
      }
      else{
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if( this.scrollHeight<100){
        this.scrollTrue=true
      }
      else{
        this.scrollTrue=false
      }
      if( this.scrollHeight<50){
        this.scrollback=false
      }
      else{
        this.scrollback=true
      }
      console.log("gaodu",this.scrollHeight<100)
    },
  },
  beforeDestroy() {

    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);
    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);

    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '智能采油气解决方案_油气开发作业GPT应用项目服务-油气通GPT',
        pageDescription: '安东是专业的采油气技术一体化服务提供商，拥有高素质和经验丰富的采油气技术服务团队、专业设计软件、国际化标准管理体系，通过全球采油技术资源整合，为国内外油田提供全生命周期的排水采气工艺技术服务，油田化学产品及技术服务，人工举升产品及技术与油田运维服务、特色采油技术服务等。',
        pageKeywords: '智能采油气，智能采油工程，压裂施工参数优化，智能分层注采，智能人工举升，智能采气工程，智能井群能量优化管理，智能柱塞排水采气，智能泡沫排水采气，智能采油气解释，智能采油气设计，油气开发作业GPT，油气通GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .topback{
      position: fixed;
      z-index:999999999999;
      width: 100vw;
      padding: 0 0 0 2.86077411900635vw;
    }
    .background{
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      width: 100%;
      height: 72.41285537700865vh;
      background-image: url("../../assets/oil1.png");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 15.09270704573548vh;
        text-align: center;
        font-size: 3.0151935298vw;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }
      .title4 {
        text-align: center;
        margin: 8.82076637824475vh auto 0 auto;
        width: 38.45869439630272vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 8.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .screenmake0{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .heightLight3{
        margin: 5.08739184177998vh auto 5.08739184177998vh auto;
       // cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1709416522vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #2f6eff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 2.1989601386vw;
        height:7.5624227441vh;
        background: #B3D0FF;
        border: #B3D0FF 1px solid;
      }
      .line0{
        width: 40vw;
        margin:0 auto;
        border-top-right-radius: 63px;
        border-top-left-radius: 63px;
        border-top:  #B3D0FF 4px solid;
        border-left:  #B3D0FF 4px solid;
        border-right:  #B3D0FF 4px solid;;
        height: 5.831891223733vh;
      }
      .grid0{
        width: 57vw;
        margin:0 auto;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 1vw;
        .card{
          position: relative;
          margin: 0 auto;
          text-align: center;
          .line{
            position: absolute;
            height: 3.831891223733vh;
            width: 4px;
            left: 50%;
            top:-3.831891223733vh;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }
          .line1{
            position: absolute;
            height: 12.663782447466vh;
            width: 4px;
            left: 50%;
            top:-12.663782447466vh;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }
          .heightLight3{
            margin:0 auto;

           // cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.1709416522vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2f6eff;
            line-height: 3.3374536465vh;
            text-align: center;
            border-radius: 53px;
            width: fit-content;
            padding: 2.4624227441vh 2.1989601386vw;
            height:8.5624227441vh;
            background: transparent;
            border: #B3D0FF 4px solid;
          }
        }


      }
      .grid{
        margin-top:4.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 1vw;
        .card{
         // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:30.72311495673671vh ;
            background: #2F6EFF linear-gradient(to right, #CFE1FF 0%, #F0F6FF 100%);
            display: flex;

            .titleDesign{
              .Title1{
                text-align: center;
                margin:5.8124845488vh 2.5597920277vw 1.1124845488vh 2.155401502vw;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin:4.8124845488vh 2.5597920277vw 1.1124845488vh 2.155401502vw;
                text-align: justify;
                height: 4.6971569839vh;
                font-size:0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                //color:#2F6EFF;
                line-height: 3.194066749vh;
              }
            }
          }



        }
        .card1{
         // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background: #FFFFFF;
            margin-bottom: 1.5248454883vh;
            .imgDesign{
              text-align: center;
              height:54vh;
              width: 100%;
              background-image: url("../../assets/serve1.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              background-color: #2F6EFF;
              .Title1{
                color: white;
                text-align: center;
                font-size: 1.1709416522vw;

                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
              }

            }
            .backconsult{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);

              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 80%;
              transform: translateX(-50%);
            }

          }

        }

      }
    }
    .screenmake{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap:0.81317157712305vw ;
        .card{
         // cursor: pointer;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border: white 3px solid;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:50vh;
            border-radius: 9px;
            background: #FFFFFF;
            .imgDesign{
              width: 100%;
              height: 30.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/oil2.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              margin-bottom: 5vh;
              padding: 2.8124845488vh 2.0597920277vw 4.1124845488vh 2.0597920277vw;
              .Title1{
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                text-align: justify;
                margin-top: 2.5124845488vh;
                height: 4.6971569839vh;
                font-size: 0.92432120161756vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card1{
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
         // cursor: pointer;
          opacity: 1;

          .back{    border: white 3px solid;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:50vh;
            border-radius: 9px;
            background: #FFFFFF;
            .imgDesign{
              width: 100%;
              height: 30.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/oil3.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 2.8124845488vh 2.0597920277vw 4.1124845488vh 2.0597920277vw;
              .Title1{
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                text-align: justify;
                margin-top: 2.5124845488vh;
                height: 4.6971569839vh;
                font-size: 0.92432120161756vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card2{
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
         // cursor: pointer;
          opacity: 1;
          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: white 3px solid;
            height:50vh;    border: white 3px solid;
            border-radius: 9px;
            background: #FFFFFF;
            .imgDesign{
              width: 100%;
              height: 30.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/oil4.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 2.8124845488vh 2.0597920277vw 4.1124845488vh 2.0597920277vw;
              .Title1{
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 3.194066749vh;
              }
              .Title2{
                text-align: justify;
                margin-top: 2.5124845488vh;
                height: 4.6971569839vh;
                font-size: 0.92432120161756vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake2{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap:0.81317157712305vw ;
        .card{
         // cursor: pointer;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:52vh;
            border-radius: 9px;
            background: #FFFFFF;
            .imgDesign{
              width: 100%;
              height: 30.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/oil5.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 2.8124845488vh 2.0597920277vw 7.1124845488vh 2.0597920277vw;
              .Title1{
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                text-align: justify;
                margin-top: 2.5124845488vh;
                height: 4.6971569839vh;
                font-size: 0.92432120161756vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card1{
         // cursor: pointer;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:52vh;
            border-radius: 9px;
            background: #FFFFFF;
            .imgDesign{
              width: 100%;
              height: 30.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/oil6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 2.8124845488vh 2.0597920277vw 7.1124845488vh 2.0597920277vw;
              .Title1{
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                text-align: justify;
                margin-top: 2.5124845488vh;
                height: 4.6971569839vh;
                font-size: 0.92432120161756vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card2{
         // cursor: pointer;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:52vh;
            border-radius: 9px;
            background: #FFFFFF;
            .imgDesign{
              width: 100%;
              height: 30.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/oil7.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 2.8124845488vh 2.0597920277vw 7.1124845488vh 2.0597920277vw;
              .Title1{
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                text-align: justify;
                margin-top: 2.5124845488vh;
                height: 4.6971569839vh;
                font-size: 0.92432120161756vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake3{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        .card{
          cursor: pointer;
          transition:transform 0.5s ease-out;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: white 3px solid;
            height:53.87144622991347vh ;
            border-radius: 9px;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            .imgDesign{
              margin: 3.1124845488vh auto;
              text-align: center;
              height: 8.3152039555vh;
              width: 8.3152039555vh;
              background-image: url("../../assets/icon-01.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.3248454883vw;
              .Title1{
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 1.1709416522vw;
                height: 2.7194066749vh;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 3.33745364647713vh;
              }
              .Title2{
                margin-top: 6.0124845488vh;
                text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult {
              display: flex;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              align-items: center;
              justify-content: center;
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);

              color: #2168DB;
            }

            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 65%;
            }

          }
        }
        .card:hover{
          transform: translateY(-10px);
        }
        .card1{
          cursor: pointer;
          transition:transform 0.5s ease-out;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            position: relative;   border: white 3px solid;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:53.87144622991347vh ;
            border-radius: 9px;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            .imgDesign{
              margin: 3.1124845488vh auto;
              text-align: center;
              height: 8.3152039555vh;
              width: 8.3152039555vh;
              background-image: url("../../assets/icon-02.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.3248454883vw;
              .Title1{
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 1.1709416522vw;
                height: 2.7194066749vh;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 3.33745364647713vh;
              }
              .Title2{
                margin-top: 6.0124845488vh;
                text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult {
              display: flex;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              align-items: center;
              justify-content: center;
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);

              color: #2168DB;
            }

            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 65%;
            }

          }
        }
        .card1:hover{
          transform: translateY(-10px);
        }
        .card2{
          transition:transform 0.5s ease-out;
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            position: relative;   border: white 3px solid;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:53.87144622991347vh ;
            border-radius: 9px;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            .imgDesign{
              margin: 3.1124845488vh auto;
              text-align: center;
              height: 8.3152039555vh;
              width: 8.3152039555vh;
              background-image: url("../../assets/icon-03.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.3248454883vw;
              .Title1{
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 1.1709416522vw;
                height: 2.7194066749vh;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 3.33745364647713vh;
              }
              .Title2{
                margin-top: 6.0124845488vh;
                text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult {
              display: flex;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              align-items: center;
              justify-content: center;
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);

              color: #2168DB;
            }

            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 65%;
            }

          }
        }
        .card2:hover{
          transform: translateY(-10px);
        }
        .card3{
          transition:transform 0.5s ease-out;
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            position: relative;   border: white 3px solid;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:53.87144622991347vh ;
            border-radius: 9px;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            .imgDesign{
              margin: 3.1124845488vh auto;
              text-align: center;
              height: 8.3152039555vh;
              width: 8.3152039555vh;
              background-image: url("../../assets/icon-04.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.3248454883vw;
              .Title1{
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 1.1709416522vw;
                height: 2.7194066749vh;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 3.33745364647713vh;
              }
              .Title2{
                margin-top: 6.0124845488vh;
                text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult {
              display: flex;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              align-items: center;
              justify-content: center;
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);

              color: #2168DB;
            }

            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 65%;
            }

          }
        }
        .card3:hover{
          transform: translateY(-10px);
        }
        .card4{
          transition:transform 0.5s ease-out;
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            position: relative;   border: white 3px solid;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:53.87144622991347vh ;
            border-radius: 9px;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            .imgDesign{
              margin: 3.1124845488vh auto;
              text-align: center;
              height: 8.3152039555vh;
              width: 8.3152039555vh;
              background-image: url("../../assets/icon-05.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.3248454883vw;
              .Title1{
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 1.1709416522vw;
                height: 2.7194066749vh;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 3.33745364647713vh;
              }
              .Title2{
                margin-top: 6.0124845488vh;
                text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult {
              display: flex;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              align-items: center;
              justify-content: center;
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);

              color: #2168DB;
            }

            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 65%;
            }

          }
        }
        .card4:hover{
          transform: translateY(-10px);
        }
      }
    }
    .screenmake4{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);

        .card{
          transition:transform 0.5s ease-out;
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:42.87144622991347vh ;
            border-radius: 9px;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            .imgDesign{
              margin: 3.1124845488vh auto;
              text-align: center;
              height: 8.3152039555vh;
              width: 8.3152039555vh;
              background-image: url("../../assets/icon-06.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.5248454883vw;
              .Title1{
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 1.1709416522vw;
                height: 2.7194066749vh;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 3.33745364647713vh;
              }
              .Title2{
                margin-top: 6.0124845488vh;
                margin-left: 1.8vw;
                text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult {
              display: flex;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              align-items: center;
              justify-content: center;
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);

              color: #2168DB;
            }

            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 65%;
            }

          }
        }
        .card:hover{
          transform: translateY(-10px);
        }
        .card1{
          transition:transform 0.5s ease-out;
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:42.87144622991347vh ;
            border-radius: 9px;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            .imgDesign{
              margin: 3.1124845488vh auto;
              text-align: center;
              height: 8.3152039555vh;
              width: 8.3152039555vh;
              background-image: url("../../assets/icon-07.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.5248454883vw;
              .Title1{
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 1.1709416522vw;
                height: 2.7194066749vh;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 3.33745364647713vh;
              }
              .Title2{
                margin-top: 6.0124845488vh;
                margin-left: 1vw;
                text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult {
              display: flex;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              align-items: center;
              justify-content: center;
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);

              color: #2168DB;
            }

            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 65%;
            }


          }
        }
        .card1:hover{
          transform: translateY(-10px);
        }
        .card2{
          transition:transform 0.5s ease-out;
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:42.87144622991347vh ;
            border-radius: 9px;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            .imgDesign{
              margin: 3.1124845488vh auto;
              text-align: center;
              height: 8.3152039555vh;
              width: 8.3152039555vh;
              background-image: url("../../assets/icon-08.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.5248454883vw;
              .Title1{
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 1.1709416522vw;
                height: 2.7194066749vh;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 3.33745364647713vh;
              }
              .Title2{
                margin-top: 6.0124845488vh;
                margin-left: 1vw;
                text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult {
              display: flex;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              align-items: center;
              justify-content: center;
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);

              color: #2168DB;
            }

            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 65%;
            }


          }
        }
        .card2:hover{
          transform: translateY(-10px);
        }
        .card3{
          transition:transform 0.5s ease-out;
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:42.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            .imgDesign{
              margin: 3.1124845488vh auto;
              text-align: center;
              height: 8.3152039555vh;
              width: 8.3152039555vh;
              background-image: url("../../assets/icon-09.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.5248454883vw;
              .Title1{
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 1.1709416522vw;
                height: 2.7194066749vh;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 3.33745364647713vh;
              }
              .Title2{
                margin-top: 6.0124845488vh;
                margin-left: 1.8vw;
                text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult {
              display: flex;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              align-items: center;
              justify-content: center;
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);

              color: #2168DB;
            }

            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 65%;
            }


          }
        }
        .card3:hover{
          transform: translateY(-10px);
        }
        .card4{
          transition:transform 0.5s ease-out;
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:42.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            .imgDesign{
              margin: 3.1124845488vh auto;
              text-align: center;
              height: 8.3152039555vh;
              width: 8.3152039555vh;
              background-image: url("../../assets/icon-10.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.5248454883vw;
              .Title1{
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 1.1709416522vw;
                height: 2.7194066749vh;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 3.33745364647713vh;
              }
              .Title2{
                margin-top: 6.0124845488vh;
                margin-left:1vw;
                text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult {
              display: flex;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              align-items: center;
              justify-content: center;
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);

              color: #2168DB;
            }

            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 65%;
            }

          }
        }
        .card4:hover{
          transform: translateY(-10px);
        }
      }
    }
    .screenmake5{
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;
      //background-color: #ebf1fd;
      .heighLight{
        color:white;
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        .card{
          //cursor: pointer;
          margin:0 auto;
          height:65vh;
          width: 86%;
          padding:0.98887515451174vh 0 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:64vh;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width:fit-content;
              height:100%;
              opacity: 1;

              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              color: white;
              width: 30vw;
              margin:auto;
              float: right;
              .title1{
                font-size: 1.1709416522vw;
              }
              .title2{
                margin-top:10px;
                line-height: 4vh;
                font-size: 0.9243212016vw;
              }
              .success{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                column-gap: 1vw;
                margin-top: 2vh;
                .suc1{
                  width: 100%;
                  height: 8vh;
                  .title3{
                    text-align: center;
                    margin-top: 2vh;
                    font-size: 0.8243212016vw;
                  }

                }

              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:68vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 550px;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
         // cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }
  }

}
.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .background{
      //z-index: 3;
      position: relative;
      width: 100%;
      height: 460px;
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      background-image: url("../../assets/gptModel41.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin:50px auto;
        width: fit-content;
        text-align: center;
        height: 23px;
        font-size: 23px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #111111;
        line-height: 8.2373300371vh;
      }
      .titlle3{
        position: absolute;
        bottom:75px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }
      .title4{
        position: absolute;
        left:10%;
        bottom:-35px;
        margin: 35px auto 0 auto;
        width: 80%;
        height: fit-content;
        font-size: 12px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 18px;
        border-radius: 15px;
        padding: 15px;
        background:white;
        border: #e8eefa 1px solid;
      }
    }
    .screenmake0{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:50px 4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .heightLight3{
        margin: 0 auto 25px auto;
       // cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size:16px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #2f6eff;
        line-height: 22px;
        text-align: center;
        border-radius: 9px;
        width: fit-content;
        padding: 10px 15px;
        height:40px;
        background: #B3D0FF;
        border: #B3D0FF 1px solid;
      }
      .line0{
        width: 245px;
        border-top-right-radius: 63px;
        border-top-left-radius: 63px;
        border-top:  #B3D0FF 4px solid;
        border-left:  #B3D0FF 4px solid;
        border-right:  #B3D0FF 4px solid;;
        height: 35px;
        margin:0 auto;
      }
      .grid0{
        width: 350px;
        margin:0 auto;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 5px;

        .card{
          position: relative;
          margin: 0 auto;
          text-align: center;
          .line{
            position: absolute;
            height: 35px;
            width: 4px;
            left: 50%;
            top:-35px;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }
          .line1{
            position: absolute;
            height: 75px;
            width: 4px;
            left: 50%;
            top:-75px;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }
          .heightLight3{
            margin:0 auto;

           // cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 8px;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2f6eff;
            line-height: 16px;
            text-align: center;
            border-radius: 9px;
            width: fit-content;
            padding: 5px 5px;
            height:45px;
            background: transparent;
            border: #B3D0FF 4px solid;
          }
        }


      }

    }
    .screenmake{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:  #ebf1fd;;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 26px;

      }


      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        row-gap: 15px;
        .card{
         // cursor: pointer;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            border-radius: 9px;
            background: #FFFFFF;
            .imgDesign{
              width: 100%;
              height: 230px;
              opacity: 1;
              background-image: url("../../assets/oil2.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5px 15px;
              .Title1{
                margin: 10px auto auto 8px;
                text-align: center;
                height: 20px;
                font-size: 16px;
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
        .card1{
         // cursor: pointer;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            border-radius: 9px;
            background: #FFFFFF;
            .imgDesign{
              width: 100%;
              height: 230px;
              opacity: 1;
              background-image: url("../../assets/oil3.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5px 15px;
              .Title1{
                margin: 10px auto auto 8px;
                text-align: center;
                height: 20px;
                font-size: 16px;
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
        .card2{
         // cursor: pointer;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            border-radius: 9px;
            background: #FFFFFF;
            .imgDesign{
              width: 100%;
              height: 230px;
              opacity: 1;
              background-image: url("../../assets/oil4.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5px 15px;
              .Title1{
                margin: 10px auto auto 8px;
                text-align: center;
                height: 20px;
                font-size: 16px;
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
      }
    }

    .screenmake2{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 26px;

      }


      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        row-gap: 15px;
        .card{
         // cursor: pointer;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            border-radius: 9px;
            background:  #ebf1fd;;
            .imgDesign{
              width: 100%;
              height: 230px;
              opacity: 1;
              background-image: url("../../assets/oil5.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5px 15px;
              .Title1{
                margin: 10px auto auto 8px;
                text-align: center;
                height: 20px;
                font-size: 16px;
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
        .card1{
         // cursor: pointer;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            border-radius: 9px;
            background:  #ebf1fd;;
            .imgDesign{
              width: 100%;
              height: 230px;
              opacity: 1;
              background-image: url("../../assets/oil6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5px 15px;
              .Title1{
                margin: 10px auto auto 8px;
                text-align: center;
                height: 20px;
                font-size: 16px;
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
        .card2{
         // cursor: pointer;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            border-radius: 9px;
            background:  #ebf1fd;;
            .imgDesign{
              width: 100%;
              height: 230px;
              opacity: 1;
              background-image: url("../../assets/oil7.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5px 15px;
              .Title1{
                margin: 10px auto auto 8px;
                text-align: center;
                height: 20px;
                font-size: 16px;
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
      }
    }

    .screenmake3{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: #ebf1fd;;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 26px;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background-color: white;;
            padding: 7px 15px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 58px;
              height: 100%;
              background-image: url("../../assets/icon-01.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 7px 0 15px;
              .Title1{
                opacity: 4;
                width:100%;
                white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: bold;
                line-height: 26px;
              }
              .Title2{
                opacity: 4;
                width: 230px;
                margin: 2px 0 10px 0;
                text-align: justify;
                font-size: 12px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
        .card1{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background-color: white;;
            padding: 7px 15px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 58px;
              height: 100%;
              background-image: url("../../assets/icon-02.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 7px 0 15px;
              .Title1{
                opacity: 4;
                width:100%;
                white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: bold;
                line-height: 26px;
              }
              .Title2{
                opacity: 4;
                width: 230px;
                margin: 2px 0 10px 0;
                text-align: justify;
                font-size: 12px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
        .card2{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background-color: white;;
            padding: 7px 15px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 58px;
              height: 100%;
              background-image: url("../../assets/icon-03.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 7px 0 15px;
              .Title1{
                opacity: 4;
                width:100%;
                white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: bold;
                line-height: 26px;
              }
              .Title2{
                opacity: 4;
                width: 230px;
                margin: 2px 0 10px 0;
                text-align: justify;
                font-size: 12px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
        .card3{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background-color: white;;
            padding: 7px 15px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 58px;
              height: 100%;
              background-image: url("../../assets/icon-04.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 7px 0 15px;
              .Title1{
                opacity: 4;
                width:100%;
                white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: bold;
                line-height: 26px;
              }
              .Title2{
                opacity: 4;
                width: 230px;
                margin: 2px 0 10px 0;
                text-align: justify;
                font-size: 12px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
        .card4{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background-color: white;;
            padding: 7px 15px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 58px;
              height: 100%;
              background-image: url("../../assets/icon-05.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 7px 0 15px;
              .Title1{
                opacity: 4;
                width:100%;
                white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: bold;
                line-height: 26px;
              }
              .Title2{
                opacity: 4;
                width: 230px;
                margin: 2px 0 10px 0;
                text-align: justify;
                font-size: 12px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }


      }
    }

    .screenmake4{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: white;;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 26px;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background:#FFFFFF linear-gradient(to right, #CFE1FF 0%, #F0F6FF 100%);
            padding: 7px 15px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 58px;
              height: 100%;
              background-image: url("../../assets/icon-06.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 7px 0 15px;
              .Title1{
                opacity: 4;
                width:100%;
                white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: bold;
                line-height: 26px;
              }
              .Title2{
                opacity: 4;
                width: 230px;
                margin: 2px 0 10px 0;
                text-align: justify;
                font-size: 12px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
        .card1{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background:#FFFFFF linear-gradient(to right, #CFE1FF 0%, #F0F6FF 100%);
            padding: 7px 15px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 58px;
              height: 100%;
              background-image: url("../../assets/icon-07.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 7px 0 15px;
              .Title1{
                opacity: 4;
                width:100%;
                white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: bold;
                line-height: 26px;
              }
              .Title2{
                opacity: 4;
                width: 230px;
                margin: 2px 0 10px 0;
                text-align: justify;
                font-size: 12px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
        .card2{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background:#FFFFFF linear-gradient(to right, #CFE1FF 0%, #F0F6FF 100%);
            padding: 7px 15px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 58px;
              height: 100%;
              background-image: url("../../assets/icon-08.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 7px 0 15px;
              .Title1{
                opacity: 4;
                width:100%;
                white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: bold;
                line-height: 26px;
              }
              .Title2{
                opacity: 4;
                width: 230px;
                margin: 2px 0 10px 0;
                text-align: justify;
                font-size: 12px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
        .card3{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background:#FFFFFF linear-gradient(to right, #CFE1FF 0%, #F0F6FF 100%);
            padding: 7px 15px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 58px;
              height: 100%;
              background-image: url("../../assets/icon-09.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 7px 0 15px;
              .Title1{
                opacity: 4;
                width:100%;
                white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: bold;
                line-height: 26px;
              }
              .Title2{
                opacity: 4;
                width: 230px;
                margin: 2px 0 10px 0;
                text-align: justify;
                font-size: 12px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
        .card4{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background:#FFFFFF linear-gradient(to right, #CFE1FF 0%, #F0F6FF 100%);
            padding: 7px 15px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 58px;
              height: 100%;
              background-image: url("../../assets/icon-10.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 7px 0 15px;
              .Title1{
                opacity: 4;
                width:100%;
                white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: bold;
                line-height: 26px;
              }
              .Title2{
                opacity: 4;
                width: 230px;
                margin: 2px 0 10px 0;
                text-align: justify;
                font-size: 12px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
      }
    }

    .screenmake5{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding:20px 15px;
      background: #212a3c;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
       font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;

        .card{
         // cursor: pointer;
          width: 98%;
          margin:0 auto;
          min-height: 250px ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            min-height: 520px ;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:254px;
              opacity: 1;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 15px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:15px;
                font-size: 14px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:12px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 540px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -138px -10px  ;
         // cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}
</style>
