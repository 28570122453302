<template>
  <div>
    <div v-if="model===false">
      <div ref="total" class="totalmakeHole">
        <div class="makeHole1">
          <kefu @toptop="topTop"></kefu>
          <div
              :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}"
              class="topback">
            <headtip :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
          </div>
          <div @click="changeheadVisable">
              <div class="background">
                <!--     文字-->
                <div class="titlle1">
                  数据分析
                </div>
                <div class="title4">
                  覆盖从数据接入、到数据处理、再到数据分析整个数据应用链路，满足企业各类复杂的分析需求，实现数据驱动决策。
                </div>
                <div class="titlle3" @click="clickConsult('油气通')">
                  产品咨询
                </div>


              </div>
              <div class="screenmake0">
                <div class="heighLight">方案概述</div>
                <div class="grid">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img loading="lazy" src="@/assets/analysis1.png">
                      </div>

                    </div>


                  </div>
                </div>
                <div class="gridTitle">
                  <div class="card">
                    <div class="back">
                      <div class="titleDesign">
                        <div class="title1">全链路数据建设
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="back">
                      <div class="titleDesign">
                        <div class="title1">报表设计与开发
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="back">
                      <div class="titleDesign">
                        <div class="title1">报表使用与应用
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="back">
                      <div class="titleDesign">
                        <div class="title1">报表平台管理
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="grid1">
                  <div class="card">
                    <div class="back">
                      <div class="titleDesign">
                        <div class="title1">在线表单
                        </div>
                        <div class="title2">
                          简单拖拽创建表单，发布链接回收数据

                        </div>
                      </div>
                      <div class="titleSpan">
                        <span style="color:  #dbdefd ;font-size: 4vh">“</span> 表单让数据收集提升了80%，开启了我们无纸化办公的第一步<span
                          style="color:  #dbdefd ;font-size: 4vh">”</span>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="back">

                      <div class="titleDesign">
                        <div class="title1">流程引擎

                        </div>
                        <div class="title2">
                          根据团队业务，灵活设计流程流转规则
                        </div>
                      </div>
                      <div class="titleSpan">
                        <span style="color:  #dbdefd ;font-size: 4vh">“</span> 能明显感觉到业务规则被有效落实了，协作上更加有序明确<span
                          style="color:  #dbdefd ;font-size: 4vh">”</span>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="back">
                      <div class="titleDesign">
                        <div class="title1">仪表盘
                        </div>
                        <div class="title2">
                          将收集来的数据进行统计、分析、对比
                        </div>
                      </div>
                      <div class="titleSpan">
                        <span style="color:  #dbdefd ;font-size: 4vh">“</span> 每天帮我节约4小时，现在再也不用到处复制粘贴，也不老出错了<span
                          style="color:  #dbdefd ;font-size: 4vh">”</span>
                      </div>
                    </div>


                  </div>

                </div>
              </div>
              <div class="screenmake1">
                <div class="heighLight">服务内容</div>
                <div class="grid">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img loading="lazy" src="@/assets/governance2.png">
                      </div>

                      <div class="titleDesign">
                        <div class="title1">报表分析
                        </div>
                        <div class="title2">
                          复杂报表的展示与查询，动态数据展示，提升报表统计效率。<br>&nbsp;

                        </div>
                      </div>
                      <div class="titleSpan" style="background-color: #eb7c31"></div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img loading="lazy" src="@/assets/governance3.png">
                      </div>
                      <div class="titleDesign">
                        <div class="title1">大屏可视化

                        </div>
                        <div class="title2">
                          <li>提供制作近百种特效任意组合酷炫灵动的大屏驾驶舱</li>
                          <li>快速搭建移动数据分析平台，随时随地采集与分析数据，掌握一手业务经营动态
                          </li>
                        </div>
                      </div>
                      <div class="titleSpan" style="background-color: #fdbe00"></div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img loading="lazy" src="@/assets/governance4.png">
                      </div>

                      <div class="titleDesign">
                        <div class="title1">数据决策平台
                        </div>
                        <div class="title2">
                          对报表、用户、系统系统配置进行统一管理的报表应用管理平台解决方案。<br>&nbsp;

                        </div>
                      </div>
                      <div class="titleSpan" style="background-color: #a4a4a4"></div>
                    </div>


                  </div>

                </div>

              </div>
              <div class="screenmake2">
                <div class="heighLight">成功案例</div>
                <div class="grid">
                  <el-carousel arrow="always" indicator-position="outside" interval="5000">
                    <el-carousel-item v-for="item in success" :key="item">
                      <div class="card">
                        <div class="back">
                          <div class="imgDesign">
                            <img :src="item.img" loading="lazy"/>
                          </div>
                          <div class="titleDesign">
                            <div class="title1">{{ item.title1 }}</div>
                            <div class="title2" v-html="item.title2"></div>
                          </div>
                        </div>


                      </div>


                    </el-carousel-item>
                  </el-carousel>
                </div>

              </div>

              <bottomTip></bottomTip>
            </div>
          </div>
          <partnerTip
              :dialogVisible="dialogconsult"
              @hideDialog="hide"
          ></partnerTip>
          <consultTip
              :dialogVisible="dialogVisible"
              :thisTitle="title"
              @hideDialog="hideDialog"
          ></consultTip>
        </div>
      </div>
      <div v-else>
        <div class="totalmakeHoleModel"  ref="totalModel">
          <div class="makeHole1">
            <kefuMobile @topMobile="topTopmobile"></kefuMobile>
            <div class="background">
              <!--      logo  you气通GPT社区 合作伙伴  登录-->
              <headtip @showDialog="showPartner"></headtip>
              <!--     文字-->
              <div class="layer2">
                <div class="titlle1">数据治理</div>
                <div class="title4">
                  为客户打通数据治理各个环节，可快速满足企业各类不同的数据治理场景。覆盖数据“采”、“存”、“管”、“用”、“服”各个环节，辅助业务决策，全面释放数据价值。
                </div>

              </div>
              <div class="title3" @click="clickConsult('油气通')">
                产品咨询
              </div>


            </div>
            <div class="screenmake0">
              <div class="heighLight">方案概述</div>
              <div class="grid">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/service1.png">
                    </div>
                    <div class="titleDesign">
                      <div class="title2">
                        <li>油气资讯GPTs在线服务</li>
                        <li>企业管理知识GPTs在线服务</li>
                        <li>油气专业类GPTs在线服务</li>
                        <li>通用类GPTs在线服务</li>
                      </div>
                    </div>
                  </div>


                </div>
              </div>

            </div>
            <div class="screenmake1">
              <div class="heighLight">成功案例</div>
              <div class="grid">
                <el-carousel arrow="always" indicator-position="outside" interval="5000">
                  <el-carousel-item v-for="item in success" :key="item">
                    <div class="card">
                      <div class="back">
                        <div class="imgDesign">
                          <img :src="item.img" loading="lazy"/>
                        </div>
                        <div class="titleDesign">
                          <div class="title1">{{ item.title1 }}</div>
                          <div class="title2" v-html="item.title2"></div>
                        </div>
                      </div>


                    </div>


                  </el-carousel-item>
                </el-carousel>
              </div>

            </div>

            <bottomTip></bottomTip>
          </div>
          <partnerTip
              :dialogVisible="dialogconsult"
              @hideDialog="hide"
          ></partnerTip>
          <consultTip
              :dialogVisible="dialogVisible"
              :thisTitle="title"
              @hideDialog="hideDialog"
          ></consultTip>
        </div>
      </div>
    </div>


</template>


<script>

import headtip from "@/views/headTip.vue";
import kefu from "@/views/kefu.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";

export default {

  name: "",

  props: [],

  components: {PartnerTip, consultTip, headtip, bottomTip, kefu, kefuMobile},

  data() {

    return {
      title: "",
      pageTitle: '数据治理_安迅油气通-油气通GPT',
      pageDescription: '安迅油气通智能技术有限公司专注为石油行业提供人工智能技术解决方案，提供数据治理，为客户打通数据治理各个环节，可快速满足企业各类不同的数据治理场景。覆盖数据“采”、“存”、“管”、“用”、“服”各个环节，辅助业务决策，全面释放数据价值。',
      pageKeywords: '安迅油气通，安迅油气通智能技术有限公司，油气通，油气通GPT，油气通GPT云平台',
      scrollback: false,
      changeVisable: 1,
      model: false,
      dialogconsult: false,
      dialogVisible: false,
      success: [{
        img: require("@/assets/analysis2.png"),
        title1: "某知名油服集团",
        title2: "基于公司四大核心业务流程关键节点，建成了“智慧经营”包含订单、作业、收入、应收、应付、现金流等八大板块，“智慧管理”包含市场营销、生产指挥、供应链、人力资源、技术及产建等7大板块。通过智能化、自动化减少运营成本、降低安全风险、提升工作效率。",
      },
        {
          img: require("@/assets/anslysis3.png"),
          title1: "某知名油服集团",
          title2: "基于公司四大核心业务流程关键节点，建成了“智慧经营”包含订单、作业、收入、应收、应付、现金流等八大板块，“智慧管理”包含市场营销、生产指挥、供应链、人力资源、技术及产建等7大板块。通过智能化、自动化减少运营成本、降低安全风险、提升工作效率。",
        },
        {
          img: require("@/assets/analysis4.png"),
          title1: "某知名油服集团",
          title2: "基于公司四大核心业务流程关键节点，建成了“智慧经营”包含订单、作业、收入、应收、应付、现金流等八大板块，“智慧管理”包含市场营销、生产指挥、供应链、人力资源、技术及产建等7大板块。通过智能化、自动化减少运营成本、降低安全风险、提升工作效率。",
        },
      ]
    }

  },

  methods: {    topTopmobile() {
      console.log("fuzujianchuanzhi")
      const element = this.$refs.totalModel;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    topTop() {
      console.log("fuzujianchuanzhi")
      const element = this.$refs.total;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },    changeheadVisable() {
      if (this.changeVisable === 1) {
        this.changeVisable = 0
      } else {
        this.changeVisable = 1
      }
      console.log("父组件", this.changeVisable)
    },
    clickConsult(item) {

      this.title = item
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide() {
      this.dialogconsult = false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner() {
      this.dialogconsult = true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
  },

  mounted() {
    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if (screenWidth <= 700) {
      this.model = true
    } else {
      this.model = false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home", 0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '数据治理_安迅油气通-油气通GPT',
        pageDescription: '安迅油气通智能技术有限公司专注为石油行业提供人工智能技术解决方案，提供数据治理，为客户打通数据治理各个环节，可快速满足企业各类不同的数据治理场景。覆盖数据“采”、“存”、“管”、“用”、“服”各个环节，辅助业务决策，全面释放数据价值。',
        pageKeywords: '安迅油气通，安迅油气通智能技术有限公司，油气通，油气通GPT，油气通GPT云平台',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style lang="scss" scoped type="text/scss">
.totalmakeHole {
  background: #bdd6f7;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: 100vh;

    .topback {
      position: fixed;
      z-index: 999999999999;
      width: 100vw;
      padding: 0 0 0 2.86077411900635vw;
    }

    .background {
      padding: 7.84301606922126vh 2.86077411900635vw 2.84301606922126vh 2.86077411900635vw;
      width: 100%;
      height: 72.41285537700865vh;
      background-image: url("../../assets/governance.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 15.09270704573548vh;
        text-align: center;
        font-size: 3.0151935298vw;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }

      .title4 {
        text-align: center;
        margin: 8.82076637824475vh auto 0 auto;
        width: 58.45869439630272vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 4.31520395550062vh;
      }

      .titlle3 {
        margin: 8.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }

      .titlle3:hover {

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }

    .screen1 {
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 74vh;
      padding: 2.84301606922126vh 2.86077411900635vw 2.84301606922126vh 2.86077411900635vw;
      background-image: url("../../assets/governance.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 18.1495673671199vh;
        left: 9.17157712305026vw;

        .title1 {
          width: fit-content;
          height: 5.77626699629172vh;
          font-size: 3.50606585788562vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
          line-height: 12.23733003708282vh;
        }

        .title2 {
          margin-top: 6.82076637824475vh;
          width: 33.45869439630272vw;
          height: fit-content;
          font-size: 1.0597920277vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
          line-height: 4.31520395550062vh;
        }

        .title4 {
          height: 8.77626699629172vh;
          font-size: 1.6060658579vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
          line-height: 9.31520395550062vh;
        }

        .titlle3 {
          margin-top: 2.07911001236094vh;
          //cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }

        .titlle3:hover {

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }

    .screenmake0 {
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: fit-content;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .grid {
        margin-top: 3vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              height: fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }

      .gridTitle {
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          opacity: 1;

          .back {
            background: #ffffff;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .title1 {
              opacity: 4;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 1.17094165222415vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: bold;
              color: #0063e7;
              line-height: 2.71940667490729vh;
            }

            .title2 {
              opacity: 4;
              margin: 1.94437577255871vh 1.35540150202195vw 5.94437577255871vh 1.35540150202195vw;
              text-align: justify;

              align-items: center;
              font-size: 0.80878105141537vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              //color: #2168DB;
              line-height: 2.71940667490729vh;
            }

            .titleSpan {
              height: 5px;
              width: 100%;
            }
          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }

      .grid1 {
        margin-top: 5.7873918418vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        row-gap: 1.5vh;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;

          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: #ffffff;
            border: #dbdefd 1px solid;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 45px;
              height: 45px;
              opacity: 1;
              margin: 3vh auto;
              text-align: center;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .title1 {
              opacity: 4;
              margin-top: 2.22496909765142vh;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 2.71940667490729vh;
              font-size: 1.17094165222415vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: bold;
              //color: #2168DB;
              line-height: 2.71940667490729vh;
            }

            .title2 {
              opacity: 4;
              margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
              text-align: center;
              font-size: 0.80878105141537vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              //color: #2168DB;
              line-height: 2.71940667490729vh;
            }

            .titleSpan {
              opacity: 4;
              margin: 0 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;

              font-size: 1.17094165222415vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: bold;
              //color: #2168DB;
              line-height: 4.71940667490729vh;

            }
          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake1 {
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: transparent;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height: 8.73733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: fit-content;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .grid {
        margin-top: 2.7873918418vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        row-gap: 1.5vh;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;

          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: #ffffff;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 45px;
              height: 45px;
              opacity: 1;
              margin: 3vh auto;
              text-align: center;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .title1 {
              opacity: 4;
              margin-top: 2.22496909765142vh;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 2.71940667490729vh;
              font-size: 1.17094165222415vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: bold;
              //color: #2168DB;
              line-height: 2.71940667490729vh;
            }

            .title2 {
              opacity: 4;
              margin: 1.94437577255871vh 1.35540150202195vw 5.94437577255871vh 1.35540150202195vw;
              text-align: justify;

              align-items: center;
              font-size: 0.80878105141537vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              //color: #2168DB;
              line-height: 2.71940667490729vh;
            }

            .titleSpan {
              height: 5px;
              width: 100%;
            }
          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake2 {
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;
      //background-color: #ebf1fd;
      .heighLight {
        color: white;
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }

      .grid {
        margin-top: 2.78739184177998vh;

        .card {
          ////cursor: pointer;
          margin: 0 auto;
          height: 65vh;
          width: 86%;
          padding: 0.98887515451174vh 0 0.98887515451174vh 0;
          opacity: 1;

          .back {
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 64vh;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw;
              color: white;
              width: 30vw;
              margin: auto;
              float: right;

              .title1 {
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 10px;
                line-height: 4vh;
                font-size: 0.9243212016vw;
              }

              .success {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                column-gap: 1vw;
                margin-top: 2vh;

                .suc1 {
                  width: 100%;
                  height: 8vh;

                  .title3 {
                    text-align: center;
                    margin-top: 2vh;
                    font-size: 0.8243212016vw;
                  }

                }

              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 68vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 550px;
        }

        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0;
          // //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight: 800;
          font-size: 25px;
        }
      }
    }
  }

}

.totalmakeHoleModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: 100vh;

    .topback {
      position: fixed;
      z-index: 999999999999;
      width: 100vw;
      padding: 0 0 0 2.86077411900635vw;
    }

    .background {
      padding: 2.84301606922126vh 2.86077411900635vw 2.84301606922126vh 2.86077411900635vw;
      width: 100%;
      height: 400px;
      background-image: url("../../assets/governance.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      position: relative;
      /* Add any additional styling as needed */
      .layer2 {
        position: absolute;
        left: 0;

        .titlle1 {
          margin: 25px auto 0 auto;
          width: fit-content;
          text-align: center;
          height: fit-content;
          font-size: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          color: #111111;
          line-height: 22px;

        }

        .title4 {
          margin: 25px auto;
          width: 90%;
          text-align: center;
          height: 17px;
          font-size: 12px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #111111;
          line-height: 20px;
        }
      }

      .title3 {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        line-height: 15px;
        height: 35px;
        color: white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;


      }

    }

    .screenmake0 {
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw;
      background: white;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {
        position: relative;
        height: fit-content;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .title1 {
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
          }

          .title2 {
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }

          .title3 {
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            line-height: 22px;
            font-size: 14px;
          }

          .titleDesign {
            margin-top: 10px;
          }

          .back {
            background: white;
            border-radius: 9px;
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            column-gap: 5px;
            position: relative;
            height: fit-content;
            margin-top: 15px;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              //border: #666666 1px solid;
              height: fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake1 {
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw;
      background: black;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .card {
          //cursor: pointer;
          width: 98%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 450px !important;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: 100%;
              height: fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 5px 20px 25px 20px;
              color: white;
              width: 100%;
              float: right;

              .title1 {
                margin-top: 10px;
                font-size: 16px;
              }

              .title2 {
                margin-top: 15px;
                line-height: 22px;
                font-size: 14px;

                ::v-deep li {
                  margin-top: 5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 390px !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -85px -10px;
          //cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}
</style>
