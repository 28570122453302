import { render, staticRenderFns } from "./training.vue?vue&type=template&id=aafb771c&scoped=true"
import script from "./training.vue?vue&type=script&lang=js"
export * from "./training.vue?vue&type=script&lang=js"
import style0 from "./training.vue?vue&type=style&index=0&id=aafb771c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_420b40f8fe8ddaf3248c7dea96ec9571/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aafb771c",
  null
  
)

export default component.exports