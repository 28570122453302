<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole" ref="total">
      <div class="makeHole1">
        <kefu @toptop="topTop"></kefu>
        <div class="topback"  :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}">
          <headtip  :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
        </div>
          <div @click="changeheadVisable">
        <div class="background">
          <!--     文字-->
          <div class="titlle1">智能油藏地质解决方案</div>
          <div class="title4">*该解决方案是现场的油气开发技术服务与人工智能模型融合，通过现场的解决方案为客户提供服务，如需对该方案进一步了解合作，请随时与我们联系。</div>
          <div class="titlle3" @click="dialogVisible=true">
            解决方案咨询
          </div>
        </div>
        <div class="screenmake0">
          <div class="heighLight">智能油藏地质服务总览</div>
          <div class="heightLight2">油气田开发研究院面向全局，聚焦单井油藏地质精细研究，以地质工程相结合为特色，为客户提供全面地质研究评价、开发方案编制服务，同时为安东整体解决方案提供地质风险评价服务</div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/serve1.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">结构精细解释</div>
                </div>
              </div>
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/serve2.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">三维地质模型</div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/serve3.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">甜点评价</div>
                </div>
              </div>
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/serve4.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">脆性指数</div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/serve5.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">天然裂缝预测</div>
                </div>
              </div>
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/serve6.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">天然裂缝预测</div>
                </div>
              </div>
            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/serve7.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">地质力学分析</div>
                </div>
              </div>
            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/serve8.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">地质风险评价</div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="screenmake">
          <div class="heighLight">
            <span style="background-color: #FF6400;border-radius: 50%; width:2.2060658579vw;font-size:2vw;height:2.2060658579vw;display: flex;justify-content: center;align-items: center;color: white;   ">1</span>
            <span>&nbsp;&nbsp;智能采油气解释</span>
          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">智能地震相识别</div>
                  <div class="Title2">机器学习和深度学习，实现数据预处理、特征提取、MCC计算、模型训练、地震相分类。</div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">深度学习测井岩<br>相识别</div>
                  <div class="Title2">测井数据自动识别与分类，实现波形特征、频域特征、时域特征等测井数据分类和识别。</div>
                </div>

              </div>


            </div>
            <div class="card2">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">高精度智能断层识别</div>
                  <div class="Title2">构建海量断层训练样本库，采用三维高精度卷积网络技术学习预测断裂。</div>
                </div>
              </div>


            </div>
            <div class="card3">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">高精度智能层位追踪与解释</div>
                  <div class="Title2">以少量层位骨架为样本集，采用神经网络技术，大幅度提升层位自动追踪的效率和精度。</div>
                </div>

              </div>


            </div>
            <div class="card4">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">智能储层预测</div>
                  <div class="Title2">通过人工智能算法对储层进行预测和评价，对储层的分布、厚度及岩性进行追踪和预测。</div>
                </div>

              </div>


            </div>
          </div>

        </div>
        <div class="screenmake2">

          <div class="heighLight"><span style="background-color: #FF6400;border-radius: 50%; width:2.2060658579vw;height:2.2060658579vw;font-size:2vw;display: flex;justify-content: center;align-items: center;color: white;   ">2</span>
            <span>&nbsp;&nbsp;智能测井解释</span></div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">测井物性参数智能预测</div>
                  <div class="Title2">
                    基于机器学习理论的回归模型，识别测井数据与地层物性参数之间的非线性映射关系，准确地预测储层段的孔隙度变化。

                  </div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">测井岩相类别智能预测</div>
                  <div class="Title2">以建立岩心孔隙度与岩石物理测井之间的模糊规则, 提取模糊聚类的最优参数, 提出一种新的多测井曲线数据匹配算法。</div>
                </div>
              </div>


            </div>
            <div class="card2">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">测井地层智能划分</div>
                  <div class="Title2">建立从测井曲线到岩相的目标映射函数, 预测未解释测井数据所对应的岩相类别。</div>
                </div>
              </div>


            </div>
          </div>

        </div>
        <div class="screenmake3">
          <div class="heighLight">成功案例</div>
          <div class="grid">
            <el-carousel indicator-position="outside" arrow="always" interval="5000">
              <el-carousel-item v-for="item in 1" :key="item" >
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/success1.png">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">西南J致密油气区块沙溪庙组优质储层评价及开发目标优选项目技术服务</div>
                      <div class="title1" style="margin-top: 25px;font-weight: bold">研究内容</div>
                      <div class="title2">
                        <li>储层精细刻画：根据录井、测井解释，利用岩石物理分析及模型正演，明确8砂组地震响应特征，精细刻画储层展布；</li>
                        <li style="margin-top: 0.5vh">储层分类：结合录井、测井成果，落实秋林区块8砂组河道构造及烃源断层发育情况，并开展分类评价；</li>
                        <li style="margin-top: 0.5vh">优质储层综合评价综合钻井、试油、试采等资料，对8砂组储层进行优质储层分类及综合评价。</li>
                      </div>
                      <div class="title1" style="margin-top: 25px">取得成果</div>
                      <div class="success" >
                        <dv-border-box-10>
                          <div class="suc1">
                            <div class="title3">评价有利目标</div>
                            <div class="title3" style="margin-top: 1vh"><span style="font-size: 1.1709416522vw;margin-right: 0.4vw">4</span>个</div>
                          </div>
                        </dv-border-box-10>
                        <dv-border-box-10>
                          <div class="suc1">
                            <div class="title3">部署井位</div>
                            <div class="title3" style="margin-top: 1vh"><span style="font-size: 1.1709416522vw;margin-right: 0.4vw">5</span>口</div>
                          </div>
                        </dv-border-box-10>
                      </div>
                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
        <bottomTip></bottomTip>
          </div>
      </div>
      <partnerTip
          :dialogVisible="dialogconsult"
          @hideDialog="hide"
      ></partnerTip>
      <resultTip
          :dialogVisible="dialogVisible"
          @hideDialog="hideDialog"
      ></resultTip>
    </div></div>
    <div v-else><div class="totalmakeHoleModel" ref="totalModel">
      <div class="makeHole1">
        <kefuMobile @topMobile="topTopmobile"></kefuMobile>
        <div class="background">
          <!--      logo  you气通GPT社区 合作伙伴  登录-->
          <headtip @showDialog="showPartner"></headtip>
          <!--     文字-->
          <div class="titlle1">智能油藏地质解决方案</div>
          <div class="title4">*该解决方案是现场的油气开发技术服务与人工智能模型融合，通过现场的解决方案为客户提供服务，如需对该方案进一步了解合作，请随时与我们联系。</div>
          <div class="titlle3" @click="dialogVisible=true">
            解决方案咨询
          </div>


        </div>
        <div class="screenmake0">
          <div class="heighLight">智能油藏地质服务总览</div>
          <div class="heightLight2">油气田开发研究院面向全局，聚焦单井油藏地质精细研究，以地质工程相结合为特色，为客户提供全面地质研究评价、开发方案编制服务，同时为安东整体解决方案提供地质风险评价服务</div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/serve1.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">结构精细解释</div>
                </div>
              </div>
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/serve2.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">三维地质模型</div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/serve3.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">甜点评价</div>
                </div>
              </div>
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/serve4.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">脆性指数</div>
                </div>
              </div>
            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/serve5.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">天然裂缝预测-蚂蚁体</div>
                </div>
              </div>
            </div>
            <div class="card1">

              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/serve6.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">天然裂缝预测-曲率体</div>
                </div>
              </div>
            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/serve7Model.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">地质力学分析</div>
                </div>
              </div>
            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/serve8Model.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">地质风险评价</div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="screenmake">
          <div class="heighLight">
            <span style="background-color: #FF6400;border-radius: 50%; width:21px;font-size:21px;height:21px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 3px   ">1</span>
            <span>&nbsp;&nbsp;智能采油气解释</span>
          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">智能地震相识别</div>
                  <div class="Title2">机器学习和深度学习，实现数据预处理、特征提取、MCC计算、模型训练、地震相分类。</div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">深度学习测井岩相识别</div>
                  <div class="Title2">测井数据自动识别与分类，实现波形特征、频域特征、时域特征等测井数据分类和识别。</div>
                </div>

              </div>


            </div>
            <div class="card2">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">高精度智能断层识别</div>
                  <div class="Title2">构建海量断层训练样本库，采用三维高精度卷积网络技术学习预测断裂。</div>
                </div>
              </div>


            </div>
            <div class="card3">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">高精度智能层位追踪与解释</div>
                  <div class="Title2">以少量层位骨架为样本集，采用神经网络技术，大幅度提升层位自动追踪的效率和精度。</div>
                </div>

              </div>


            </div>
            <div class="card4">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">智能储层预测</div>
                  <div class="Title2">通过人工智能算法对储层进行预测和评价，对储层的分布、厚度及岩性进行追踪和预测。</div>
                </div>

              </div>


            </div>
          </div>

        </div>
        <div class="screenmake2">

          <div class="heighLight">
            <span style="background-color: #FF6400;border-radius: 50%; width:21px;font-size:21px;height:21px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 3px   ">2</span>

            <span>&nbsp;&nbsp;智能测井解释</span></div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">测井物性参数智能预测</div>
                  <div class="Title2">
                    基于机器学习理论的回归模型，识别测井数据与地层物性参数之间的非线性映射关系，准确地预测储层段的孔隙度变化。

                  </div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">测井岩相类别智能预测</div>
                  <div class="Title2">以建立岩心孔隙度与岩石物理测井之间的模糊规则, 提取模糊聚类的最优参数, 提出一种新的多测井曲线数据匹配算法。</div>
                </div>
              </div>


            </div>
            <div class="card2">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">测井地层智能划分</div>
                  <div class="Title2">建立从测井曲线到岩相的目标映射函数, 预测未解释测井数据所对应的岩相类别。</div>
                </div>
              </div>


            </div>
          </div>

        </div>
        <div class="screenmake3">
          <div class="heighLight">成功案例</div>
          <div class="grid">
            <el-carousel indicator-position="outside" arrow="always" interval="5000">
              <el-carousel-item v-for="item in 1" :key="item" >
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/success1.png">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">西南J致密油气区块沙溪庙组优质储层评价及开发目标优选项目技术服务</div>
                      <div class="title1" style="font-weight: bold">研究内容</div>
                      <div class="title2">
                        <li>储层精细刻画：根据录井、测井解释，利用岩石物理分析及模型正演，明确8砂组地震响应特征，精细刻画储层展布；</li>
                        <li style="margin-top: 1vh">储层分类：结合录井、测井成果，落实秋林区块8砂组河道构造及烃源断层发育情况，并开展分类评价；</li>
                        <li style="margin-top: 1vh">优质储层综合评价综合钻井、试油、试采等资料，对8砂组储层进行优质储层分类及综合评价。</li>
                      </div>
                      <div class="title1">取得成果</div>
                      <div class="success" >
                        <dv-border-box-10>
                          <div class="suc1">
                            <div class="title3">评价有利目标</div>
                            <div class="title3" ><span style="font-size: 14px;;margin-right: 5px">4</span>个</div>
                          </div>
                        </dv-border-box-10>
                        <dv-border-box-10>
                          <div class="suc1">
                            <div class="title3">部署井位</div>
                            <div class="title3" ><span style="font-size: 14px;;margin-right: 5px">5</span>口</div>
                          </div>
                        </dv-border-box-10>
                      </div>
                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
        <div ref="bottom">
          <bottomTip></bottomTip>
        </div>
      </div>
      <partnerTip
          :dialogVisible="dialogconsult"
          @hideDialog="hide"
      ></partnerTip>
      <resultTip
          :dialogVisible="dialogVisible"
          @hideDialog="hideDialog"
      ></resultTip>
    </div></div>
  </div>


</template>


<script>

import headtip from "@/views/headTip.vue";
import kefu from "@/views/kefu.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import bottomTip from "@/views/bottomTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import resultTip from "@/components/ResultTip.vue";
export default {

  name: "",

  props: [],

  components: {
    PartnerTip,  headtip, bottomTip,resultTip,kefu,kefuMobile
  },

  data() {
    return {
      changeVisable:1,

      pageTitle: '智能油藏地质解决方案_油气开发作业GPT应用项目服务-油气通GPT',
      pageDescription: '油气田开发研究院面向全局，聚焦单井油藏地质精细研究，以地质工程相结合为特色，为客户提供全面地质研究评价、开发方案编制服务，同时为安东整体解决方案提供地质风险评价服务。',
      pageKeywords: '智能油藏地质，智能采油气解释，智能地震相识别，深度学习测井岩相识别，高精度智能断层识别，高精度智能层位追踪与解释，智能储层预测，智能测井解释，测井物性参数智能预测，测井岩相类别智能预测，测井地层智能划分，油气开发作业GPT，油气通GPT',
      scrollback:false,
      model:false,
      dialogconsult:false,
      dialogVisible:false,
    }

  },

  methods: {
     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    topTop() {
      console.log("fuzujianchuanzhi")
      const element = this.$refs.total;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    handleScroll() {
      if(this.model===false){
        this.scrollHeight = this.$refs.total.scrollTop;
      }
      else{
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if( this.scrollHeight<100){
        this.scrollTrue=true
      }
      else{
        this.scrollTrue=false
      }
      if( this.scrollHeight<50){
        this.scrollback=false
      }
      else{
        this.scrollback=true
      }
      console.log("gaodu",this.scrollHeight<100)
    },
  },
  beforeDestroy() {

    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);
    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);

    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)

  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .topback{
      position: fixed;
      z-index:999999999999;
      width: 100vw;
      padding: 0 0 0 2.86077411900635vw;
    }
    .background{
      padding: 7.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      width: 100%;
      height: 72.41285537700865vh;
      background-image: url("../../assets/ground1.png");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 15.09270704573548vh;
        text-align: center;
        font-size: 3.0151935298vw;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }
      .title4 {
        text-align: center;
        margin: 8.82076637824475vh auto 0 auto;
        width: 38.45869439630272vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 8.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .screenmake0 {
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .grid {

        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 1vw;

        .card {
         // cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;

          .back {
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 1px solid #2f6dfd;
            height: fit-content;
            overflow: hidden;
            background: #FFFFFF;
            margin-bottom: 1.5248454883vh;

            .imgDesign {
              text-align: center;
              height: 23.24969097651422vh;
              width: 100%;
              background-image: url("../../assets/serve1.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw;
              background-color: #2F6EFF;

              .Title1 {
                color: white;
                text-align: center;
                font-size: 1.1709416522vw;

                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
              }

            }

            .backconsult {
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 5.55414091470952vw;
            }

            .backconsultArrow {
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }
        }

        .card1 {
         // cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;

          .back {
            border-radius: 9px;
            position: relative;
            border: 1px solid #2f6dfd;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height: fit-content;
            overflow: hidden;
            background: #FFFFFF;
            margin-bottom: 1.5248454883vh;

            .imgDesign {
              text-align: center;
              height: 54vh;
              width: 100%;
              background-image: url("../../assets/serve1.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw;
              background-color: #2F6EFF;

              .Title1 {
                color: white;
                text-align: center;
                font-size: 1.1709416522vw;

                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
              }

            }

            .backconsult {
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 5.55414091470952vw;
            }

            .backconsultArrow {
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }


        }
      }
    }
    .screenmake{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 1vw;
        .card{
         // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:47.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF;
            .imgDesign{
              text-align: center;
              height:23.24969097651422vh;
              width: 100%;
              background-image: url("../../assets/ground2.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.5248454883vw;
              .Title1{
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 1.1709416522vw;
                height: 2.7194066749vh;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 3.33745364647713vh;
              }
              .Title2{
                margin-top: 5.0124845488vh;
                text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult{
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left:5.55414091470952vw ;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }
        }

        .card1{
         // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{

            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:47.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF;
            .imgDesign{
              text-align: center;
              height:23.24969097651422vh;
              width: 100%;
              background-image: url("../../assets/ground3.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.5248454883vw;
              .Title1{
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 1.1709416522vw;
                height: 2.7194066749vh;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 3.33745364647713vh;
              }
              .Title2{
                margin-top: 5.0124845488vh;
                text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult{
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left:5.55414091470952vw ;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }
        }

        .card2{
         // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:47.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF;
            .imgDesign{
              text-align: center;
              height:23.24969097651422vh;
              width: 100%;
              background-image: url("../../assets/ground4.png");
              background-position: center;
              background-size: contain;;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.5248454883vw;
              .Title1{
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 1.1709416522vw;
                height: 2.7194066749vh;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 3.33745364647713vh;
              }
              .Title2{
                margin-top: 5.0124845488vh;
                text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult{
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left:5.55414091470952vw ;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }
        }

        .card3{
         // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:47.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF;
            .imgDesign{
              text-align: center;
              height:23.24969097651422vh;
              width: 100%;
              background-image: url("../../assets/ground5.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.5248454883vw;
              .Title1{
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 1.1709416522vw;
                height: 2.7194066749vh;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 3.33745364647713vh;
              }
              .Title2{
                margin-top: 5.0124845488vh;
                text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult{
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left:5.55414091470952vw ;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }
        }

        .card4{
         // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:47.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF;
            .imgDesign{
              text-align: center;
              height:23.24969097651422vh;
              width: 100%;
              background-image: url("../../assets/ground6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.5248454883vw;
              .Title1{
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 1.1709416522vw;
                height: 2.7194066749vh;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 3.33745364647713vh;
              }
              .Title2{
                margin-top: 5.0124845488vh;
                text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult{
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left:5.55414091470952vw ;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }
        }

      }
    }
    .screenmake2{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap:0.81317157712305vw ;
        .card{
         // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 16px;
            position: relative;
            border: white 3px solid;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:55.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            .imgDesign{
              position:absolute;
              transition:transform 0.3s ease-out;
              overflow:hidden;
              width: 100%;
              height: 30.69962917181706vh;
              opacity: 1;
              background-image: url("../../assets/ground7.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title1{
                margin-top: 36vh;
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{

                margin: 3vh 1.8597920277vw auto 1.8597920277vw;
                text-align: justify;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card1{
          border-radius: 16px;
         // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 16px;
            position: relative;    border: white 3px solid;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:55.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            .imgDesign{
              position:absolute;
              transition:transform 0.3s ease-out;
              overflow:hidden;
              width: 100%;
              height: 30.69962917181706vh;
              opacity: 1;
              background-image: url("../../assets/ground8.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title1{
                margin-top: 36vh;
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{

                margin: 3vh 1.8597920277vw auto 1.8597920277vw;
                text-align: justify;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card2{
          border-radius: 16px;
         // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 16px;
            position: relative;    border: white 3px solid;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:55.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            .imgDesign{
              position:absolute;
              transition:transform 0.3s ease-out;
              overflow:hidden;
              width: 100%;
              height: 30.69962917181706vh;
              opacity: 1;
              background-image: url("../../assets/ground9.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title1{
                margin-top: 36vh;
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{

                margin: 3vh 1.8597920277vw auto 1.8597920277vw;
                text-align: justify;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake3{
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;
      //background-color: #ebf1fd;
      .heighLight{
        color:white;
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        .card{
          //cursor: pointer;
          margin:0 auto;
          height:65vh;
          width: 86%;
          padding:0.98887515451174vh 0 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:64vh;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: fit-content;
              height:100%;
              opacity: 1;

              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              color: white;
              width: 30vw;
              margin:auto;
              float: right;
              .title1{
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                margin-top:10px;
                line-height: 4vh;
                font-size: 0.9243212016vw;
              }
              .success{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                column-gap: 1vw;
                margin-top: 2vh;
                .suc1{
                  width: 100%;
                  height: 8vh;
                  .title3{
                    text-align: center;
                    margin-top: 2vh;
                    font-size: 0.8243212016vw;
                  }

                }

              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:68vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 550px;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
         // cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }

  }

}
.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .background{
      //z-index: 3;
      position: relative;
      width: 100%;
      height: 460px;
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      background-image: url("../../assets/gptModel11.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin:50px auto;
        width: fit-content;
        text-align: center;
        height: 23px;
        font-size: 23px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #111111;
        line-height: 8.2373300371vh;
      }
      .titlle3{
        position: absolute;
        bottom:75px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }
      .title4{
        position: absolute;
        left:10%;
        bottom:-35px;
        margin: 35px auto 0 auto;
        width: 80%;
        height: fit-content;
        font-size: 12px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 18px;
        border-radius: 15px;
        padding: 15px;
        background:white;
        border: #e8eefa 1px solid;
      }
    }
    .screenmake0{
      width: 100vw;
      height: fit-content;
      padding:50px 4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {

        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap: 10px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border:1px solid #2f6dfd;
            height:fit-content ;
            overflow:hidden;
            background: #FFFFFF;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              height:165px;
              width: 100%;
              background-image: url("../../assets/serve1.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding:10px 1.5248454883vw;
              background-color: #2F6EFF;
              .Title1{
                color: white;
                text-align: center;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
              }

            }

          }
        }
        .card1{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.6vh 0.69324090121317vw 0 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            border:1px solid #2f6dfd;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background: #FFFFFF;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              height:165px;
              width: 100%;
              background-image: url("../../assets/serve1.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding:10px 1.5248454883vw;
              background-color: #2F6EFF;
              .Title1{
                color: white;
                text-align: center;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
              }

            }
          }
          .back:hover{
            transform: scale(1.05);}
        }
      }
    }
    .screenmake{
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:  #ebf1fd;;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 26px;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        row-gap: 0px;
        .card{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background-color: white;;
            padding: 7px 7.5px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 125px;
              height: 100%;
              background-image: url("../../assets/ground2.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 7px 0 8px;
              .Title1{
                opacity: 4;
                width: 200px;
                white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: bold;
                line-height: 26px;
              }
              .Title2{
                opacity: 4;
                width: 53vw;
                margin: 2px 0 10px 0;
                text-align: justify;
                font-size: 12px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
        .card1{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background-color: white;;
            padding: 7px 7.5px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 125px;
              height: 100%;
              background-image: url("../../assets/ground3.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 7px 0 8px;
              .Title1{
                opacity: 4;
                width: 200px;
                white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: bold;
                line-height: 26px;
              }
              .Title2{
                opacity: 4;
                width: 53vw;
                margin: 2px 0 10px 0;
                text-align: justify;
                font-size: 12px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
        .card2{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background-color: white;;
            padding: 7px 7.5px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 125px;
              height: 100%;
              background-image: url("../../assets/ground4.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 7px 0 8px;
              .Title1{
                opacity: 4;
                width: 200px;
                white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: bold;
                line-height: 26px;
              }
              .Title2{
                opacity: 4;
                width: 53vw;
                margin: 2px 0 10px 0;
                text-align: justify;
                font-size: 12px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
        .card3{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background-color: white;;
            padding: 7px 7.5px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 125px;
              height: 100%;
              background-image: url("../../assets/ground5.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 7px 0 8px;
              .Title1{
                opacity: 4;
                width: 200px;
                white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: bold;
                line-height: 26px;
              }
              .Title2{
                opacity: 4;
                width: 53vw;
                margin: 2px 0 10px 0;
                text-align: justify;
                font-size: 12px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
        .card4{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background-color: white;;
            padding: 7px 7.5px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 125px;
              height: 100%;
              background-image: url("../../assets/ground6.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 7px 0 8px;
              .Title1{
                opacity: 4;
                width: 200px;
                white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: bold;
                line-height: 26px;
              }
              .Title2{
                opacity: 4;
                width: 53vw;
                margin: 2px 0 10px 0;
                text-align: justify;
                font-size: 12px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
      }
    }
    .screenmake2{
      //z-index: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:  white;;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 26px;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        row-gap: 15px;
        .card{
          cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: #ebf1fd;;
            .imgDesign{
              text-align: center;
              height:230px;
              width: 100%;
              background-image: url("../../assets/ground7.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.5248454883vw;
              .Title1{
                margin-top: 15px;
                text-align: center;
                height: 20px;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 15px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
        .card1{
          cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: #ebf1fd;;
            .imgDesign{
              text-align: center;
              height:230px;
              width: 100%;
              background-image: url("../../assets/ground8.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.5248454883vw;
              .Title1{
                margin-top: 15px;
                text-align: center;
                height: 20px;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 15px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
        .card2{
          cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: #ebf1fd;;
            .imgDesign{
              text-align: center;
              height:230px;
              width: 100%;
              background-image: url("../../assets/ground9.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.5248454883vw;
              .Title1{
                margin-top: 15px;
                text-align: center;
                height: 20px;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 15px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
      }
    }
    .screenmake3{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 15px;
      background: #212a3c;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #19202e;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;

        .card{
          cursor: pointer;
          width: 98%;
          margin:0 auto;
          min-height: 250px ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            min-height: 660px ;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:254px;
              opacity: 1;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 15px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:15px;
                font-weight: bold;
                font-size: 14px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:12px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }
            .success{
              display: grid;
              grid-template-columns: minmax(0,1fr) minmax(0,1fr);
              column-gap: 10px;
              margin-top: 15px;
              .suc1{
                width: 100%;
                height: 55px;
                .title3{
                  text-align: center;
                  margin-top: 10px;
                  font-size: 14px;
                }

              }

            }
          }
        }

        ::v-deep .el-carousel__container{
          height: 680px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -228px -10px  ;
          cursor: pointer;
          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }

  }

}
</style>
