<template>
  <div>
    <div v-if="model===false">

    </div>
    <div v-else>
      <div class="totalModel" ref="totalModel">
        <div @click="changeheadVisable">
          <div class="screen1">
          <kefuMobile @topMobile="topTopmobile"></kefuMobile>
          <headtip  :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->

          <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
          <div class="layer2">
            <div class="title1">设计类GPTs</div>
            <div class="title2">设计类GPTs，基于目标区域的地质条件、地层结构、工程要求、产量目标等多方面因素，制定出科学、合理、可行的设计方案，以实现油气资源的高效、安全、经济开发。</div>
          </div>
          <div class="titlle3" @click="clickConsult('油气通')">
            产品咨询
          </div>
          <div class="title4">*油气人工智能模型的泛化能力有限，实际应用中可能需根据具体场景和需求进行适配和优化。如您有合作意向或产品咨询，请随时与我们联系。
            <br>①&nbsp;设计类服务，由安东石油线下技术人员提供服务<br>②&nbsp;GPT开发服务，由安东人工智能专业技术人员提供服务</div>
          <!--      平台大图-->
          <div class="layer3"></div>
        </div>
        <!-- <div class="screenmake0">
          <div class="heighLight">油藏地质模型架构</div>
          <div class="heightLight2">以油藏地质研究及各个应用场景数据为中心，通过解释脑形成知识成果，智能优化油藏地质研究成果，从而达到油藏地质知识智能解释处理、为智能钻井压裂等应用场景提供油藏地质知识的目的。</div>
          <div class="grid">
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/gptModel12.png">
                </div>
              </div>
            </div>
          </div>

        </div> -->
        <div class="screenmake2">
          <div class="heighLight">   <span>设计类GPTs服务</span></div>
          <div class="grid">
            <div class="card">
              <div class="back">
<!--              <div class="back" @click="detail">-->
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">    <span style="border-radius: 50%; width:16px;font-size:16px;height:16px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 4px">1</span>&nbsp;智能工程方案辅助设计平台</div>
                  <div class="Title2">
                    通过集成邻井与同平台工程方案数据，实现快速构建基本工程方案。系统具备自动化设计撰写功能，能够简化设计流程，减少人工操作，提高设计效率。
                  </div>
<!--                  <div class="backconsult"  @click="detail">-->
<!--                    免费体验-->
<!--                  </div>-->
<!--                  <div class="backconsultArrow">-->
<!--                    <i style="font-size:14px;margin-left: 15px;" class="el-icon-right"></i>-->
<!--                  </div>-->
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">     <span style="border-radius: 50%; width:16px;font-size:16px;height:16px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 4px">2</span>&nbsp;压裂设计方案参数优化</div>
                  <div class="Title2">地质与工程相结合，根据新井实钻轨迹及不同河道砂体的叠置和接触关系，制定个性化的方案，优化分段、分簇参数，强化裂缝复杂程度，最大限度的提高裂缝控制体积、释放有效砂体产能。同时实时计算井底压力及净压力，根据净压力变化情况，及时判断裂缝延伸情况，预判砂堵风险，实时优化调整施工参数，确保单井压裂控制体积最大。</div>
                </div>
              </div>
            </div>

            <div class="card2">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">     <span style="border-radius: 50%; width:16px;font-size:16px;height:16px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 4px"></span>&nbsp;油气田开发设计</div>
                  <div class="Title2">根据实际情况制定详细的油气田开采方案。它涉及到油气藏的开采方式、生产设施的建设、工艺流程的设计等多个方面。目标是制定出最优的开发方案，实现油气资源的高效开发，同时保障生产安全和环境保护。</div>
                </div>
              </div>


            </div>
          </div>

        </div>
        <div class="screenmake3">
          <div class="heighLight">成功案例</div>
          <div class="grid">
            <el-carousel indicator-position="outside">
              <el-carousel-item v-for="item in 1" :key="item">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/gpt16.png">
                    </div>
                    <div class="titleDesign">
                      <div style="font-size: 16px; text-align: center;">XX油气田分公司页岩气井压裂项目</div>
                      <div class="title1">2022年，安东为某油气田分公司提供压裂工程技术服务，在长宁区块页岩气井均EUR同步提高18%，渝西区块同比提高超10%；致密气压裂新工艺井井均测试日产量达40.98万立方米，较常规工艺提高25%。</div>
                      <!-- <div class="title2">1.精细三维地应力预测及裂缝检测</div>
                      <div class="title2">2.新井精细跟踪服务</div>
                      <div class="title2">3.老井生产动态跟踪分析及评价</div> -->
                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
        <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
         <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import PartnerTip from "@/components/partnerTip.vue";
import consultTip from "@/components/consultTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import {shareUrl} from "@/utils/vxshare";

export default {

  name: "",

  props: [],

  components: {bottomTip, consultTip, PartnerTip, headtip,kefuMobile},

  data() {

   return {
     changeVisable:1,
      title:'',
      pageTitle: '设计类GPTs _油气行业设计类大模型人工智能应用-油气通GPT',
      pageDescription: '油气通GPT云平台提供设计类GPTs，基于目标区域的地质条件、地层结构、工程要求、产量目标等多方面因素，制定出科学、合理、可行的设计方案，以实现油气资源的高效、安全、经济开发。',
      pageKeywords: '设计GPTs，设计类GPTs ，基于压裂参数的EUR预测及参数反推平台，智能工程方案辅助设计平台，油气田开发设计，油气通，油气通GPT，油气通GPT云平台，油气通大模型，油气通GPT大模型',
      model:false,
      dialogconsult:false,
      dialogVisible:false,
    }

  },

  methods: {
    handleScroll() {
      if(this.model===false){
        this.scrollHeight = this.$refs.total.scrollTop;
      }
      else{
        this.scrollHeight = this.$refs.totalModel.scrollTop;

      }
      console.log("--------------------",this.scrollHeight)

      if( this.scrollHeight<100){
        this.scrollTrue=true
      }
      else{
        this.scrollTrue=false
      }
      if( this.scrollHeight<50){
        this.scrollback=false
      }
      else{
        this.scrollback=true
      }
      console.log("gaodu",this.scrollHeight<100)
    },
    topTopmobile() {
      console.log("fuzujianchuanzhi")
      // const element = this.$refs.totalModel;
      // const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });

    },
    topTop() {
      console.log("fuzujianchuanzhi")
      const element = this.$refs.total;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    clickConsult(item){

      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    detail(){
      window.open("/assisted", "_blank");
    },
    showPartner() {
      this.dialogconsult = true;
    },

    hideDialog() {
      this.dialogVisible = false;
    },
    hide() {
      this.dialogconsult = false;
    },
  },


  mounted() {
    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '设计类GPTs _油气行业设计类大模型人工智能应用-油气通GPT',
        pageDescription: '油气通GPT云平台提供设计类GPTs，基于目标区域的地质条件、地层结构、工程要求、产量目标等多方面因素，制定出科学、合理、可行的设计方案，以实现油气资源的高效、安全、经济开发。',
        pageKeywords: '设计GPTs，设计类GPTs ，基于压裂参数的EUR预测及参数反推平台，智能工程方案辅助设计平台，油气田开发设计，油气通，油气通GPT，油气通GPT云平台，油气通大模型，油气通GPT大模型',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>

.totalModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  //height: 100vh;
  position: relative;

  .screen1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    height: 460px;
    padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
    background-image: url("../../assets/gptModel11.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */
    .titlle3{
      position: absolute;
      bottom:80px;
      left:50%;
      transform: translateX(-60%);
      font-size: 14px;
      font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 53px;
      width: fit-content;
      margin: 0 15px;
      padding: 5px 4.6875vw;
      line-height: 15px;
      height: 35px;
      color:white;
      background-color: #2f6eff;
      border: 1px solid #2f6eff;
      overflow: hidden;


    }
    .title4{
      position: absolute;
      left:5%;
      bottom:-55px;
      margin: 35px auto 0 auto;
      width: 90%;
      height: fit-content;
      font-size: 12px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #111111;
      line-height: 18px;
      border-radius: 15px;
      padding: 15px;
      background:white;
      border: #e8eefa 1px solid;

    }
    .layer2 {
      position: absolute;
      left:0;

      .title1 {
        margin:35px auto 0 auto;
        width: fit-content;
        text-align: center;
        height: fit-content;
        font-size: 22px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #111111;
        line-height: 22px;
      }

      .title2 {
        margin: 16px auto;
        width: 90%;
        text-align: center;
        height: 17px;
        font-size: 12px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 20px;
      }
    }

  }
  .screenmake2{
    padding:75px 4.6875vw 20px 4.6875vw;
    width: 100%;
    height: fit-content;
    background: #ebf1fd;
    .heighLight {
      text-align: center;
      height: 20px;
      opacity: 1;
      font-size: 21px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 26px;

    }

    .grid{
      margin-top:2.78739184177998vh ;
      display: grid;
      grid-template-columns: minmax(0,1fr);
      column-gap:0 ;
      row-gap: 15px;
      .card{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 200px;
            opacity: 1;
            background-image: url("../../assets/gpt23.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 215px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 20px;
              font-size: 16px;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 35px 12px;
              text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
      .card2{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 170px;
            opacity: 1;
            background-image: url("../../assets/pro4.jpg");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 180px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 20px;
              font-size: 16px;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 15px 12px;
              text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
      .card1{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            height: 160px;
            opacity: 1;
            background-image: url("../../assets/gpt15.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 175px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 20px;
              font-size: 16px;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 15px 12px;
              text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  .screenmake3{
    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;

    padding:20px 15px;
    background: #212a3c;
    .heighLight{
      text-align: center;
      height:21px;
      opacity: 1;
      font-size: 21px;
      color: white;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 26px;
    }
    .heightLight2{
      margin:19px 2vw;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }
    .grid{
      margin-top:2.78739184177998vh ;
      position: relative;

      .card{
        cursor: pointer;
        width: 98%;
        margin:0 auto;
        min-height: 250px ;
        padding:0 0.69324090121317vw 0 0;
        opacity: 1;
        //background: #4D85F7 ;
        .back{
          border-radius: 9px;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          min-height: 370px ;
          overflow:hidden;
          background: #19202e;
          .imgDesign{
            width: 100%;
            height:fit-content;
            opacity: 1;
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 15px 20px 25px 20px;
            color: white;
            width:100%;
            float: right;
            .title1{
              margin-top:15px;
              font-size: 14px;
              line-height: 20px;
            }
            .title2{
              margin-top: 15px;
              line-height: 20px;
              font-size:14px;
              ::v-deep li{
                margin-top:5px;
              }
            ;
            }


          }

        }
      }

      ::v-deep .el-carousel__container{
        height: 390px !important;

      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }

      ::v-deep .el-carousel__arrow {
        display: block;
        //border: 1px solid white;
        outline: -5px;
        padding: 0;
        margin: -68px -10px  ;
        cursor: pointer;

        border-radius: 0;
        width: 25px;
        height: 48px;
        background: rgba(245, 246, 250, 0.8);
        color: #131313;
        position: absolute;
        top: 50%;
        //z-index: 100;
        transform: translateY(-50%);
        text-align: center;
        font-size: 14px;
      }
    }
  }
}
</style>
