<template>
  <div>
    <div v-if="model===false">
         <div class="totalmakeHole" ref="total">
      <div class="makeHole1">
        <kefu @toptop="topTop"></kefu>
        <div class="topback"  :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}">
          <headtip  :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
        </div>
                <div @click="changeheadVisable">
        <div class="background">
            <!--     文字-->
            <div class="titlle1">企业管理GPT开发服务</div>
            <div class="title4">帮助客户企业全线上办公平台建设，定制化人工智能办公服务方案。围绕客户业务的端到端价值流，通过流程自动化、数据驱动、在线社区、智能客服等智能化解决方案，全面提升客户内外部的运营管理和决策效率。</div>
            <div class="titlle3" @click="clickConsult('油气通')">
              产品咨询
            </div>


          </div>
          <div class="screenmake0">
            <div class="heighLight">企业管理数智化架构</div>
            <div class="grid">
              <div class="card">
                <div class="back" style="height:45vh">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/menageService2.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      以企业的核心业务及管理支持流程为主线，通过平台，构建运营管理工作的全线上化，并通过智能化手段提升运营管理效率。  </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake1">
            <div class="heighLight">管理流程自动化平台</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="titleDesign">
                    <div class="title2">
                      管理及业务支持的流程从线上化向智能化升级。通过流程自动机器人RPA、图形识别OCR、Python等智能化工具及语言，实现基于规则的流程系统可以24小时不间断的自动识别核验、自动处理流程，显著的减少人工操作，提高流程效率。

                    </div>
                  </div>
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/menageService3.png">
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake0">
            <div class="heighLight">数据驱动智慧决策平台</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/menageService4.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      数据驱动，智慧决策。构建面向经营管理领域的数据服务地图，以终为始，基于预测以及实际执行情况，在核心业务流程的经营各环节，提前预警，智能预判，支持业绩达成。   </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake1">
            <div class="heighLight">智慧员工社区</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="titleDesign">
                    <div class="title2">
                      员工社区作为员工可以随时访问，获取各项管理智能化服务的入口。安东基于基础大模型，并利用内部各领域文档、知识开展训练，建设安东通知识GPT，实现智能问答对话，让员工快速便捷获取知识。
                    </div>
                  </div>
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/menageService.png">
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake0">
            <div class="heighLight">智慧电商平台</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/menageService5.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      安东在线、全球油气展馆等各类油气电子商务平台，实现面向用户的智能问答、智能在线助手服务、搜索智能匹配，并可以根据用户的爱好和访问历史，智能推荐产品和解决方案服务。
                    </div>
                    </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake1">
            <div class="heighLight">企业内部管理类知识GPT开发服务</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="titleDesign">
                    <div class="title2">
                      基于大模型GPT能力，建设安东通GPT，内嵌集团各领域文档知识，实现智能问答对话，员工可以快速便捷获取知识。  </div>
                  </div>
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/menageService6.png">
                  </div>
                </div>
              </div>
            </div>

          </div>

          <bottomTip></bottomTip></div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
         <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel" ref="totalModel">
        <div class="makeHole1">
        <kefuMobile @topMobile="topTopmobile"></kefuMobile>
          <div class="background">
            <headtip @showDialog="showPartner"></headtip>

            <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="titlle1">企业管理GPT开发服务</div>
              <div class="title4">帮助客户企业全线上办公平台建设，定制化人工智能办公服务方案。围绕客户业务的端到端价值流，通过流程自动化、数据驱动、在线社区、智能客服等智能化解决方案，全面提升客户内外部的运营管理和决策效率。</div>
            </div>
            <div class="title3" @click="clickConsult('油气通')">
              产品咨询
            </div>
          </div>
          <div class="screenmake0">
            <div class="heighLight">企业管理数智化架构</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign" style="height:155px">
                    <img loading="lazy" src="@/assets/menageService2.png">
                  </div>
                  <div class="titleDesign" >
                    <div class="title2" style="margin-top:10px">
                      以企业的核心业务及管理支持流程为主线，通过平台，构建运营管理工作的全线上化，并通过智能化手段提升运营管理效率。  </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake1">
            <div class="heighLight">管理流程自动化平台</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign" style="height: 150px">
                    <img loading="lazy" src="@/assets/menageService3.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      管理及业务支持的流程从线上化向智能化升级。通过流程自动机器人RPA、图形识别OCR、Python等智能化工具及语言，实现基于规则的流程系统可以24小时不间断的自动识别核验、自动处理流程，显著的减少人工操作，提高流程效率。

                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
          <div class="screenmake0">
            <div class="heighLight">数据驱动智慧决策平台</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/menageService4.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      数据驱动，智慧决策。构建面向经营管理领域的数据服务地图，以终为始，基于预测以及实际执行情况，在核心业务流程的经营各环节，提前预警，智能预判，支持业绩达成。   </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake1">
            <div class="heighLight">智慧员工社区</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/menageService.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      员工社区作为员工可以随时访问，获取各项管理智能化服务的入口。安东基于基础大模型，并利用内部各领域文档、知识开展训练，建设安东通知识GPT，实现智能问答对话，让员工快速便捷获取知识。
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
          <div class="screenmake0">
            <div class="heighLight">智慧电商平台</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/menageService5.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      安东在线、全球油气展馆等各类油气电子商务平台，实现面向用户的智能问答、智能在线助手服务、搜索智能匹配，并可以根据用户的爱好和访问历史，智能推荐产品和解决方案服务。
                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake1">
            <div class="heighLight">企业内部管理类知识GPT开发服务</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign" style="height: 150px">
                    <img loading="lazy" src="@/assets/menageService6.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      基于大模型GPT能力，建设安东通GPT，内嵌集团各领域文档知识，实现智能问答对话，员工可以快速便捷获取知识。  </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
         <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import kefu from "@/views/kefu.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {PartnerTip, consultTip, headtip,bottomTip,kefu,kefuMobile},

  data() {

       return {title:"",
      pageTitle: '企业管理GPT开发服务_安迅油气通-油气通GPT',
      pageDescription: '安迅油气通智能技术有限公司专注为石油行业提供人工智能技术解决方案，提供企业管理GPT开发服务，帮助客户企业全线上办公平台建设，定制化人工智能办公服务方案。围绕客户业务的端到端价值流，通过流程自动化、数据驱动、在线社区、智能客服等智能化解决方案，全面提升客户内外部的运营管理和决策效率。',
      pageKeywords: '企业管理GPT，管理GPT，企业管理GPT开发，安迅油气通智能技术有限公司，油气通，油气通GPT，油气通GPT云平台',
      scrollback:false,
      model:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/menageService2.png"),
        title1:"企业管理数智化架构",
        title2:"以企业的核心业务及管理支持流程为主线，通过平台，构建运营管理工作的全线上化，并通过智能化手段提升运营管理效率。",
      },{
        img:require("@/assets/menageService3.png"),
        title1:"管理流程自动化平台",
        title2:"管理及业务支持的流程从线上化向智能化升级。通过流程自动机器人RPA、图形识别OCR、Python等智能化工具及语言，实现基于规则的流程系统可以24小时不间断的自动识别核验、自动处理流程，显著的减少人工操作，提高流程效率。",
      },
        {
          img:require("@/assets/menageService4.png"),
          title1:"数据驱动智慧决策平台",
          title2:"数据驱动，智慧决策。构建面向经营管理领域的数据服务地图，以终为始，基于预测以及实际执行情况，在核心业务流程的经营各环节，提前预警，智能预判，支持业绩达成。",
        },
        {
          img:require("@/assets/menageService.png"),
          title1:"智慧员工社区",
          title2:"员工社区作为员工可以随时访问，获取各项管理智能化服务的入口。安东基于基础大模型，并利用内部各领域文档、知识开展训练，建设安东通知识GPT，实现智能问答对话，让员工快速便捷获取知识。",
        },
        {
          img:require("@/assets/menageService5.png"),
          title1:"智慧电商平台",
          title2:"安东在线、全球油气展馆等各类油气电子商务平台，实现面向用户的智能问答、智能在线助手服务、搜索智能匹配，并可以根据用户的爱好和访问历史，智能推荐产品和解决方案服务。",
        },
        {
          img:require("@/assets/menageService6.png"),
          title1:"企业内部管理类知识GPT开发服务",
          title2:"基于大模型GPT能力，建设安东通GPT，内嵌集团各领域文档知识，实现智能问答对话，员工可以快速便捷获取知识。",
        },
      ]
    }

  },

  methods: {    topTopmobile() {
      console.log("fuzujianchuanzhi")
      const element = this.$refs.totalModel;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    topTop() {
      console.log("fuzujianchuanzhi")
      const element = this.$refs.total;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    clickConsult(item){

      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
  },

  mounted() {
    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '企业管理GPT开发服务_安迅油气通-油气通GPT',
        pageDescription: '安迅油气通智能技术有限公司专注为石油行业提供人工智能技术解决方案，提供企业管理GPT开发服务，帮助客户企业全线上办公平台建设，定制化人工智能办公服务方案。围绕客户业务的端到端价值流，通过流程自动化、数据驱动、在线社区、智能客服等智能化解决方案，全面提升客户内外部的运营管理和决策效率。',
        pageKeywords: '企业管理GPT，管理GPT，企业管理GPT开发，安迅油气通智能技术有限公司，油气通，油气通GPT，油气通GPT云平台',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: 100vh;
       .topback{
      position: fixed;
      z-index:999999999999;
      width: 100vw;
      padding: 0 0 0 2.86077411900635vw;
    }
    .background{
      padding: 7.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      width: 100%;
      height: 72.41285537700865vh;
      background-image: url("../../assets/menageBanner.png");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 15.09270704573548vh;
        text-align: center;
        font-size: 3.0151935298vw;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }
      .title4 {
        text-align: center;
        margin: 8.82076637824475vh auto 0 auto;
        width: 58.45869439630272vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 8.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .screen1 {
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 74vh;
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      background-image: url("../../assets/serviceBanner.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 18.1495673671199vh;
        left: 9.17157712305026vw;

        .title1 {
          width: fit-content;
          height: 5.77626699629172vh;
          font-size: 3.50606585788562vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
          line-height: 12.23733003708282vh;
        }

        .title2 {
          margin-top: 6.82076637824475vh;
          width: 33.45869439630272vw;
          height: fit-content;
          font-size: 1.0597920277vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
          line-height: 4.31520395550062vh;
        }
        .title4{
          height: 8.77626699629172vh;
          font-size: 1.6060658579vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
          line-height: 9.31520395550062vh;
        }
        .titlle3{
          margin-top: 2.07911001236094vh;
          //cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }
        .titlle3:hover{

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }
    .screenmake0{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            //background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:fit-content;
            overflow:hidden;
            .imgDesign{
              width:40vw;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 3.5248454883vh 2.5248454883vw 2.5248454883vh 2.5248454883vw;
              display: flex;
              justify-content: center;
              align-items: center;
              float: right;
              color: black;
              width: 45%;
              .title1{
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                text-align: justify;
                line-height: 3.194066749vh;
                font-size:   1vw;
              }
              .title3{
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size:   1vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: transparent;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            //background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:fit-content;
            overflow:hidden;
            .imgDesign{
              //margin-left: 5%;
              width: 40vw;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 3.5248454883vh 2.5248454883vw 2.5248454883vh 2.5248454883vw;
              display: flex;
              justify-content: center;
              align-items: center;
              float: right;
              color: black;
              width: 50%;
              .title1{
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                text-align: justify;
                line-height: 3.194066749vh;
                font-size:   1vw;
              }
              .title3{
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size:   1vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }

}

.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: 100vh;
       .topback{
      position: fixed;
      z-index:999999999999;
      width: 100vw;
      padding: 0 0 0 2.86077411900635vw;
    }
    .background{
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      width: 100%;
      height: 400px;
      background-image: url("../../assets/menageBanner.png");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      position: relative;
      /* Add any additional styling as needed */
      .layer2 {
        position: absolute;
        left: 0;

        .titlle1 {
          margin: 25px auto 0 auto;
          width: fit-content;
          text-align: center;
          height: fit-content;
          font-size: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          color: #111111;
          line-height: 22px;

        }

        .title4 {
          margin: 25px auto;
          width: 90%;
          text-align: center;
          height: 17px;
          font-size: 12px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #111111;
          line-height: 20px;
        }
      }
      .title3 {
        position: absolute;
        bottom:30px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        line-height: 15px;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;


      }

    }
    .screenmake0{
      padding:30px 4.6875vw 20px 4.6875vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        text-align: center;
        height: 20px;
        opacity: 1;
        font-size: 21px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;

      }

      .grid{
        margin-top:20px ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .imgDesign{
            margin:0 auto;
            text-align: center;
            width: fit-content;
            height:170px;
            opacity: 1;
            //background-image: url("../../assets/menage6.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{

            .title2{
              margin: 25px 12px 15px 12px;
              text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              line-height: 22px;
            }

          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1{
      padding:30px 4.6875vw 20px 4.6875vw;
      width: 100%;
      height: fit-content;
      background: transparent;

      .heighLight {
        text-align: center;
        height: 20px;
        opacity: 1;
        font-size: 21px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;

      }

      .grid{
        margin-top:20px ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .imgDesign{
            margin:0 auto;
            text-align: center;
            width: fit-content;
            height:165px;
            opacity: 1;
            //background-image: url("../../assets/menage6.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{

            .title2{
              margin: 25px 12px 15px 12px;
              text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              line-height: 22px;
            }

          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }

}
</style>
