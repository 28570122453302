<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole" ref="total">
      <div class="makeHole1">
        <kefu @toptop="topTop"></kefu>
        <div class="topback"  :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}">
          <headtip  :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
        </div>
          <div @click="changeheadVisable">
        <div class="background">
          <!--     文字-->
          <div class="titlle1">智能钻井解决方案</div>
          <div class="title4">*该解决方案是现场的油气开发技术服务与人工智能模型融合，通过现场的解决方案为客户提供服务，如需对该方案进一步了解合作，请随时与我们联系。</div>
          <div class="titlle3" @click="dialogVisible=true">
            解决方案咨询
          </div>
        </div>

        <div class="screenmake0">
          <div class="heighLight">智能钻井服务总览</div>
          <div class="heightLight2">安东钻井产业集团是技术领先的优快钻井、低成本钻井技术服务商，服务项目涵盖钻井全过程，拥有一系列钻井关键技术，以水平井钻井、一体化钻完井总包服务模式为特色</div>

          <div class="heightLight3">钻井技术</div>
          <div class="line0"></div>
          <div class="grid0">
            <div class="card">
              <div class="heightLight3">钻完井一体化钻井技术</div>
            </div>
            <div class="card">
              <div class="line"></div>
              <div class="heightLight3">智能钻井技术</div>
            </div>
            <div class="card">
              <div class="line1"></div>
              <div class="heightLight3">钻井液及<br>完井液技术</div>
            </div>
            <div class="card">
              <div class="line"></div>
              <div class="heightLight3">钻井工程技术</div>
            </div>
            <div class="card">
              <div class="heightLight3">固井技术</div>
            </div>




          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="titleDesign">
                  <div class="Title1">钻完井一体化</div>
                  <div class="Title2">国内首家承担一体化总包的服务公司，主要提供风险总包、工程总包等服务，累计施工400余井。</div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="titleDesign">
                  <div class="Title1">定向</div>
                  <div class="Title2">国内首家拥有旋导的民营油服公司，提供全配套定向井及井下工具一体化服务，累计施工1200余井次。</div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="titleDesign">
                  <div class="Title1">泥浆</div>
                  <div class="Title2">国内陆上油基/生物合成基先导者，提供各种钻井环境的钻完井液技术服务，累计施工1500余井次。</div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="titleDesign">
                  <div class="Title1">固井</div>
                  <div class="Title2">拥有弹塑性水泥浆、旋转下套管等特色技术，提供固井服务及配套的技术服务，累计施工400余井次。</div>
                </div>
              </div>
            </div>

          </div>

        </div>
            <div class="screenmake21" style="padding:0  11.0976314269vw 7.7873918418vh">
              <div class="heighLight" style="font-size: 2.2060658579vw;font-weight: unset">  业务痛点与挑战</div>
              <div class="line"></div>
              <div class="grid">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/prediction.png" style="border-radius: 9px">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">地质预测不足
                      </div>
                      <div class="title2">
                        传统钻井依赖人工解读地质数据，导致地质模型的准确性受限，进而增加了钻井过程中的不确定性与风险。
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/risk.png" style="border-radius: 9px">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">风险管理不足
                      </div>
                      <div class="title2">
                        工程风险点的识别与应对措施往往依赖于经验，这使得风险评估缺乏系统性和前瞻性，难以及时应对突发情况。
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/optimize.png" style="border-radius: 9px">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">钻井参数优化困难
                      </div>
                      <div class="title2">
                        优化钻井参数通常需要大量的试验与调整，不仅耗时，而且大幅提高了项目的总体成本。
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/Intelligence.png" style="border-radius: 9px">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">工程措施不够智能
                      </div>
                      <div class="title2">
                        传统的工程措施优化依赖于人工判断，导致响应速度慢、精确度不足，进而影响整体钻井效率和安全性。
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
        <div class="screenmake">
          <div class="heighLight">
            <span style="background-color: #FF6400;border-radius: 50%; width:2.2060658579vw;font-size:2vw;height:2.2060658579vw;display: flex;justify-content: center;align-items: center;color: white;    ">1</span>
            <span>&nbsp;&nbsp;智能钻井设计</span>
          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">智能钻井地质设计</div>
                  <div class="Title2">人工智能算法学习历史地质数据及设计资料，智能输出更为准确的地质设计，为工程设计提供更好参考及风险预警。</div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">智能钻井工程设计</div>
                  <div class="Title2">基于历史工程数据及设计资料的学习，人工智能主动输出更为精准的工程设计，安全高效快速达成钻探目的。</div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div class="screenmake2">
          <div class="heighLight">
            <span style="background-color: #FF6400;border-radius: 50%; width:2.2060658579vw;font-size:2vw;height:2.2060658579vw;display: flex;justify-content: center;align-items: center;color: white;    ">2</span>
            <span>&nbsp;&nbsp;智能钻井地质</span>
          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">智能地质风险分析</div>
                  <div class="Title2">神经网络提取断层的潜在特征，抑制噪声干扰，建立了从地震数据到目标断层的非线性映射关系，实现快速干预。</div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">智能实时地质导向</div>
                  <div class="Title2">人工智能自动识别特征数据判断当前层位，便于提前介入布置控制措施，从而实现精准地质导向提高钻遇率。</div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div class="screenmake3">
          <div class="heighLight">
            <span style="background-color: #FF6400;border-radius: 50%; width:2.2060658579vw;font-size:2vw;height:2.2060658579vw;display: flex;justify-content: center;align-items: center;color: white;    ">3</span>
            <span>&nbsp;&nbsp;智能钻井工程</span>
          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">智能风险预警</div>
                  <div class="Title2">大数据深度学习识别工程风险点，制定应对措施。并在多维设定风险阈值，提前介入控制，避免发生复杂事故。</div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">智能参数实时优化</div>
                  <div class="Title2">机器学习算法分析数据要素与钻速之间的关系获得预测，根据钻速预测模型，输出钻井参数优化的选项和量化数值。</div>
                </div>
              </div>


            </div>
            <div class="card2">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">智能工程措施优化</div>
                  <div class="Title2">智能分析井筒流体、摩阻等数据，判断井下情况，在保障安全的前提下优化划眼、短起下措施，大大减少钻井周期。</div>
                </div>
              </div>


            </div>
          </div>

        </div>
        <div class="screenmake4">
          <div class="heighLight">成功案例</div>
          <div class="grid">
            <el-carousel indicator-position="outside" arrow="always" interval="5000">
              <el-carousel-item v-for="item in success" :key="item">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" :src="item.img"/>
                    </div>
                    <div class="titleDesign">
                      <div class="title1">{{item.title1}}</div>
                      <div class="title1" style="margin-top: 25px">{{item.title3}}</div>
                      <div class="title2">{{item.title4}}</div>

                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
        <bottomTip></bottomTip></div>
      </div>
      <partnerTip
          :dialogVisible="dialogconsult"
          @hideDialog="hide"
      ></partnerTip>
      <resultTip
          :dialogVisible="dialogVisible"
          @hideDialog="hideDialog"
      ></resultTip>
    </div></div>
    <div v-else> <div class="totalmakeHoleModel" ref="totalModel">
      <div class="makeHole1">
        <kefuMobile @topMobile="topTopmobile"></kefuMobile>
        <div class="background">
          <!--      logo  you气通GPT社区 合作伙伴  登录-->
          <headtip @showDialog="showPartner"></headtip>
          <!--     文字-->
          <div class="titlle1">智能钻井解决方案</div>
          <div class="title4">*该解决方案是现场的油气开发技术服务与人工智能模型融合，通过现场的解决方案为客户提供服务，如需对该方案进一步了解合作，请随时与我们联系。</div>
          <div class="titlle3" @click="dialogVisible=true">
            解决方案咨询
          </div>


        </div>
        <div class="screenmake0">
          <div class="heighLight">智能钻井服务总览</div>
          <div class="heightLight2">安东钻井产业集团是技术领先的优快钻井、低成本钻井技术服务商，服务项目涵盖钻井全过程，拥有一系列钻井关键技术，以水平井钻井、一体化钻完井总包服务模式为特色</div>

          <div class="heightLight3">钻井技术</div>
          <div class="line0"></div>
          <div class="grid0">
            <div class="card">
              <div class="heightLight3">钻完井一体化钻井技术</div>
            </div>
            <div class="card">
              <div class="line"></div>
              <div class="heightLight3">智能钻井技术</div>
            </div>
            <div class="card">
              <div class="line1"></div>
              <div class="heightLight3">钻井液及<br>完井液技术</div>
            </div>
            <div class="card">
              <div class="line"></div>
              <div class="heightLight3">钻井工程技术</div>
            </div>
            <div class="card">
              <div class="heightLight3">固井技术</div>
            </div>




          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="titleDesign">
                  <div class="Title1">钻完井一体化</div>
                  <div class="Title2">国内首家承担一体化总包的服务公司，主要提供风险总包、工程总包等服务，累计施工400余井。</div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="titleDesign">
                  <div class="Title1">定向</div>
                  <div class="Title2">国内首家拥有旋导的民营油服公司，提供全配套定向井及井下工具一体化服务，累计施工1200余井次。</div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="titleDesign">
                  <div class="Title1">泥浆</div>
                  <div class="Title2">国内陆上油基/生物合成基先导者，提供各种钻井环境的钻完井液技术服务，累计施工1500余井次。</div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="titleDesign">
                  <div class="Title1">固井</div>
                  <div class="Title2">拥有弹塑性水泥浆、旋转下套管等特色技术，提供固井服务及配套的技术服务，累计施工400余井次。</div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="screenmake21">
          <div class="heighLight" >  业务痛点与挑战</div>
          <div class="line"></div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/prediction.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">地质预测不足
                  </div>
                  <div class="title2">
                    传统钻井依赖人工解读地质数据，导致地质模型的准确性受限，进而增加了钻井过程中的不确定性与风险。
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/risk.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">风险管理不足
                  </div>
                  <div class="title2">
                    工程风险点的识别与应对措施往往依赖于经验，这使得风险评估缺乏系统性和前瞻性，难以及时应对突发情况。
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/optimize.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">钻井参数优化困难
                  </div>
                  <div class="title2">
                    优化钻井参数通常需要大量的试验与调整，不仅耗时，而且大幅提高了项目的总体成本。
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/Intelligence.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">工程措施不够智能
                  </div>
                  <div class="title2">
                    传统的工程措施优化依赖于人工判断，导致响应速度慢、精确度不足，进而影响整体钻井效率和安全性。
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="screenmake">
          <div class="heighLight">
            <span style="background-color: #FF6400;border-radius: 50%; width:21px;font-size:21px;height:21px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 3px   ">1</span>
            <span>&nbsp;&nbsp;智能钻井设计</span>
          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">智能钻井地质设计</div>
                  <div class="Title2">人工智能算法学习历史地质数据及设计资料，智能输出更为准确的地质设计，为工程设计提供更好参考及风险预警。</div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">智能钻井工程设计</div>
                  <div class="Title2">基于历史工程数据及设计资料的学习，人工智能主动输出更为精准的工程设计，安全高效快速达成钻探目的。</div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div class="screenmake2">
          <div class="heighLight">
            <span style="background-color: #FF6400;border-radius: 50%; width:21px;font-size:21px;height:21px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 3px   ">2</span>
            <span>&nbsp;&nbsp;智能钻井地质</span>
          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">智能地质风险分析</div>
                  <div class="Title2">神经网络提取断层的潜在特征，抑制噪声干扰，建立了从地震数据到目标断层的非线性映射关系，实现快速干预。</div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">智能实时地质导向</div>
                  <div class="Title2">人工智能自动识别特征数据判断当前层位，便于提前介入布置控制措施，从而实现精准地质导向提高钻遇率。</div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div class="screenmake3">
          <div class="heighLight">
            <span style="background-color: #FF6400;border-radius: 50%; width:21px;font-size:21px;height:21px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 3px   ">3</span>

            <span>&nbsp;&nbsp;智能钻井工程</span>
          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">智能风险预警</div>
                  <div class="Title2">大数据深度学习识别工程风险点，制定应对措施。并在多维设定风险阈值，提前介入控制，避免发生复杂事故。</div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">智能参数实时优化</div>
                  <div class="Title2">机器学习算法分析数据要素与钻速之间的关系获得预测，根据钻速预测模型，输出钻井参数优化的选项和量化数值。</div>
                </div>
              </div>


            </div>
            <div class="card2">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">智能工程措施优化</div>
                  <div class="Title2">智能分析井筒流体、摩阻等数据，判断井下情况，在保障安全的前提下优化划眼、短起下措施，大大减少钻井周期。</div>
                </div>
              </div>


            </div>
          </div>

        </div>
        <div class="screenmake4">
          <div class="heighLight">成功案例</div>
          <div class="grid">
            <el-carousel indicator-position="outside" arrow="always" interval="5000">
              <el-carousel-item v-for="item in success" :key="item">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" :src="item.img"/>
                    </div>
                    <div class="titleDesign">
                      <div class="title1">{{item.title1}}</div>
                      <div class="title1">{{item.title3}}</div>
                      <div class="title2">{{item.title4}}</div>

                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
        <div ref="bottom">
          <bottomTip></bottomTip>
        </div>
      </div>
      <partnerTip
          :dialogVisible="dialogconsult"
          @hideDialog="hide"
      ></partnerTip>
      <resultTip
          :dialogVisible="dialogVisible"
          @hideDialog="hideDialog"
      ></resultTip>
    </div></div>
  </div>


</template>


<script>

import headtip from "@/views/headTip.vue";
import kefu from "@/views/kefu.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import bottomTip from "@/views/bottomTip.vue";
import resultTip from "@/components/ResultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {PartnerTip, resultTip, headtip,bottomTip,kefu,kefuMobile},

  data() {
    return {
      changeVisable:1,
      pageTitle: '智能钻井解决方案_油气开发作业GPT应用项目服务-油气通GPT',
      pageDescription: '安东钻井产业集团是技术领先的优快钻井、低成本钻井技术服务商，服务项目涵盖钻井全过程，拥有一系列钻井关键技术，以水平井钻井、一体化钻完井总包服务模式为特色。',
      pageKeywords: '智能钻井，智能钻井设计，智能钻井地质设计，智能钻井工程设计，智能钻井地质，智能地质风险分析，智能实时地质导向，智能钻井工程，智能风险预警，智能参数实时优化，智能工程措施优化，油气开发作业GPT，油气通GPT',
 scrollback:false,
      model:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/success2.png"),
        title1:"地质导向GPT",
        title3:"着陆段地层分类",
        title4:" 基于6个元素录井数据建立人工智能机器学习数据分类模型；用于钻井着陆段地层分类判断场景。"

      },{
        img:require("@/assets/22.png"),
        title1:"地质导向GPT",
        title3:"水平段钻进状态判断",
        title4:"钻井水平段实时给出钻头穿层、基于地层切角转换以及地层判断。" ,

      },
      ]
    }

  },

  methods: {     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    topTop() {
      console.log("fuzujianchuanzhi")
      const element = this.$refs.total;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },

    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    handleScroll() {
      if(this.model===false){
        this.scrollHeight = this.$refs.total.scrollTop;
      }
      else{
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if( this.scrollHeight<100){
        this.scrollTrue=true
      }
      else{
        this.scrollTrue=false
      }
      if( this.scrollHeight<50){
        this.scrollback=false
      }
      else{
        this.scrollback=true
      }
      console.log("gaodu",this.scrollHeight<100)
    },
  },
  beforeDestroy() {

    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);

    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '智能钻井解决方案_油气开发作业GPT应用项目服务-油气通GPT',
        pageDescription: '安东钻井产业集团是技术领先的优快钻井、低成本钻井技术服务商，服务项目涵盖钻井全过程，拥有一系列钻井关键技术，以水平井钻井、一体化钻完井总包服务模式为特色。',
        pageKeywords: '智能钻井，智能钻井设计，智能钻井地质设计，智能钻井工程设计，智能钻井地质，智能地质风险分析，智能实时地质导向，智能钻井工程，智能风险预警，智能参数实时优化，智能工程措施优化，油气开发作业GPT，油气通GPT',


      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    ////z-index:: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .topback{
      position: fixed;
      z-index:999999999999;
      width: 100vw;
      padding: 0 0 0 2.86077411900635vw;
    }
    .background{
      padding: 7.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      width: 100%;
      height: 72.41285537700865vh;
      background-image: url("../../assets/makehole1.png");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 15.09270704573548vh;
        text-align: center;
        font-size: 3.0151935298vw;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }
      .title4 {
        text-align: center;
        margin: 8.82076637824475vh auto 0 auto;
        width: 38.45869439630272vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 8.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .screenmake21 {
      padding: 7.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Roboto,sans-serif;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Roboto,sans-serif;
      }
      .line{
        height: 1vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);

        border-bottom: dashed black 1px;
        position: absolute;
        top:11vh;
        z-index: 1;

      }

      .grid {
        z-index: 2;
        margin-top: 0vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 2vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 5vh;
              height: 5vh;
              margin:0 auto 2vh auto;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 auto;
              color: black;
              float: right;

              .title1 {
                margin-top: 0;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: left;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake0{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .heightLight3{
        margin: 0 auto 3.78739184177998vh auto;
        // cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1709416522vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #2f6eff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 2.1989601386vw;
        height:7.5624227441vh;
        background: #B3D0FF;
        border: #B3D0FF 1px solid;
      }
      .line0{
        width: 52.7vw;
        border-top-right-radius: 63px;
        border-top-left-radius: 63px;
        border-top:  #B3D0FF 4px solid;
        border-left:  #B3D0FF 4px solid;
        border-right:  #B3D0FF 4px solid;;
        height: 3.831891223733vh;
        margin:0 auto;
      }
      .grid0{
        width: 65vw;
        margin:0 auto;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 1vw;

        .card{
          position: relative;
          margin: 0 auto;
          text-align: center;
          .line{
            position: absolute;
            height: 3.831891223733vh;
            width: 4px;
            left: 50%;
            top:-3.831891223733vh;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }
          .line1{
            position: absolute;
            height: 7.663782447466vh;
            width: 4px;
            left: 50%;
            top:-7.663782447466vh;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }
          .heightLight3{
            margin:0 auto;

            // cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.1709416522vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2f6eff;
            line-height: 3.3374536465vh;
            text-align: center;
            border-radius: 53px;
            width: fit-content;
            padding: 2.4624227441vh 2.1989601386vw;
            height:8.5624227441vh;
            background: transparent;
            border: #B3D0FF 4px solid;
          }
        }


      }
      .grid{
        margin-top:4.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 1vw;
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:30.72311495673671vh ;
            background: #2F6EFF linear-gradient(to right, #CFE1FF 0%, #F0F6FF 100%);
            display: flex;

            .titleDesign{
              .Title1{
                text-align: center;
                margin:5.8124845488vh 2.5597920277vw 1.1124845488vh 2.155401502vw;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin:4.8124845488vh 2.5597920277vw 1.1124845488vh 2.155401502vw;
                text-align: justify;
                height: 4.6971569839vh;
                font-size:0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                //color:#2F6EFF;
                line-height: 3.194066749vh;
              }
            }
          }


        }
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background: #FFFFFF;
            margin-bottom: 1.5248454883vh;
            .imgDesign{
              text-align: center;
              height:54vh;
              width: 100%;
              background-image: url("../../assets/serve1.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              background-color: #2F6EFF;
              .Title1{
                color: white;
                text-align: center;
                font-size: 1.1709416522vw;

                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
              }

            }
            .backconsult{
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left:5.55414091470952vw ;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }

        }

      }
    }
    .screenmake{
      padding: 7.78739184177998vh 8.09763142692085vw;
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap:2.21317157712305vw ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:30.72311495673671vh ;
            display: flex;
            background: #FFFFFF;
            .imgDesign{
              width: 38.69555170421722vw;
              height: 100%;
              opacity: 1;
              background-image: url("../../assets/makehole2.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title1{
                margin:5.8124845488vh 2.5597920277vw 1.1124845488vh 2.155401502vw;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin:4.8124845488vh 2.5597920277vw 1.1124845488vh 2.155401502vw;
                text-align: justify;
                height: 4.6971569839vh;
                font-size:0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                //color:#2F6EFF;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card1{
          // cursor: pointer;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;

          opacity: 1;

          .back{
            border-radius: 9px;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:30.72311495673671vh ;
            display: flex;
            background: #FFFFFF;
            .imgDesign{
              width: 38.69555170421722vw;
              height: 100%;
              opacity: 1;
              background-image: url("../../assets/makehole3.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title1{
                margin:5.8124845488vh 2.5597920277vw 1.1124845488vh 2.155401502vw;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin:4.8124845488vh 2.5597920277vw 1.1124845488vh 2.155401502vw;
                text-align: justify;
                height: 4.6971569839vh;
                font-size:0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                //color:#2F6EFF;
                line-height: 3.194066749vh;
              }
            }
          }




        }


      }
    }
    .screenmake2{

      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap:2.21317157712305vw ;
        .card{

          // cursor: pointer;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            border: white 3px solid;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:30.72311495673671vh ;
            display: flex;
            background: #FFFFFF linear-gradient(to right, #CFE1FF 0%, #F0F6FF 100%);
            .imgDesign{
              width: 38.69555170421722vw;
              height: 100%;
              opacity: 1;
              background-image: url("../../assets/makehole4.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title1{
                margin:5.8124845488vh 2.5597920277vw 1.1124845488vh 2.155401502vw;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin:4.8124845488vh 2.5597920277vw 1.1124845488vh 2.155401502vw;
                text-align: justify;
                height: 4.6971569839vh;
                font-size:0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                //color:#2F6EFF;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card1{

          // cursor: pointer;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;

          opacity: 1;

          .back{
            border-radius: 9px;    border: white 3px solid;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:30.72311495673671vh ;
            display: flex;
            background: #FFFFFF linear-gradient(to right, #CFE1FF 0%, #F0F6FF 100%);
            .imgDesign{
              width: 38.69555170421722vw;
              height: 100%;
              opacity: 1;
              background-image: url("../../assets/makehole5.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title1{
                margin:5.8124845488vh 2.5597920277vw 1.1124845488vh 2.155401502vw;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 3.194066749vh;
              }
              .Title2{
                margin:4.8124845488vh 2.5597920277vw 1.1124845488vh 2.155401502vw;
                text-align: justify;
                height: 4.6971569839vh;
                font-size:0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                //color:#2F6EFF;
                line-height: 3.194066749vh;
              }
            }
          }




        }


      }
    }
    .screenmake3{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap:2.21317157712305vw ;
        .card{
          // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:52.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFf;
            .imgDesign{
              position:absolute;
              transition:transform 0.3s ease-out;
              overflow:hidden;
              width: 100%;
              height:  27.6996291718vh;
              opacity: 1;
              background-image: url("../../assets/makehole6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title1{
                position: absolute;
                top: 31vh;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{

                margin: 37.5vh 2.5597920277vw auto 2.5597920277vw;
                text-align: justify;
                height: 4.6971569839vh;
                font-size:0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card1{
          // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:52.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF;
            .imgDesign{
              position:absolute;
              transition:transform 0.3s ease-out;
              overflow:hidden;
              width: 100%;
              height:  27.6996291718vh;
              opacity: 1;
              background-image: url("../../assets/makehole7.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title1{
                position: absolute;
                top: 31vh;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{

                margin: 37.5vh 2.5597920277vw auto 2.5597920277vw;
                text-align: justify;
                height: 4.6971569839vh;
                font-size:0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card2{
          // cursor: pointer;
           width: fit-content;
           height:fit-content ;
           padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
           opacity: 1;
           .back{
             border-radius: 9px;
             position: relative;
             box-shadow: 5px 0 14px 0px #D4E4FF;
             height:52.87144622991347vh ;
             overflow:hidden;
             background: #FFFFFF;
             .imgDesign{
               position:absolute;
               transition:transform 0.3s ease-out;
               overflow:hidden;
               width: 100%;
               height:  27.6996291718vh;
               opacity: 1;
               background-image: url("../../assets/makehole8.png");
               background-position: center;
               background-size:cover;
               background-repeat: no-repeat;
               background-color: transparent;
             }
             .titleDesign{
               .Title1{
                 position: absolute;
                 top: 31vh;
                 left: 50%;
                 transform: translateX(-50%);
                 text-align: center;
                 height: 2.7194066749vh;
                 font-size: 1.1709416522vw;
                 font-family: arial, sans-serif, "Microsoft Yahei";
                 font-weight: 700;
                 line-height: 2.7194066749vh;
               }
               .Title2{

                 margin: 37.5vh 2.5597920277vw auto 2.5597920277vw;
                 text-align: justify;
                 height: 4.6971569839vh;
                 font-size:0.8243212016vw;
                 font-family: Sarial, sans-serif, "Microsoft Yahei";
                 font-weight: 400;
                 line-height: 3.194066749vh;
               }
             }
           }
         }

      }
    }
    .screenmake4{
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;
      //background-color: #ebf1fd;
      .heighLight{
        color:white;
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        .card{
          //// cursor: pointer;
          margin:0 auto;
          height:65vh;
          width: 86%;
          padding:0.98887515451174vh 0 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:64vh;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: fit-content;
              height:100%;
              opacity: 1;

              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              color: white;
              width: 30vw;
              margin:auto;
              float: right;
              .title1{
                font-size: 1.1709416522vw;
              }
              .title2{
                margin-top:25px;
                line-height: 4vh;
                font-size: 0.9243212016vw;
              }
              .success{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                column-gap: 1vw;
                margin-top: 2vh;
                .suc1{
                  width: 100%;
                  height: 8vh;
                  .title3{
                    text-align: center;
                    margin-top: 2vh;
                    font-size: 0.8243212016vw;
                  }

                }

              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:68vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 550px;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
          // cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }
  }

}
.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .background{
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 460px;
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      background-image: url("../../assets/gptModel21.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin:50px auto;
        width: fit-content;
        text-align: center;
        height: 23px;
        font-size: 23px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #111111;
        line-height: 8.2373300371vh;
      }
      .titlle3{
        position: absolute;
        bottom:75px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }
      .title4{
        position: absolute;
        left:10%;
        bottom:-35px;
        margin: 35px auto 0 auto;
        width: 80%;
        height: fit-content;
        font-size: 12px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 18px;
        border-radius: 15px;
        padding: 15px;
        background:white;
        border: #e8eefa 1px solid;
      }
    }
    .screenmake21{
      width: 100vw;
      height: fit-content;
      padding:15px  4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            width: 90%;
            margin: 0 0 0 15px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 10px 0 10px;
            height:fit-content;
            display: flex;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake0{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:50px 4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .heightLight3{
        margin: 0 auto 25px auto;
        // cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size:16px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #2f6eff;
        line-height: 22px;
        text-align: center;
        border-radius: 9px;
        width: fit-content;
        padding: 10px 15px;
        height:40px;
        background: #B3D0FF;
        border: #B3D0FF 1px solid;
      }
      .line0{
        width: 300px;
        border-top-right-radius: 63px;
        border-top-left-radius: 63px;
        border-top:  #B3D0FF 4px solid;
        border-left:  #B3D0FF 4px solid;
        border-right:  #B3D0FF 4px solid;;
        height: 35px;
        margin:0 auto;
      }
      .grid0{
        width: 350px;
        margin:0 auto;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 5px;

        .card{
          position: relative;
          margin: 0 auto;
          text-align: center;
          .line{
            position: absolute;
            height: 35px;
            width: 4px;
            left: 50%;
            top:-35px;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }
          .line1{
            position: absolute;
            height: 75px;
            width: 4px;
            left: 50%;
            top:-75px;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }
          .heightLight3{
            margin:0 auto;

            // cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 8px;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2f6eff;
            line-height: 16px;
            text-align: center;
            border-radius: 9px;
            width: fit-content;
            padding: 5px 5px;
            height:45px;
            background: transparent;
            border: #B3D0FF 4px solid;
          }
        }


      }
      .grid{
        margin-top:15px;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap:10px;
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            background: #2F6EFF linear-gradient(to right, #CFE1FF 0%, #F0F6FF 100%);
            display: flex;
            .titleDesign{
              padding: 0 1.5248454883vw;
              .Title1{
                margin-top: 15px;
                text-align: center;
                height: 20px;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 22px;
              }
            }

          }


        }
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background: #FFFFFF;
            margin-bottom: 1.5248454883vh;
            .imgDesign{
              text-align: center;
              height:54vh;
              width: 100%;
              background-image: url("../../assets/serve1.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              background-color: #2F6EFF;
              .Title1{
                color: white;
                text-align: center;
                font-size: 1.1709416522vw;

                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
              }

            }
            .backconsult{
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left:5.55414091470952vw ;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }

        }

      }
    }
    .screenmake{

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:  #ebf1fd;;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 26px;

      }


      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        row-gap: 15px;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            border-radius: 9px;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:180px;
            display: flex;
            background: #FFFFFF;
            .imgDesign{
              width: 300px;
              height: 100%;
              opacity: 1;
              background-image: url("../../assets/makehole2.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              width: 300px;
              padding: 0 1.5248454883vw;
              .Title1{
                margin: 10px auto auto 8px;
                text-align: left;
                height: 20px;
                font-size: 16px;
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }

        .card1{
          // cursor: pointer;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            border-radius: 9px;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:180px;
            display: flex;
            background: #FFFFFF;
            .imgDesign{
              width: 300px;
              height: 100%;
              opacity: 1;
              background-image: url("../../assets/makehole3.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              width: 300px;
              padding: 0 1.5248454883vw;
              .Title1{
                margin: 10px auto auto 8px;
                text-align: left;
                height: 20px;
                font-size: 16px;
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }


      }
    }
    .screenmake2{

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:  white;;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 26px;

      }


      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        row-gap: 15px;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            border-radius: 9px;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:180px;
            display: flex;
            background: #ebf1fd;;

            .imgDesign{
              width: 300px;
              height: 100%;
              opacity: 1;
              background-image: url("../../assets/makehole5.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              width: 300px;
              padding: 0 1.5248454883vw;
              .Title1{
                margin: 10px auto auto 8px;
                text-align: left;
                height: 20px;
                font-size: 16px;
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }

        .card1{
          // cursor: pointer;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            border-radius: 9px;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:180px;
            display: flex;
            background:  #ebf1fd;;

            .imgDesign{
              width: 300px;
              height: 100%;
              opacity: 1;
              background-image: url("../../assets/makehole6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              width: 300px;
              padding: 0 1.5248454883vw;
              .Title1{
                margin: 10px auto auto 8px;
                text-align: left;
                height: 20px;
                font-size: 16px;
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }


      }
    }
    .screenmake3{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: #ebf1fd;;


      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 26px;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        row-gap: 15px;
        .card{
          // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: white;;
            .imgDesign{
              text-align: center;
              height:230px;
              width: 100%;
              background-image: url("../../assets/makehole6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.5248454883vw;
              .Title1{
                margin-top: 15px;
                text-align: center;
                height: 20px;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 15px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
        .card1{
          cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: white;;
            .imgDesign{
              text-align: center;
              height:230px;
              width: 100%;
              background-image: url("../../assets/makehole7.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.5248454883vw;
              .Title1{
                margin-top: 15px;
                text-align: center;
                height: 20px;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 15px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
        .card2{
          cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: white;;
            .imgDesign{
              text-align: center;
              height:230px;
              width: 100%;
              background-image: url("../../assets/makehole8.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.5248454883vw;
              .Title1{
                margin-top: 15px;
                text-align: center;
                height: 20px;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 15px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
      }
    }
    .screenmake4{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding:20px 15px;
      background: #212a3c;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;

        .card{
          cursor: pointer;
          width: 98%;
          margin:0 auto;
          min-height: 250px ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            min-height: 360px ;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:254px;
              opacity: 1;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 15px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:15px;
                font-size: 14px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:12px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 430px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -88px -10px  ;
          cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}
</style>
