<template>
  <div>
    <div v-if="model===false">    <div class="totalmakeHole" ref="total">
      <div class="makeHole1">
        <kefu @toptop="topTop"></kefu>
        <div class="topback"  :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}">
          <headtip  :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
        </div>
          <div @click="changeheadVisable">
        <div class="background">
          <!--     文字-->
          <div class="titlle1">设备设施监测解决方案</div>
          <div class="title4">*该解决方案是现场的油气开发技术服务与人工智能模型融合，通过现场的解决方案为客户提供服务，如需对该方案进一步了解合作，请随时与我们联系。</div>
          <div class="titlle3" @click="dialogVisible=true">
            解决方案咨询
          </div>
        </div>

        <div class="screenmake0">
          <div class="heighLight">油田资产智能化服务总览</div>
          <div class="heightLight2">为油气企业提供技术自主可控、产品线丰富的设备智能化改造方案及油田智能化管理解决方案，依托深厚的油气行业经验积淀， 提供可信赖的油气生产、储运、炼化智能化咨询和服务，推动油气资产管理全面数字化转型</div>

          <div class="heightLight3">油田资产智能化服务</div>
          <div class="line"></div>
          <div class="line0"></div>
          <div class="grid0">
            <div class="card">
              <div class="heightLight3">设备智能化监测</div>
            </div>
            <div class="card">
              <div class="line"></div>
              <div class="heightLight3">生产智能化监测</div>
            </div>
            <div class="card">
              <div class="heightLight3">检测管理软件服务</div>
            </div>

          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="titleDesign">
                  <div class="Title2">
                    <li>自主开发的智能软件平台，检测+监测的数字化管理，融合线下+线上技术服务模式，实现一体化资产智能管理系统；</li>
                    <li>整合全球数字化智能技术，与百度、京东、中国石油大学、昆仑数智等战略合作，提供油气全场景智慧检测服务；</li>
                    <li>利用超过20年设备设施现场检测经验。深刻理解客户资产运行难点和痛点，提供针对性智能化服务。</li>
                  </div>
                </div>
                <div class="imgDesign">
                  <div>
                    <img loading="lazy" src="@/assets/equup1.png">
                  </div>
                  <div>
                    <img loading="lazy" src="@/assets/equup2.png">
                  </div>
                </div>

              </div>


            </div>

          </div>

        </div>
            <div class="screenmake21" style="padding: 0 11.0976314269vw 7.7873918418vh">
              <div class="heighLight" style="font-size: 2.2060658579vw;font-weight: unset"> 业务痛点与挑战</div>
              <div class="line"></div>
              <div class="grid">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/managementicon.png" style="border-radius: 9px">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">设备管理痛点
                      </div>
                      <div class="title2">
                        系统分散，导致管理复杂、效率低下。传统维护方式落后，无法预测设备故障，增加停机和维修成本。缺乏全面的设备健康监测，难以及时预防问题。
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/monitor.png" style="border-radius: 9px">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">生产监控痛点
                      </div>
                      <div class="title2">
                        缺乏实时生产数据，决策滞后。数据分析能力不足，无法预测产量趋势和生产效率。应急响应不及时，增加风险。
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/managementicon.png" style="border-radius: 9px">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">资产管理痛点
                      </div>
                      <div class="title2">
                        资产管理不全面，无法实现价值最大化。环保和安全管理不足。数字化程度低，无法支撑智能决策，影响运营效率和合规性。
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/All.png" style="border-radius: 9px">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">综合管理痛点
                      </div>
                      <div class="title2">
                        信息孤岛，缺乏统一的平台整合各项功能。缺少智能化和数字化支持，影响企业数字化转型。无法全面提升管理效率和运营优化。
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
        <div class="screenmake1">
          <div class="heighLight">
            <span style="background-color: #FF6400;border-radius: 50%; width:2.2060658579vw;font-size:2vw;height:2.2060658579vw;display: flex;justify-content: center;align-items: center;color: white; ">1</span>
            <span>&nbsp;&nbsp;设备设施智能监测</span>
          </div>
          <div class="Light">设备健康管理系统是新型的厂务设备工程一体化平台，管理的是设备硬件、运行的软件、设备上的数据、参数、模型等一体化管理，工程师不必在各个系统之间来回穿梭，基于工业互联网技术与设备分类策略，实现TPM(全员修)、TBM(周期修)、CBM(状态修)、PHM(预测修) 等智能运维的先进模型全闭环管理</div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
              </div>


            </div>

          </div>

        </div>
        <div class="screenmake2">
          <div class="heighLight">
            <span style="background-color: #FF6400;border-radius: 50%; width:2.2060658579vw;font-size:2vw;height:2.2060658579vw;display: flex;justify-content: center;align-items: center;color: white; ">2</span>
            <span>&nbsp;&nbsp; 生产智能监测</span>
          </div>
          <div class="Light">提供基于开放的物联网技术，构建从井口、场站到管输的全流程的实时生产监控服务；生成油气生产和管理的专业报表，实现产量趋势预测、生产时率分析，提供生产综合管理、决策和应急事件处理的运营优化服务；基于数据底座的数据，构建针对生产过程中的各种异常事件的分析和预测能力的智能分析服务</div>

          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
              </div>


            </div>

          </div>

        </div>
        <div class="screenmake3">
          <div class="heighLight"> <span style="background-color: #FF6400;border-radius: 50%; width:2.2060658579vw;font-size:2vw;height:2.2060658579vw;display: flex;justify-content: center;align-items: center;color: white; ">3</span>
            <span>&nbsp;&nbsp;智慧检测管理软件</span></div>
          <div class="Light">致力于通过数字技术赋能，围绕资产全生命周期构建一个智慧、环保、安全的数字化应用生态，实现资产价值最大化，助力客户资产数智化管理</div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
              </div>


            </div>

          </div>

        </div>
        <div class="screenmake4">
          <div class="heighLight">成功案例</div>
          <div class="grid">
            <el-carousel indicator-position="outside" arrow="always" interval="5000">
              <el-carousel-item v-for="item in success" :key="item">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" :src="item.img"/>
                    </div>
                    <div class="titleDesign">
                      <div class="title1" v-html="item.title1"></div>
                      <div class="title2" v-html="item.title3">  </div>


                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
        <bottomTip></bottomTip></div>
      </div>
      <partnerTip
          :dialogVisible="dialogconsult"
          @hideDialog="hide"
      ></partnerTip>
      <resultTip
          :dialogVisible="dialogVisible"
          @hideDialog="hideDialog"
      ></resultTip>
    </div></div>
    <div v-else>  <div class="totalmakeHoleModel" ref="totalModel">
      <div class="makeHole1">
        <kefuMobile @topMobile="topTopmobile"></kefuMobile>
        <div class="background">
          <!--      logo  you气通GPT社区 合作伙伴  登录-->
          <headtip @showDialog="showPartner"></headtip>
          <!--     文字-->
          <div class="titlle1">设备设施监测解决方案</div>
          <div class="title4">*该解决方案是现场的油气开发技术服务与人工智能模型融合，通过现场的解决方案为客户提供服务，如需对该方案进一步了解合作，请随时与我们联系。</div>
          <div class="titlle3" @click="dialogVisible=true">
            解决方案咨询
          </div>


        </div>
        <div class="screenmake0">
          <div class="heighLight">油田资产智能化服务总览</div>
          <div class="heightLight2">为油气企业提供技术自主可控、产品线丰富的设备智能化改造方案及油田智能化管理解决方案，依托深厚的油气行业经验积淀， 提供可信赖的油气生产、储运、炼化智能化咨询和服务，推动油气资产管理全面数字化转型</div>

          <div class="heightLight3">油田资产智能化服务</div>
          <div class="line"></div>
          <div class="line0"></div>
          <div class="grid0">
            <div class="card">
              <div class="heightLight3">设备智能化监测</div>
            </div>
            <div class="card">
              <div class="line"></div>
              <div class="heightLight3">生产智能化监测</div>
            </div>
            <div class="card">
              <div class="heightLight3">检测管理软件服务</div>
            </div>

          </div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <div class="img">
                    <img loading="lazy" src="@/assets/equup1.png">
                  </div>
                  <div class="img">
                    <img loading="lazy" src="@/assets/equup2.png">
                  </div>
                </div>
                <div class="titleDesign">
                  <div class="Title2">
                    <li>自主开发的智能软件平台，检测+监测的数字化管理，融合线下+线上技术服务模式，实现一体化资产智能管理系统；</li>
                    <li>整合全球数字化智能技术，与百度、京东、中国石油大学、昆仑数智等战略合作，提供油气全场景智慧检测服务；</li>
                    <li>利用超过20年设备设施现场检测经验。深刻理解客户资产运行难点和痛点，提供针对性智能化服务。</li>
                  </div>
                </div>


              </div>


            </div>

          </div>

        </div>
        <div class="screenmake21" >
          <div class="heighLight" > 业务痛点与挑战</div>
          <div class="line"></div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/managementicon.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">设备管理痛点
                  </div>
                  <div class="title2">
                    系统分散，导致管理复杂、效率低下。传统维护方式落后，无法预测设备故障，增加停机和维修成本。缺乏全面的设备健康监测，难以及时预防问题。
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/monitor.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">生产监控痛点
                  </div>
                  <div class="title2">
                    缺乏实时生产数据，决策滞后。数据分析能力不足，无法预测产量趋势和生产效率。应急响应不及时，增加风险。
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/managementicon.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">资产管理痛点
                  </div>
                  <div class="title2">
                    资产管理不全面，无法实现价值最大化。环保和安全管理不足。数字化程度低，无法支撑智能决策，影响运营效率和合规性。
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/All.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">综合管理痛点
                  </div>
                  <div class="title2">
                    信息孤岛，缺乏统一的平台整合各项功能。缺少智能化和数字化支持，影响企业数字化转型。无法全面提升管理效率和运营优化。
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="screenmake1">
          <div class="heighLight">
            <span style="background-color: #FF6400;border-radius: 50%; width:21px;font-size:21px;height:21px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 3px   ">1</span>
            <span>&nbsp;&nbsp;设备设施智能监测</span>
          </div>
          <div class="Light">设备健康管理系统是新型的厂务设备工程一体化平台，管理的是设备硬件、运行的软件、设备上的数据、参数、模型等一体化管理，工程师不必在各个系统之间来回穿梭，基于工业互联网技术与设备分类策略，实现TPM(全员修)、TBM(周期修)、CBM(状态修)、PHM(预测修) 等智能运维的先进模型全闭环管理</div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
              </div>


            </div>

          </div>

        </div>
        <div class="screenmake2">
          <div class="heighLight">
            <span style="background-color: #FF6400;border-radius: 50%; width:21px;font-size:21px;height:21px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 3px   ">2</span>
            <span>&nbsp;&nbsp; 生产智能监测</span>
          </div>
          <div class="Light">提供基于开放的物联网技术，构建从井口、场站到管输的全流程的实时生产监控服务；生成油气生产和管理的专业报表，实现产量趋势预测、生产时率分析，提供生产综合管理、决策和应急事件处理的运营优化服务；基于数据底座的数据，构建针对生产过程中的各种异常事件的分析和预测能力的智能分析服务</div>

          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
              </div>


            </div>

          </div>

        </div>
        <div class="screenmake3">
          <div class="heighLight"> <span style="background-color: #FF6400;border-radius: 50%; width:21px;font-size:21px;height:21px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 3px   ">3</span>
            <span>&nbsp;&nbsp;智慧检测管理软件</span></div>
          <div class="Light">致力于通过数字技术赋能，围绕资产全生命周期构建一个智慧、环保、安全的数字化应用生态，实现资产价值最大化，助力客户资产数智化管理</div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
              </div>


            </div>

          </div>

        </div>
        <div class="screenmake4">
          <div class="heighLight">成功案例</div>
          <div class="grid">
            <el-carousel indicator-position="outside" arrow="always" interval="5000">
              <el-carousel-item v-for="item in success" :key="item">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" :src="item.img"/>
                    </div>
                    <div class="titleDesign">
                      <div class="title1">{{item.title1}}</div>
                      <div class="title2" v-html="item.title3">  </div>


                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
        <div ref="bottom">
          <bottomTip></bottomTip>
        </div>
      </div>
      <partnerTip
          :dialogVisible="dialogconsult"
          @hideDialog="hide"
      ></partnerTip>
      <resultTip
          :dialogVisible="dialogVisible"
          @hideDialog="hideDialog"
      ></resultTip>
    </div></div>
  </div>


</template>


<script>

import headtip from "@/views/headTip.vue";
import kefu from "@/views/kefu.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import bottomTip from "@/views/bottomTip.vue";
import resultTip from "@/components/ResultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {PartnerTip,resultTip, headtip,bottomTip,kefu,kefuMobile},

  data() {
    return {
      changeVisable:1,
      pageTitle: '设备设施监测解决方案_油气开发作业GPT应用项目服务-油气通GPT',
      pageDescription: '设备设施监测解决方案为油气企业提供技术自主可控、产品线丰富的设备智能化改造方案及油田智能化管理解决方案，依托深厚的油气行业经验积淀， 提供可信赖的油气生产、储运、炼化智能化咨询和服务，推动油气资产管理全面数字化转型。',
      pageKeywords: '设备设施监测，油田资产智能化服务，设备智能化监测，生产智能化监测，检测管理软件服务，油气开发作业GPT，油气通GPT',
 scrollback:false,
      model:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/success11.png"),
        title1:"助力长庆油田某采油厂实现地面工程设备设施健康管理",
        title3:"油田地面工程是油气田开发生产这个大系统中的一个重要子系统，包括油气集输、污水处理、配制注入系统等，由离心泵、柱塞泵、加热炉等核心设备构成。核心设备健康管理水平的高低，直接影响着油气田开发技术的高低和经济效益。",
      },{
        img:require("@/assets/success12.png"),
        title1: "<div style='margin-left: 50px'>助力天津石化设备健康管理</div>",
        title3:"<div style='margin-left: 50px'><li>往复式压缩机气阀温度采集无线传感器替代</li><li>实现设备多源参数采集统管理</li><li>实现设备、测点、数据自主配置组态</li><li>实现多维图谱分析工具应用</li></div>",
      },

        {
          img:require("@/assets/success14.png"),
          title1: "某油田生产监测和运行优化",
          title3:"基于采集的实时数据和预警规则，实现采油、采气、注水、集输等工艺流程以及环境敏感区域的实时预警。借助智能摄像头，实现人员入侵、危险动作识别等预警。基于大数据构建的智能预警模型，实现气并工况分析、抽油机功图诊断，以及设备关键参数的趋势预警。",

        },

        {
          img:require("@/assets/success16.png"),
          title1: "西部钻探钻具资产管理系统",
          title3:"西钻钻探管具与井控公司实现了从钻井工具购入后到钻具报废的全生命周期管理，包括: 钻具首次检测完后录入本系统，在系统内保存钻具的相关信息(包括钢号、制造商、接头长度尺寸、接头外径尺寸、密封面尺寸、全长尺寸、壁厚尺寸、超声探伤结果、磁粉探伤结果、电磁探伤结果、螺纹判修等)，通过以上数据判断钻具状态，待再次发井时就可以通过系统内自动调用相应的数据形成单根钻具报告(并可查阅历史检测数据)，形成从入库、检测、送井的闭环管理;解决资产管理目前存在的不规范、不精确、管理投入成本高的难题。",

        },

        {
          img:require("@/assets/success17.png"),
          title1: "某天然气管道完整性管理系统",
          title3:"通奥智联根据客户实际生产需要建设完成了管道完整性系统，实现管道基础信息查询，场站内设备全生命周期管理巡检管理，阴保管理及重点河道管理，高后果区管理、风险评价、SCADA系统、无人机系统对接及三维场站建设。最终实现管道完整性管理系统的全面建设工作。",

        },
      ]
    }

  },

  methods: {     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    topTop() {
      console.log("fuzujianchuanzhi")
      const element = this.$refs.total;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
 skipp3() {
  const element = this.$refs.bottom;
  const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

  this.$nextTick(() => {
    this.$refs.totalModel.scrollTop = rect
  });
},
    handleScroll() {
      if(this.model===false){
        this.scrollHeight = this.$refs.total.scrollTop;
      }
      else{
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if( this.scrollHeight<100){
        this.scrollTrue=true
      }
      else{
        this.scrollTrue=false
      }
      if( this.scrollHeight<50){
        this.scrollback=false
      }
      else{
        this.scrollback=true
      }
      console.log("gaodu",this.scrollHeight<100)
    },
  },
  beforeDestroy() {

    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);
    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '设备设施监测解决方案_油气开发作业GPT应用项目服务-油气通GPT',
        pageDescription: '设备设施监测解决方案为油气企业提供技术自主可控、产品线丰富的设备智能化改造方案及油田智能化管理解决方案，依托深厚的油气行业经验积淀， 提供可信赖的油气生产、储运、炼化智能化咨询和服务，推动油气资产管理全面数字化转型。',
        pageKeywords: '设备设施监测，油田资产智能化服务，设备智能化监测，生产智能化监测，检测管理软件服务，油气开发作业GPT，油气通GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .topback{
      position: fixed;
      z-index:999999999999;
      width: 100vw;
      padding: 0 0 0 2.86077411900635vw;
    }
    .background{
      padding: 7.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      width: 100%;
      height: 72.41285537700865vh;
      background-image: url("../../assets/equ1.png");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 15.09270704573548vh;
        text-align: center;
        font-size: 3.0151935298vw;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }
      .title4 {
        text-align: center;
        margin: 8.82076637824475vh auto 0 auto;
        width: 38.45869439630272vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 8.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .screenmake21 {
      padding: 7.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Roboto,sans-serif;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Roboto,sans-serif;
      }
      .line{
        height: 1vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);

        border-bottom: dashed black 1px;
        position: absolute;
        top:11vh;
        z-index: 1;

      }

      .grid {
        z-index: 2;
        margin-top: 0vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 2vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 5vh;
              height: 5vh;
              margin:0 auto 2vh auto;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 auto;
              color: black;
              float: right;

              .title1 {
                margin-top: 0;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: left;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake0{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .heightLight3{
        margin: 0 auto 0 auto;
        // cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1709416522vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #2f6eff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 2.1989601386vw;
        height:7.5624227441vh;
        background: #B3D0FF;
        border: #B3D0FF 1px solid;
      }
      .line{
        height: 3.831891223733vh;
        width: 4px;
        left: 50%;
        margin:0 auto;
        background-color: #B3D0FF;
        margin:0 auto;
      }
      .line0{
        width:48%;
        border-top-right-radius: 63px;
        border-top-left-radius: 63px;
        border-top:  #B3D0FF 4px solid;
        border-left:  #B3D0FF 4px solid;
        border-right:  #B3D0FF 4px solid;;
        height: 4.831891223733vh;
        margin:0 auto;
      }
      .grid0{
        display: grid;
        width: 70%;
        margin:0 auto;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 1vw;
        .card{
          position: relative;
          margin: 0 auto;
          text-align: center;
          .line{
            position: absolute;
            height:5.831891223733vh;
            width: 4px;
            left: 50%;
            top:-5.831891223733vh;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }

          .heightLight3{
            margin:0 auto;

            // cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.1709416522vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2f6eff;
            line-height: 3.3374536465vh;
            text-align: center;
            border-radius: 53px;
            width: fit-content;
            padding: 2.4624227441vh 2.1989601386vw;
            height:8.5624227441vh;
            background: transparent;
            border: #B3D0FF 4px solid;
          }
        }


      }
      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        column-gap:0 ;
        .card{
          // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: #EDF3FF ;
            .imgDesign{
              height:100%;
              width: 32vw;
              opacity: 1;
             display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title2{
                display: flex;
                flex-direction: column;
                justify-content: center; /* Center vertically */
                align-items: flex-start;
                margin: 8vh 8vw;
                text-align: justify;
                width: 25.23743500866551vw;
                font-size: 0.8243212016vw;
                height: fit-content;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 4.7194066749vh;
              }
              li{
                margin-top: 4.7194066749vh;
              }
            }
          }
        }


      }
    }
    .screenmake1{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .Light{
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        column-gap:0 ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          width: 100%;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:70.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF ;
            .imgDesign{
              width: 100%;
              height:100%;
              opacity: 1;
              background-image: url("../../assets/equ2.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title2{
                display: flex;
                flex-direction: column;
                justify-content: start; /* Center vertically */
                align-items: flex-start;
                margin: 5vh 5vw;
                text-align: justify;
                width: 16.23743500866551vw;
                font-size: 0.8243212016vw;
                height: fit-content;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 4.7194066749vh;
              }
              li{
                margin-top: 4.7194066749vh;
              }
            }
          }
        }


      }
    }
    .screenmake2{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .Light{
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        column-gap:0 ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          width: 100%;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:54.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF ;
            .imgDesign{
              width: 100%;
              height:100%;
              opacity: 1;
              background-image: url("../../assets/equ3.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title2{
                display: flex;
                flex-direction: column;
                justify-content: start; /* Center vertically */
                align-items: flex-start;
                margin: 5vh 5vw;
                text-align: justify;
                width: 16.23743500866551vw;
                font-size: 0.8243212016vw;
                height: fit-content;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 4.7194066749vh;
              }
              li{
                margin-top: 4.7194066749vh;
              }
            }
          }
        }


      }
    }
    .screenmake3{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .Light{
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        display: grid;
        grid-template-columns: minmax(0,1fr);
        column-gap:0 ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          width: 100%;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:70.87144622991347vh ;
            overflow:hidden;
            background: #FFFFFF ;
            .imgDesign{
              width: 100%;
              height:100%;
              opacity: 1;
              background-image: url("../../assets/equ4.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title2{
                display: flex;
                flex-direction: column;
                justify-content: start; /* Center vertically */
                align-items: flex-start;
                margin: 5vh 5vw;
                text-align: justify;
                width: 16.23743500866551vw;
                font-size: 0.8243212016vw;
                height: fit-content;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 4.7194066749vh;
              }
              li{
                margin-top: 4.7194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake4{
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;
      //background-color: #ebf1fd;
      .heighLight{
        color:white;
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        .card{
          //// cursor: pointer;
          margin:0 auto;
          height:65vh;
          width: 86%;
          padding:0.98887515451174vh 0 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:64vh;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width:fit-content;
              height:100%;
              opacity: 1;

              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              color: white;
              text-align: center;
              width: 30vw;
              margin:auto;
              float: right;
              .title1{
                text-align: left;
                font-size: 1.1709416522vw;
              }
              .title2{
                text-align: left;
                margin-top:25px;
                line-height: 4vh;
                font-size: 0.9243212016vw;
              }
              .success{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                column-gap: 1vw;
                margin-top: 2vh;
                .suc1{
                  width: 100%;
                  height: 8vh;
                  .title3{
                    text-align: center;
                    margin-top: 2vh;
                    font-size: 0.8243212016vw;
                  }

                }

              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:68vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 550px;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
          // cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }
    //.screenmake4{
    //  padding:6.8430160692vh  10.9763142692085vw ;
    //  width: 100%;
    //  height: 100.82941903584672vh;
    //  background: #ffffff;
    //  .Light{
    //    text-align: center;
    //    margin-top:2.4388133498vh ;
    //    height: 7.54017305315204vh;
    //    font-size: 1.5597920277vw;
    //    font-family: Sarial, sans-serif, "Microsoft Yahei";
    //    font-weight: 400;
    //    //color: #2168DB;
    //    line-height:2.8430160692vh;
    //  }
    //  .heighLight{
    //    text-align: center;
    //    height: 7.54017305315204vh;
    //    font-size: 2.2060658579vw;
    //    font-family: Sarial, sans-serif, "Microsoft Yahei";
    //    font-weight: 400;
    //    //color: #2168DB;
    //    line-height: 2.71940667490729vh;
    //  }
    //  .grid{
    //    margin-top:2.78739184177998vh ;
    //    display: grid;
    //    grid-template-columns: minmax(0,1fr);
    //    column-gap:0 ;
    //    .card{
    //      // cursor: pointer;
    //      width: fit-content;
    //      height:fit-content ;
    //      padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
    //      opacity: 1;
    //      width: 100%;
    //      .back{
    //        border-radius: 9px;
    //        display: flex;
    //        position: relative;
    //        box-shadow: 5px 0 14px 0px #D4E4FF;
    //        height:55.87144622991347vh ;
    //        overflow:hidden;
    //        background: #FFFFFF ;
    //        .imgDesign{
    //          width: 48.60311958405546vw;
    //          height:100%;
    //          opacity: 1;
    //          background-image: url("../../assets/equ5.jpg");
    //          background-position: center;
    //          background-size:cover;
    //          background-repeat: no-repeat;
    //          background-color: transparent;
    //        }
    //        .titleDesign{
    //          .Title2{
    //            display: flex;
    //            flex-direction: column;
    //            justify-content: start;/* Center vertically */
    //            align-items: flex-start;
    //            margin: 10vh 5vw;
    //            text-align: justify;
    //            width: 20.23743500866551vw;
    //            font-size: 0.8243212016vw;
    //            height: fit-content;
    //            font-family: Sarial, sans-serif, "Microsoft Yahei";
    //            font-weight: 400;
    //            line-height: 4.7194066749vh;
    //          }
    //          li{
    //            margin-top: 4.7194066749vh;
    //          }
    //        }
    //      }
    //    }
    //    .card:hover{
    //      .imgDesign {
    //        transform: scale(1.05);
    //      }
    //
    //    }
    //
    //
    //  }
    //}
  }

}
.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .background{
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 460px;
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      background-image: url("../../assets/gptModel61.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin:50px auto;
        width: fit-content;
        text-align: center;
        height: 23px;
        font-size: 23px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #111111;
        line-height: 8.2373300371vh;
      }
      .titlle3{
        position: absolute;
        bottom:75px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }
      .title4{
        position: absolute;
        left:10%;
        bottom:-35px;
        margin: 35px auto 0 auto;
        width: 80%;
        height: fit-content;
        font-size: 12px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 18px;
        border-radius: 15px;
        padding: 15px;
        background:white;  border: #e8eefa 1px solid;
      }
    }
    .screenmake21{
      width: 100vw;
      height: fit-content;
      padding:15px  4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            width: 90%;
            margin: 0 0 0 15px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 10px 0 10px;
            height:fit-content;
            display: flex;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake0{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:50px 4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .heightLight3{
        margin: 0 auto 0 auto;
        // cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size:16px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #2f6eff;
        line-height: 22px;
        text-align: center;
        border-radius: 9px;
        width: fit-content;
        padding: 10px 15px;
        height:40px;
        background: #B3D0FF;
        border: #B3D0FF 1px solid;
      }
      .line{
        height: 15px;
        width: 4px;
        left: 50%;
        margin:0 auto;
        background-color: #B3D0FF;
      }
      .line0{
        width: 245px;
        border-top-right-radius: 63px;
        border-top-left-radius: 63px;
        border-top:  #B3D0FF 4px solid;
        border-left:  #B3D0FF 4px solid;
        border-right:  #B3D0FF 4px solid;;
        height: 35px;
        margin:0 auto;
      }
      .grid0{
        width: 350px;
        margin:0 auto;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 5px;

        .card{
          position: relative;
          margin: 0 auto;
          text-align: center;
          .line{
            position: absolute;
            height: 35px;
            width: 4px;
            left: 50%;
            top:-35px;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }
          .line1{
            position: absolute;
            height: 75px;
            width: 4px;
            left: 50%;
            top:-75px;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }
          .heightLight3{
            margin:0 auto;

            // cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 8px;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2f6eff;
            line-height: 16px;
            text-align: center;
            border-radius: 9px;
            width: fit-content;
            padding: 5px 5px;
            height:45px;
            background: transparent;
            border: #B3D0FF 4px solid;
          }
        }


      }
      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        column-gap:0 ;
        .card{
          // cursor: pointer;
          width: fit-content;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: #EDF3FF ;
            .imgDesign{
              display: flex;
              opacity: 1;
              .img{
                width: 50%;
              }
            }
            .titleDesign{
              .Title2{
                display: flex;
                flex-direction: column;
                justify-content: center; /* Center vertically */
                align-items: flex-start;
                margin:5px 15px;
                text-align: justify;
                font-size: 14px;
                height: fit-content;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 22px;
              }
              li{
                margin-top: 5px;
              }
            }
          }
        }


      }
    }

    .screenmake1{
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:   #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 26px;

      }

      .Light{
        margin:19px 2vw 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:15px;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        column-gap:0 ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:15px;
          opacity: 1;
          width: 100%;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: #FFFFFF ;
            .imgDesign{
              width: 100%;
              height:158px;
              opacity: 1;
              background-image: url("../../assets/equ2.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title2{
                display: flex;
                flex-direction: column;
                justify-content: start; /* Center vertically */
                align-items: flex-start;
                margin: 5vh 5vw;
                text-align: justify;
                width: 16.23743500866551vw;
                font-size: 0.8243212016vw;
                height: fit-content;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 4.7194066749vh;
              }
              li{
                margin-top: 4.7194066749vh;
              }
            }
          }
        }


      }
    }
    .screenmake2{
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:  white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 26px;

      }

      .Light{
        margin:19px 2vw 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:15px;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        column-gap:0 ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:15px;
          opacity: 1;
          width: 100%;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background:  #ebf1fd;
            .imgDesign{
              width: 100%;
              height:158px;
              opacity: 1;
              background-image: url("../../assets/equ3.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title2{
                display: flex;
                flex-direction: column;
                justify-content: start; /* Center vertically */
                align-items: flex-start;
                margin: 5vh 5vw;
                text-align: justify;
                width: 16.23743500866551vw;
                font-size: 0.8243212016vw;
                height: fit-content;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 4.7194066749vh;
              }
              li{
                margin-top: 4.7194066749vh;
              }
            }
          }
        }


      }
    }
    .screenmake3{
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:   #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 26px;

      }

      .Light{
        margin:19px 2vw 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:15px;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        column-gap:0 ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:15px;
          opacity: 1;
          width: 100%;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: #FFFFFF ;
            .imgDesign{
              width: 100%;
              height:158px;
              opacity: 1;
              background-image: url("../../assets/equ4.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              .Title2{
                display: flex;
                flex-direction: column;
                justify-content: start; /* Center vertically */
                align-items: flex-start;
                margin: 5vh 5vw;
                text-align: justify;
                width: 16.23743500866551vw;
                font-size: 0.8243212016vw;
                height: fit-content;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 4.7194066749vh;
              }
              li{
                margin-top: 4.7194066749vh;
              }
            }
          }
        }
        .card:hover{
          .imgDesign {
            transform: scale(1.05);
          }

        }

      }
    }
    .screenmake4{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding:20px 15px;
      background: #212a3c;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;

        .card{
          // cursor: pointer;
          width: 98%;
          margin:0 auto;
          min-height: 250px ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            min-height: 540px ;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:254px;
              opacity: 1;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 15px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:15px;
                font-size: 14px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:12px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 540px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -158px -10px  ;
          // cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
    //.screenmake4{
    //  padding:6.8430160692vh  10.9763142692085vw ;
    //  width: 100%;
    //  height: 100.82941903584672vh;
    //  background: #ffffff;
    //  .Light{
    //    text-align: center;
    //    margin-top:2.4388133498vh ;
    //    height: 7.54017305315204vh;
    //    font-size: 1.5597920277vw;
    //    font-family: Sarial, sans-serif, "Microsoft Yahei";
    //    font-weight: 400;
    //    //color: #2168DB;
    //    line-height:2.8430160692vh;
    //  }
    //  .heighLight{
    //    text-align: center;
    //    height: 7.54017305315204vh;
    //    font-size: 2.2060658579vw;
    //    font-family: Sarial, sans-serif, "Microsoft Yahei";
    //    font-weight: 400;
    //    //color: #2168DB;
    //    line-height: 2.71940667490729vh;
    //  }
    //  .grid{
    //    margin-top:2.78739184177998vh ;
    //    display: grid;
    //    grid-template-columns: minmax(0,1fr);
    //    column-gap:0 ;
    //    .card{
    //      // cursor: pointer;
    //      width: fit-content;
    //      height:fit-content ;
    //      padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
    //      opacity: 1;
    //      width: 100%;
    //      .back{
    //        border-radius: 9px;
    //        display: flex;
    //        position: relative;
    //        box-shadow: 5px 0 14px 0px #D4E4FF;
    //        height:55.87144622991347vh ;
    //        overflow:hidden;
    //        background: #FFFFFF ;
    //        .imgDesign{
    //          width: 48.60311958405546vw;
    //          height:100%;
    //          opacity: 1;
    //          background-image: url("../../assets/equ5.jpg");
    //          background-position: center;
    //          background-size:cover;
    //          background-repeat: no-repeat;
    //          background-color: transparent;
    //        }
    //        .titleDesign{
    //          .Title2{
    //            display: flex;
    //            flex-direction: column;
    //            justify-content: start;/* Center vertically */
    //            align-items: flex-start;
    //            margin: 10vh 5vw;
    //            text-align: justify;
    //            width: 20.23743500866551vw;
    //            font-size: 0.8243212016vw;
    //            height: fit-content;
    //            font-family: Sarial, sans-serif, "Microsoft Yahei";
    //            font-weight: 400;
    //            line-height: 4.7194066749vh;
    //          }
    //          li{
    //            margin-top: 4.7194066749vh;
    //          }
    //        }
    //      }
    //    }
    //    .card:hover{
    //      .imgDesign {
    //        transform: scale(1.05);
    //      }
    //
    //    }
    //
    //
    //  }
    //}
  }

}
</style>
