<template>
  <div>
    <div v-if="model===false">
      <div class="total"  ref="total">
        <div class="topback"  :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}">
          <headtip  :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
        </div>
        <div @click="changeheadVisable">   <div class="screen1">
          <kefu @toptop="topTop"></kefu>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->

          <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
          <div class="layer2">
            <div class="title1">解释类GPTs</div>
            <div class="title2">解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持</div>
            <div class="title4">*油气人工智能模型的泛化能力有限，实际应用中可能需根据具体场景和需求进行适配和优化。如您有合作意向或产品咨询，请随时与我们联系。
              <br>①&nbsp;解释类服务，由安东石油线下技术人员提供服务<br>②&nbsp;GPT开发服务，由安东人工智能专业技术人员提供服务</div>
            <div class="titlle3" @click="clickConsult('油气通')">
              产品咨询
            </div>
          </div>
          <!--      平台大图-->
          <div class="layer3"></div>
        </div>
        <div class="screenmake2">
          <div class="heighLight">   <span>解释类GPTs服务</span></div>
          <div class="grid">
            <a href="/fiber" target="_blank">
            <div class="card" >
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/gpt73.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">  光纤井筒漏点实时预测平台</div>
                  <div class="Title2">光纤井筒漏点预测软件，融合了先进的传感技术和深度学习算法，为石油和天然气行业提供了一个高效、精准的实时监测和预警解决方案，确保井筒安全，降低维护成本。</div>
                  <div class="backconsult">
                    免费体验
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size:14px;margin-left: 15px;" class="el-icon-right"></i>
                  </div>
                </div>
              </div>


            </div>
            </a>
<!--            <div class="card" @click="detailPC1()">-->
            <div class="card" >
              <div class="back">
                <div class="imgDesign">

                </div>
                <div class="titleDesign">
                  <div class="Title1"> 储层识别/岩性识别</div>
                  <div class="Title2">

                    油气储层是在油气勘探工程中，地下存在油气聚集的岩层区域。储层的特征包括了岩性、物性、含油气性等方面，这也是储层预测的主要方向。储层岩性是描述储层矿物质组成成分的主要特征，反映了岩层的储藏性能和储层特征，常用参数包括储层岩石物理结构、分布范围、储层顶界面构造形态、储层厚度等。综合储层特征，实现对油气富集区域即甜点进行预测，为油气钻探指明位置和开采方案。  </div>
<!--                  <div class="backconsult">-->
<!--                    免费体验-->
<!--                  </div>-->
<!--                  <div class="backconsultArrow">-->
<!--                    <i style="font-size:14px;margin-left: 15px;" class="el-icon-right"></i>-->
<!--                  </div>-->
                </div>
              </div>


            </div>

<!--            <div class="card" @click="detailPC3()">-->
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/gpt63.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">  设备智能监测</div>
                  <div class="Title2">通过在线监测系统，可以对修井机的运行状态数据进行实时监测。这种实时性不仅为现场操作人员提供了技术支持，而且使油田各级管理人员能够及时了解生产现场的状况，从而提供决策依据。</div>
<!--                  <div class="backconsult">-->
<!--                    免费体验-->
<!--                  </div>-->
<!--                  <div class="backconsultArrow">-->
<!--                    <i style="font-size:14px;margin-left: 15px;" class="el-icon-right"></i>-->
<!--                  </div>-->
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/gpt25.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1"> 地质甜点/工程甜点识别</div>
                  <div class="Title2">
                    油气勘探开发的过程中，会揭示大面积的含油气区及大段的含油气层段，但是往往其中一部分是具有当前经济、技术条件下具有较好开发效益的部分，称之为“甜点”。以实测地质参数、工程参数为输入，利用神经网络模拟技术，通过大数据智能分析地质参数与弹性参数的相关关系，解译出相应的甜点评价参数。
                    </div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/gpt35.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">
                    人工裂缝改造体积动态评价</div>
                  <div class="Title2">
                    以各平台各井各段实际的天然裂缝响应、地应力、脆性情况；定性及定量描述的人工裂缝复杂性程度；定性定量的压窜程度等开展综合对比评估。
                  </div>
                  </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/gpt74.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">  光纤产液剖面、产气剖面监测</div>
                  <div class="Title2">
                    连油光纤产剖测试技术通过将铠装光缆提前泵入连续油管内，作业时通过连续油管车组将连续油管传输至目的测试层位，从而实现光纤分布式监测。光纤产剖监测技术以光纤本身作为传感器，无需额外井下测井仪器，通过测量背向散射光，探测出井下光纤沿线的温度、声波 等数据，通过实时迭代方式，对井内产液分析，从而实现对油气井井内各簇生产剖面监测。
                  </div>
                  </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/gpt75.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1"> 压裂进砂进液动态监测</div>
                  <div class="Title2">
                    光纤压裂监测技术主要利用同井或邻井中已布置的分布式光纤进行分布式声波震动 (DAS)及分布式应变传感技术(DSS)监测，通过同井DAS监测，直观显示段簇进液进砂情况，指导压裂暂堵转向优化地层改造。通过实时监测的低频应变信号，描绘压裂裂缝的发育过程及状态，实时监测指导压裂；提取微地震信号事件，评估压裂效果优化压裂方案。
                     </div>
                </div>
              </div>


            </div>
          </div>

        </div>
        <div class="screenmake3">
          <div class="heighLight">成功案例</div>
          <div class="grid">
            <el-carousel indicator-position="outside" arrow="always" interval="5000">
              <el-carousel-item v-for="item in success" :key="item">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" :src="item.img"/>
                    </div>
                    <div class="titleDesign">
                      <div class="title1">{{ item.title1 }}</div>
                      <div class="title2" v-html="item.title2"></div>
                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
          <bottomTip></bottomTip></div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
         <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalModel"  ref="totalModel">
   <div class="screen1">
          <kefuMobile @topMobile="topTopmobile"></kefuMobile>
          <headtip @showDialog="showPartner"></headtip>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->

          <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
          <div class="layer2">
            <div class="title1">解释类GPTs</div>
            <div class="title2">基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持</div>

          </div>
          <div class="titlle3" @click="clickConsult('油气通')">
            产品咨询
          </div>
          <div class="title4">*油气人工智能模型的泛化能力有限，实际应用中可能需根据具体场景和需求进行适配和优化。如您有合作意向或产品咨询，请随时与我们联系。  <br>①&nbsp;解释类服务，由安东石油线下技术人员提供服务<br>②&nbsp;GPT开发服务，由安东人工智能专业技术人员提供服务</div>
          <!--      平台大图-->
          <div class="layer3"></div>
        </div>
        <!-- <div class="screenmake0">
          <div class="heighLight">油藏地质模型架构</div>
          <div class="heightLight2">以油藏地质研究及各个应用场景数据为中心，通过解释脑形成知识成果，智能优化油藏地质研究成果，从而达到油藏地质知识智能解释处理、为智能钻井压裂等应用场景提供油藏地质知识的目的。</div>
          <div class="grid">
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/gptModel12.png">
                </div>
              </div>
            </div>
          </div>

        </div> -->
        <div class="screenmake2">
          <div class="heighLight">   <span>解释类GPTs</span></div>
          <div class="grid">
            <div class="card">
              <div class="back" @click="detail2">
                <div class="imgDesign1"></div>
                <div class="titleDesign">
                  <div class="Title1" style="margin-top: 205px">    <span style="border-radius: 50%; width:16px;font-size:16px;height:16px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 4px"></span>&nbsp;光纤井筒漏点实时预测平台</div>
                  <div class="Title2">
                    光纤井筒漏点预测软件，融合了先进的传感技术和深度学习算法，为石油和天然气行业提供了一个高效、精准的实时监测和预警解决方案，确保井筒安全，降低维护成本。
                  </div>
                  <div class="backconsult" @click="detail2">
                    免费体验
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size:14px;margin-left: 15px;" class="el-icon-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back"  >
<!--              <div class="back"  @click="detail1">-->
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1" style="margin-top: 220px">    <span style="border-radius: 50%; width:16px;font-size:16px;height:16px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 4px"></span>&nbsp;储层识别/岩性识别</div>
                  <div class="Title2">
                    油气储层是在油气勘探工程中，地下存在油气聚集的岩层区域。储层的特征包括了岩性、物性、含油气性等方面，这也是储层预测的主要方向。储层岩性是描述储层矿物质组成成分的主要特征，反映了岩层的储藏性能和储层特征，常用参数包括储层岩石物理结构、分布范围、储层顶界面构造形态、储层厚度等。综合储层特征，实现对油气富集区域即甜点进行预测，为油气钻探指明位置和开采方案。

                  </div>
<!--                  <div class="backconsult" @click="detail1">-->
<!--                    免费体验-->
<!--                  </div>-->
<!--                  <div class="backconsultArrow">-->
<!--                    <i style="font-size:14px;margin-left: 15px;" class="el-icon-right"></i>-->
<!--                  </div>-->
                </div>
              </div>


            </div>

            <div class="card">
              <div class="back">
<!--              <div class="back" @click="detail3">-->
                <div class="imgDesign2"></div>
                <div class="titleDesign">
                  <div class="Title1">    <span style="border-radius: 50%; width:16px;font-size:16px;height:16px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 4px"></span>&nbsp;设备智能监测</div>
                  <div class="Title2">
                    通过在线监测系统，可以对修井机的运行状态数据进行实时监测。这种实时性不仅为现场操作人员提供了技术支持，而且使油田各级管理人员能够及时了解生产现场的状况，从而提供决策依据。
                  </div>
<!--                  <div class="backconsult" @click="detail3">-->
<!--                    免费体验-->
<!--                  </div>-->
<!--                  <div class="backconsultArrow">-->
<!--                    <i style="font-size:14px;margin-left: 15px;" class="el-icon-right"></i>-->
<!--                  </div>-->
                </div>
              </div>
            </div>

            <div class="card2">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">     <span style="border-radius: 50%; width:16px;font-size:16px;height:16px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 4px">3</span>&nbsp;地质甜点/工程甜点识别</div>
                  <div class="Title2">油气勘探开发的过程中，会揭示大面积的含油气区及大段的含油气层段，但是往往其中一部分是具有当前经济、技术条件下具有较好开发效益的部分，称之为“甜点”。以实测地质参数、工程参数为输入，利用神经网络模拟技术，通过大数据智能分析地质参数与弹性参数的相关关系，解译出相应的甜点评价参数。</div>
                </div>
              </div>


            </div>

            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">     <span style="border-radius: 50%; width:16px;font-size:16px;height:16px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 4px"></span>&nbsp;人工裂缝改造体积动态评价</div>
                  <div class="Title2">以各平台各井各段实际的天然裂缝响应、地应力、脆性情况；定性及定量描述的人工裂缝复杂性程度；定性定量的压窜程度等开展综合对比评估。</div>
                </div>
              </div>


            </div>

            <div class="card1">
              <div class="back">
                <div class="imgDesign1"></div>
                <div class="titleDesign">
                  <div class="Title1">     <span style="border-radius: 50%; width:16px;font-size:16px;height:16px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 4px"></span>&nbsp;光纤产液剖面、产气剖面监测</div>
                  <div class="Title2">连油光纤产剖测试技术通过将铠装光缆提前泵入连续油管内，作业时通过连续油管车组将连续油管传输至目的测试层位，从而实现光纤分布式监测。光纤产剖监测技术以光纤本身作为传感器，无需额外井下测井仪器，通过测量背向散射光，探测出井下光纤沿线的温度、声波 等数据，通过实时迭代方式，对井内产液分析，从而实现对油气井井内各簇生产剖面监测。</div>
                </div>
              </div>

            </div>

            <div class="card1">
              <div class="back">
                <div class="imgDesign2"></div>
                <div class="titleDesign">
                  <div class="Title1">     <span style="border-radius: 50%; width:16px;font-size:16px;height:16px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 4px"></span>&nbsp;压裂进砂进液动态监测</div>
                  <div class="Title2">光纤压裂监测技术主要利用同井或邻井中已布置的分布式光纤进行分布式声波震动 (DAS)及分布式应变传感技术(DSS)监测，通过同井DAS监测，直观显示段簇进液进砂情况，指导压裂暂堵转向优化地层改造。通过实时监测的低频应变信号，描绘压裂裂缝的发育过程及状态，实时监测指导压裂；提取微地震信号事件，评估压裂效果优化压裂方案。</div>
                </div>
              </div>


            </div>
          </div>

        </div>
        <div class="screenmake3">
          <div class="heighLight">成功案例</div>
          <div class="grid">
            <el-carousel indicator-position="outside" arrow="always" interval="5000">
              <el-carousel-item v-for="item in success" :key="item">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" :src="item.img"/>
                    </div>
                    <div class="titleDesign">
                      <div class="title1">{{ item.title1 }}</div>
                      <div class="title2" v-html="item.title2"></div>
                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>


        </div>
        <div ref="bottom">
          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
         <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import kefu from "@/views/kefu.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import PartnerTip from "@/components/partnerTip.vue";
import consultTip from "@/components/consultTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import recognition from "@/views/third/recognition.vue";
import {shareUrl} from "@/utils/vxshare";

export default {

  name: "",
  computed: {
    recognition() {
      return recognition
    }
  },

  props: [],

  components: {bottomTip, consultTip, PartnerTip, headtip,kefu,kefuMobile},

  data() {

   return {
     changeVisable:1,
      title:'',
      pageTitle: '解释类GPTs_油气行业解释类大模型人工智能应用-油气通GPT',
      pageDescription: '油气通GPT云平台提供解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持。',
      pageKeywords: '解释GPTs，解释类GPTs，储层识别，岩性识别，设备智能监测，油气通，油气通GPT，油气通GPT云平台，油气通大模型，油气通GPT大模型',

      model:false,
     scrollback:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[
        {
          img:require("@/assets/gpt76.png"),
          title1:"XX油田光纤智能油气产剖监测项目",
          title2:"安东为西南油气田客户提供油气产剖监测项目服务， 智能分析解释获得产液剖面，优化生产制度和调整井网部署，提高采收率；通过压裂液返排分析、压裂效果的评价，反向优化压裂方案。",
        },
        {
          img:require("@/assets/gpt66.png"),
          title1:"钻修机在线监测及预测性维护",
          title2:"建设钻修机设备预测性维护平台，实现设备在线状态监测（智能预警、智能诊断、智能评估等），目前已在中海油海上平台上取得成果应用。<br><span style=\"margin-top: 5vh\">重点提升方向：</span>  <li>提升多源异构信息数据共享能力，建立统一的数据服务接口、信息采集标准、数据格式、通信协议，实现数据的统一集中管理。</li>\n" +
              "                        <li style=\"margin-top: 0.5vh\">提升人工智能技术应用场景赋能能力，基于大数据平台和各子系统不同的应用场景建立算法和逻辑控制模型，对感知信息进行智能分析、自学习与决策，实现关键设备预测性维护。</li>\n" +
              "                        <li style=\"margin-top: 0.5vh\">提升钻修机作业风险管控决策能力。</li>",
        },
      ]
    }

  },
  beforeRouteLeave(to, from, next) {
    // 保存滚动位置
    if(this.model===true){
      this.scrollPosition = this.$refs.totalModel.scrollTop;
    }
    next();
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 恢复滚动位置
      vm.$nextTick(() => {
        if(vm.model===true){
          vm.$refs.totalModel.scrollTop = vm.scrollPosition;
        }


      });
    });
  },
  methods: {
    topTop() {
      console.log("fuzujianchuanzhi")
      // const element = this.$refs.total;
      // const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },

    topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.totalModel;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
      changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    clickConsult(item){

      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    detail1(){
      this.$router.push("/recognition1")
    },
    detail2(){
      this.$router.push("/fiber")
    },
    detail3(){
      this.$router.push("/equipment1")
    },
    detailPC1(){
      window.open("/recognition", "_blank");
    },
    detailPC2(){
      window.open("/fiber", "_blank");
    },
    detailPC3(){
      window.open("/equipment", "_blank");
    },

    handleScroll() {
      if(this.model===false){
        this.scrollHeight = this.$refs.total.scrollTop;
      }
      else{
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if( this.scrollHeight<100){
        this.scrollTrue=true
      }
      else{
        this.scrollTrue=false
      }
      if( this.scrollHeight<50){
        this.scrollback=false
      }
      else{
        this.scrollback=true
      }
      console.log("gaodu",this.scrollHeight<100)
    },
    showPartner() {
      this.dialogconsult = true;
    },

    hideDialog() {
      this.dialogVisible = false;
    },
    hide() {
      this.dialogconsult = false;
    },
    skipp3() {
      if(localStorage.getItem("home")==="0"){
        const element = this.$refs.bottom;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        this.$nextTick(() => {
          this.$refs.totalModel.scrollTop = rect
        });
      }


    },
  },
  beforeDestroy() {

    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);
    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
    // if (localStorage.getItem("skip") === '3') {
    //   this.$nextTick(() => {
    //     this.skipp3();
    //   });
    // }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '解释类GPTs_油气行业解释类大模型人工智能应用-油气通GPT',
        pageDescription: '油气通GPT云平台提供解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持。',
        pageKeywords: '解释GPTs，解释类GPTs，储层识别，岩性识别，设备智能监测，油气通，油气通GPT，油气通GPT云平台，油气通大模型，油气通GPT大模型',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.total {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  //height: 100vh;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 74vh;
    padding: 6.84301606922126vh 2.86077411900635vw  2.84301606922126vh 6.86077411900635vw;
    background-image: url("../../assets/gpt71.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */

    .layer2 {
      margin-top: 3vh;
      margin-left:2vw;

      .title1 {
        width: fit-content;
        height: 7.77626699629172vh;
        font-size: 2.5vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2f6eff;
        line-height: 12.23733003708282vh;
      }

      .title2 {
        margin-top: 3vh;
        width: 35vw;
        height: fit-content;
        text-align: justify;
        font-size: 1.1597920277vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2f6eff;
        line-height: 5vh;
      }
      .title4 {
        margin-top: 1vh;
        width: 35vw;
        font-size: 0.8287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        line-height:5vh;
      }
      .titlle3{
        position: absolute;
        bottom: 7.78739184177998vh;
        margin-top: 4.07911001236094vh;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }

    //.layer3 {
    //  position: absolute;
    //  top: 5.06798516687268vh;
    //  right: 11.61178509532062vw;
    //  width: 47.42923165800116vw;
    //  height: 72.80593325092707vh;
    //  background-image: url("../assets/GPTplant.png");
    //  background-size: 100% auto;
    //  background-position: center;
    //  background-repeat: no-repeat;
    //  background-color: transparent;
    //  opacity: 1;
    //}
  }
  .screenmake2{
    padding: 7.78739184177998vh 8.09763142692085vw;
    width: 100%;
    height: fit-content;
    background: white;
    .heighLight{
      display: flex;
      justify-content: center;
      text-align: center;
      height:8.23733003708282vh;
      font-size: 2.2060658579vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 500;
      color: #111111;
      line-height: 4.23733003708282vh;
    }


    .grid{
      margin-top:2.78739184177998vh ;
      display: grid;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
      column-gap:15px ;
      row-gap: 20px;
      .card{
        cursor: pointer;
        width: fit-content;

        height:fit-content ;
        padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;
        .back{
          border-radius: 9px;
          position: relative;
          border: white 3px solid;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          min-height: 68vh;
          overflow:hidden;
          background: #ebf1fd;;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height:23vh;
            opacity: 1;
            background-image: url("../../assets/gpt13.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top:26vh;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 2.7194066749vh;
              font-size: 1.1709416522vw;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 700;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{

              margin: 3vh 1.8597920277vw 30px 1.8597920277vw;
              text-align: justify;
              height: fit-content;
              font-size: 0.8243212016vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              line-height: 3.194066749vh;
            }
            .backconsult{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);

              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
        .back:hover{
          .imgDesign {
            transform: scale(1.05);
          }
          .backconsult{
            font-weight: bold;
          }

        }
      }

      .card1{
        //cursor: pointer;
        width: fit-content;

        height:fit-content ;
        padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;
        .back{
          border-radius: 9px;
          position: relative;
          border: white 3px solid;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          min-height: 65vh;
          overflow:hidden;
          background: #ebf1fd;;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height:23vh;
            opacity: 1;
            background-image: url("../../assets/gpt13.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top:26vh;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 2.7194066749vh;
              font-size: 1.1709416522vw;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 700;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{

              margin: 3vh 1.8597920277vw 30px 1.8597920277vw;
              text-align: justify;
              height: fit-content;
              font-size: 0.8243212016vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              line-height: 3.194066749vh;
            }
            .backconsult{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);

              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
        .back:hover{
          .imgDesign {
            transform: scale(1.05);
          }

        }
      }

      .card1:hover{
        .imgDesign {
          transform: scale(1.05);
        }
      }

    }
  }
  .screenmake3{
    padding: 7.78739184177998vh 5.09763142692085vw;
    width: 100%;
    height: fit-content;
    background: #212a3c;
    //background-color: #ebf1fd;
    .heighLight{
      color:white;
      text-align: center;
      height: 7.54017305315204vh;
      font-size: 2.2060658579vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      //color: #2168DB;
      line-height: 2.71940667490729vh;
    }
    .grid{
      margin-top:2.78739184177998vh ;
      .card{
        margin:0 auto;
        width: 86%;
        height:65vh;
        padding:0.98887515451174vh 0 0.98887515451174vh 0;
        opacity: 1;
        .back{
          border-radius: 9px;
          display: flex;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          height:64vh;
          overflow:hidden;
          background: #19202e;
          .imgDesign{
            width: fit-content;
            height:100%;
            opacity: 1;
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 1.5248454883vh 1.5248454883vw;
            color: white;
            width: 30vw;
            height: fit-content;
            margin:auto;
            text-align: justify;
            float: right;
            .title1{
              font-size: 1.1709416522vw;
            }
            .title2{
              margin-top: 25px;
              line-height: 4vh;
              font-size: 0.9243212016vw;
            }
            .success{
              display: grid;
              grid-template-columns: minmax(0,1fr) minmax(0,1fr);
              column-gap: 1vw;
              margin-top: 3vh;
              .suc1{
                width: 100%;
                height: 6vh;
                .title3{
                  text-align: center;
                  margin-top: 1vh;
                  font-size: 0.8243212016vw;
                }

              }

            }


          }

        }
      }

      ::v-deep .el-carousel__container{
        height:68vh !important;
      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      ::v-deep .el-carousel__container {
        position: relative;
        height: 550px;
      }
      ::v-deep .el-carousel__arrow {
        outline: 10vh;
        padding: 0;
        margin: 0  ;
        cursor: pointer;

        border-radius: 50%;
        width: 55px;
        height: 55px;
        background-color: rgba(249, 250, 252, 0.2);
        border: rgba(255, 255, 255, 0.5) 1px solid;
        color: #fff;
        position: absolute;
        top: 50%;
        z-index: 100;
        transform: translateY(-50%);
        text-align: center;
        font-weight:800;
        font-size: 25px;
      }
    }
  }
}
.totalModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  //height: 100vh;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 460px;
    padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
    background-image: url("../../assets/gptModel71.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */
    .titlle3{
      position: absolute;
      bottom:80px;
      left:50%;
      transform: translateX(-60%);
      font-size: 14px;
      font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 53px;
      width: fit-content;
      margin: 0 15px;
      padding: 5px 4.6875vw;
      line-height: 15px;
      height: 35px;
      color:white;
      background-color: #2f6eff;
      border: 1px solid #2f6eff;
      overflow: hidden;


    }
    .title4{
      position: absolute;
      left:5%;
      bottom:-55px;
      margin: 35px auto 0 auto;
      width: 90%;
      height: fit-content;
      font-size: 12px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #111111;
      line-height: 18px;
      border-radius: 15px;
      padding: 15px;
      background:white;
      border: #e8eefa 1px solid;
    ;

    }
    .layer2 {
      position: absolute;
      left:0;

      .title1 {
        margin:35px auto 0 auto;
        width: fit-content;
        text-align: center;
        height: fit-content;
        font-size: 22px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #111111;
        line-height: 22px;
      }

      .title2 {
        margin: 16px auto;
        width: 90%;
        text-align: center;
        height: 17px;
        font-size: 12px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 20px;
      }
    }
  }
  .screenmake2{
    padding:75px 4.6875vw 20px 4.6875vw;
    width: 100%;
    height: fit-content;
    background: #ebf1fd;
    .heighLight {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 21px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 26px;

    }

    .grid{
      margin-top:2.78739184177998vh ;
      display: grid;
      grid-template-columns: minmax(0,1fr);
      column-gap:0 ;
      row-gap: 15px;
      .card{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 9px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 210px;
            opacity: 1;
            background-image: url("../../assets/gpt13.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign1{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height:180px;
            opacity: 1;
            background-image: url("../../assets/gpt73.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign2{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 155px;
            opacity: 1;
            background-image: url("../../assets/gpt63.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 170px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 20px;
              font-size: 16px;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 700;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 45px 12px;
              text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }

      }
      .card2{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 9px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 240px;
            opacity: 1;
            background-image: url("../../assets/gpt14.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 250px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 20px;
              font-size: 16px;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 700;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 15px 12px;
              text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
      .card1{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 9px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            height: 185px;
            opacity: 1;
            background-image: url("../../assets/gpt35.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign1{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            height: 185px;
            opacity: 1;
            background-image: url("../../assets/gpt74.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign2{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            height: 185px;
            opacity: 1;
            background-image: url("../../assets/gpt75.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 195px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 20px;
              font-size: 16px;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 700;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 15px 12px;
              text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  .screenmake3{
    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;

    padding:20px 15px;
    background:#2C3E50;
    .heighLight{
      text-align: center;
      height:21px;
      opacity: 1;
      font-size: 21px;
      color: white;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 26px;
    }
    .heightLight2{
      margin:19px 2vw;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }
    .grid{
      margin-top:2.78739184177998vh ;
      position: relative;

      .card{
        cursor: pointer;
        width: 98%;
        margin:0 auto;
        min-height: 250px ;
        padding:0 0.69324090121317vw 0 0;
        opacity: 1;
        color: black;
        .back{
          border-radius: 9px;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          min-height:580px ;
          overflow:hidden;
          background-color: #19202e;
          .imgDesign{
            width: 100%;
            height:fit-content;
            opacity: 1;
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 15px 20px 25px 20px;
            color: white;
            width:100%;
            float: right;
            .title1{
              margin-top:15px;
              font-size: 16px;
            }
            .title2{
              margin-top: 15px;
              line-height: 20px;
              font-size:14px;
              ::v-deep li{
                margin-top:5px;
                font-size:12px;

              }
            ;
            }


          }

        }
      }

      ::v-deep .el-carousel__container{
        height: 580px !important;

      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }

      ::v-deep .el-carousel__arrow {
        display: block;
        //border: 1px solid white;
        outline: -5px;
        padding: 0;
        margin: -168px -10px  ;
        cursor: pointer;

        border-radius: 0;
        width: 25px;
        height: 48px;
        background: rgba(245, 246, 250, 0.8);
        color: #131313;
        position: absolute;
        top: 50%;
        z-index: 100;
        transform: translateY(-50%);
        text-align: center;
        font-size: 14px;
      }
    }
  }
}
</style>
