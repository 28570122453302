<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole" ref="total">
        <div class="makeHole1">
        <kefu @toptop="topTop"></kefu>
          <div class="topback"  :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}">
            <headtip  :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
          </div>
            <div @click="changeheadVisable">
        <div class="background">
            <!--     文字-->
            <div class="titlle1">地层监测解决方案</div>
            <div class="title4">*该解决方案是现场的油气开发技术服务与人工智能模型融合，通过现场的解决方案为客户提供服务，如需对该方案进一步了解合作，请随时与我们联系。</div>
            <div class="titlle3" @click="dialogVisible=true">
              解决方案咨询
            </div>
          </div>

          <div class="screenmake0">
            <div class="heighLight">地层监测服务总览</div>
            <div class="heightLight2">安东深地智光数据技术公司以定制化、系统化为特色，提供油气开发全过程数据采集及解释服务，业务包括光纤监测技术、数字岩石技术、油气藏监测技术、测井技术</div>

            <div class="heightLight3">地层监测</div>
            <div class="line"></div>
            <div class="line0"></div>
            <div class="grid0">
              <div class="card">
                <div class="heightLight3">光纤监测技术</div>
              </div>
              <div class="card">
                <div class="line"></div>
                <div class="heightLight3">数字岩石技术</div>
              </div>
              <div class="card">
                <div class="line"></div>
                <div class="heightLight3">油气藏检测技术</div>
              </div>
              <div class="card">
                <div class="heightLight3">测井技术</div>
              </div>


            </div>

          </div>
              <div class="screenmake21" style="padding: 0 11.0976314269vw 7.7873918418vh">
                <div class="heighLight" style="font-size: 2.2060658579vw;font-weight: unset">业务痛点与挑战</div>
                <div class="line"></div>
                <div class="grid">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img loading="lazy" src="@/assets/realtime.png" style="border-radius: 9px">
                      </div>
                      <div class="titleDesign">
                        <div class="title1">缺乏实时、精准的井筒数据
                        </div>
                        <div class="title2">
                          无法及时获取温度、压力和声波等关键数据，难以及时响应井下变化。
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img loading="lazy" src="@/assets/foucs.png" style="border-radius: 9px">
                      </div>
                      <div class="titleDesign">
                        <div class="title1">漏点难以及时发现和精确定位
                        </div>
                        <div class="title2">
                          增加安全和环境风险，影响维修效率和成本控制。
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img loading="lazy" src="@/assets/select.png" style="border-radius: 9px">
                      </div>
                      <div class="titleDesign">
                        <div class="title1">井筒完整性风险难以监测
                        </div>
                        <div class="title2">
                          影响安全和运营，无法及时发现井筒泄露和结构损坏等问题。
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img loading="lazy" src="@/assets/Intelligence.png" style="border-radius: 9px">
                      </div>
                      <div class="titleDesign">
                        <div class="title1">缺乏智能分析工具
                        </div>
                        <div class="title2">
                          难以从大量数据中提取有价值的洞察，无法优化生产制度，导致采收率低下。
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img loading="lazy" src="@/assets/cost.png" style="border-radius: 9px">
                      </div>
                      <div class="titleDesign">
                        <div class="title1">维护和运营成本高
                        </div>
                        <div class="title2">
                          问题无法及时发现和处理，导致维修成本和停机时间增加。
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img loading="lazy" src="@/assets/monitor.png" style="border-radius: 9px">
                      </div>
                      <div class="titleDesign">
                        <div class="title1">传统监测手段不足
                        </div>
                        <div class="title2">
                          无法满足高效、安全、智能化的油气生产监测需求，影响整体运营效率。
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
          <div class="screenmake">
            <div class="heighLight">
              <span style="background-color: #FF6400;border-radius: 50%; width:2.2060658579vw;font-size:2vw;height:2.2060658579vw;display: flex;justify-content: center;align-items: center;color: white;  ">1</span>
              <span>&nbsp;&nbsp;储气库井筒完整性光纤实时动态智能监测</span>
            </div>
            <div class="Light">部署智能光纤监测系统，对井筒泄露、环空保护液面异常、管柱震动、井下压力及注采生产动态进行实时监测和预警</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">环空保护液位面深度动态监测图</div>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">漏点实时监测图</div>
                  </div>
                </div>

              </div>

            </div>

          </div>
          <div class="screenmake2">
            <div class="heighLight">
              <span style="background-color: #FF6400;border-radius: 50%; width:2.2060658579vw;font-size:2vw;height:2.2060658579vw;display: flex;justify-content: center;align-items: center;color: white;  ">2</span>
              <span>&nbsp;&nbsp;光纤漏点智能监测</span>
            </div>
            <div class="Light">光纤漏点智能监测包括实时监测分布式温度声波信号、低频应变信号，漏点异常监测预警及定位，管柱震动评价和漏点原因分析</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">钢丝光纤漏点实时监测</div>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">钢丝光纤漏点实时监测</div>
                  </div>
                </div>

              </div>

            </div>

          </div>
          <div class="screenmake3">
            <div class="heighLight">
              <span style="background-color: #FF6400;border-radius: 50%; width:2.2060658579vw;font-size:2vw;height:2.2060658579vw;display: flex;justify-content: center;align-items: center;color: white;  ">3</span>
              <span>&nbsp;&nbsp;光纤智能油气生产监测</span>
            </div>
            <div class="Light">部署智能光纤监测系统，对生产时的井筒温度剖面、声波震动信号及井下压力实时监测，智能分析解释获得层位产出数据，指导优化生产制度，提高采收率</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">光纤产出剖面综合解释图</div>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">光纤产出剖面综合解释图</div>
                  </div>
                </div>

              </div>

            </div>

          </div>
          <div class="screenmake4">
            <div class="heighLight">
              <span style="background-color: #FF6400;border-radius: 50%; width:2.2060658579vw;font-size:2vw;height:2.2060658579vw;display: flex;justify-content: center;align-items: center;color: white;  ">4</span>
              <span>&nbsp;&nbsp;光纤智能压裂监测</span>
            </div>
            <div class="Light">部署智能光纤监测系统，对压裂过程中的段簇进液进砂、裂缝发育、压裂冲击情况实时动态监测，评估压裂效果</div>

            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">智能压裂监测微地震监测</div>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">段簇改造监测</div>
                  </div>
                </div>


              </div>

            </div>

          </div>
          <div class="screenmake5">
            <div class="heighLight">成功案例</div>
            <div class="grid">
              <el-carousel indicator-position="outside" :current-index="currentIndex" arrow="always" interval="5000">
                <el-carousel-item v-for="(item, index) in success" :key="index">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img loading="lazy" :src="item.img"/>
                      </div>
                      <div class="titleDesign">
                        <div class="title1">{{item.title1}}</div>
                        <div class="title1" style="margin-top: 25px">{{item.title2}}</div>
                        <div class="title2" v-html="item.title3"></div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
              <!--          <el-button class="arrowButtonleft" @click="leftnext" :disabled="currentIndex === 0">-->
              <!--            <i class="el-icon-arrow-left"></i>-->
              <!--          </el-button>-->
              <!--          <el-button class="arrowButtonright" @click="rightnext" :disabled="currentIndex === success.length - 1">-->
              <!--            <i class="el-icon-arrow-right"></i>-->
              <!--          </el-button>-->
            </div>

          </div>
          <bottomTip></bottomTip></div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <resultTip
            :dialogVisible="dialogVisible"
            @hideDialog="hideDialog"
        ></resultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel" ref="totalModel">
        <div class="makeHole1">
        <kefuMobile @topMobile="topTopmobile"></kefuMobile>
          <div class="background">
            <!--      logo  you气通GPT社区 合作伙伴  登录-->
            <headtip @showDialog="showPartner"></headtip>
            <!--     文字-->
            <div class="titlle1">地层监测解决方案</div>
            <div class="title4">*该解决方案是现场的油气开发技术服务与人工智能模型融合，通过现场的解决方案为客户提供服务，如需对该方案进一步了解合作，请随时与我们联系。</div>
            <div class="titlle3" @click="dialogVisible=true">
              解决方案咨询
            </div>


          </div>
          <div class="screenmake0">
            <div class="heighLight">地层监测服务总览</div>
            <div class="heightLight2">安东深地智光数据技术公司以定制化、系统化为特色，提供油气开发全过程数据采集及解释服务，业务包括光纤监测技术、数字岩石技术、油气藏监测技术、测井技术</div>

            <div class="heightLight3">地层监测</div>
            <div class="line"></div>
            <div class="line0"></div>
            <div class="grid0">
              <div class="card">
                <div class="heightLight3">光纤监测技术</div>
              </div>
              <div class="card">
                <div class="line"></div>
                <div class="heightLight3">数字岩石技术</div>
              </div>
              <div class="card">
                <div class="line"></div>
                <div class="heightLight3">油气藏检测技术</div>
              </div>
              <div class="card">
                <div class="heightLight3">测井技术</div>
              </div>


            </div>

          </div>
          <div class="screenmake21" >
            <div class="heighLight" >业务痛点与挑战</div>
            <div class="line"></div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/realtime.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">缺乏实时、精准的井筒数据
                    </div>
                    <div class="title2">
                      无法及时获取温度、压力和声波等关键数据，难以及时响应井下变化。
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/foucs.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">漏点难以及时发现和精确定位
                    </div>
                    <div class="title2">
                      增加安全和环境风险，影响维修效率和成本控制。
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/select.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">井筒完整性风险难以监测
                    </div>
                    <div class="title2">
                      影响安全和运营，无法及时发现井筒泄露和结构损坏等问题。
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/Intelligence.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">缺乏智能分析工具
                    </div>
                    <div class="title2">
                      难以从大量数据中提取有价值的洞察，无法优化生产制度，导致采收率低下。
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/cost.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">维护和运营成本高
                    </div>
                    <div class="title2">
                      问题无法及时发现和处理，导致维修成本和停机时间增加。
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/monitor.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">传统监测手段不足
                    </div>
                    <div class="title2">
                      无法满足高效、安全、智能化的油气生产监测需求，影响整体运营效率。
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake">
            <div class="heighLight">
              <span style="background-color: #FF6400;border-radius: 50%; width:21px;font-size:21px;height:21px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 3px   ">1</span>
              <span>&nbsp;&nbsp;储气库井筒完整性光纤实时动态智能监测</span>
            </div>
            <div class="Light">部署智能光纤监测系统，对井筒泄露、环空保护液面异常、管柱震动、井下压力及注采生产动态进行实时监测和预警</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">环空保护液位面深度动态监测图</div>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">漏点实时监测图</div>
                  </div>
                </div>

              </div>

            </div>

          </div>
          <div class="screenmake2">
            <div class="heighLight">
              <span style="background-color: #FF6400;border-radius: 50%; width:21px;font-size:21px;height:21px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 3px   ">2</span>
              <span>&nbsp;&nbsp;光纤漏点智能监测</span>
            </div>
            <div class="Light">光纤漏点智能监测包括实时监测分布式温度声波信号、低频应变信号，漏点异常监测预警及定位，管柱震动评价和漏点原因分析</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">钢丝光纤漏点实时监测</div>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">钢丝光纤漏点实时监测</div>
                  </div>
                </div>

              </div>

            </div>

          </div>
          <div class="screenmake3">
            <div class="heighLight">
              <span style="background-color: #FF6400;border-radius: 50%; width:21px;font-size:21px;height:21px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 3px   ">3</span>
              <span>&nbsp;&nbsp;光纤智能油气生产监测</span>
            </div>
            <div class="Light">部署智能光纤监测系统，对生产时的井筒温度剖面、声波震动信号及井下压力实时监测，智能分析解释获得层位产出数据，指导优化生产制度，提高采收率</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">光纤产出剖面综合解释图</div>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">光纤产出剖面综合解释图</div>
                  </div>
                </div>

              </div>

            </div>

          </div>
          <div class="screenmake4">
            <div class="heighLight">
              <span style="background-color: #FF6400;border-radius: 50%; width:21px;font-size:21px;height:21px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 3px   ">4</span>
              <span>&nbsp;&nbsp;光纤智能压裂监测</span>
            </div>
            <div class="Light">部署智能光纤监测系统，对压裂过程中的段簇进液进砂、裂缝发育、压裂冲击情况实时动态监测，评估压裂效果</div>

            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">智能压裂监测微地震监测</div>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">段簇改造监测</div>
                  </div>
                </div>


              </div>

            </div>

          </div>
          <div class="screenmake5">
            <div class="heighLight">成功案例</div>
            <div class="grid">
              <el-carousel indicator-position="outside" :current-index="currentIndex" arrow="always" interval="5000">
                <el-carousel-item v-for="(item, index) in success" :key="index">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img loading="lazy" :src="item.img"/>
                      </div>
                      <div class="titleDesign">
                        <div class="title1">{{item.title1}}</div>
                        <div class="title1">{{item.title2}}</div>
                        <div class="title2" v-html="item.title3"></div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
              <!--          <el-button class="arrowButtonleft" @click="leftnext" :disabled="currentIndex === 0">-->
              <!--            <i class="el-icon-arrow-left"></i>-->
              <!--          </el-button>-->
              <!--          <el-button class="arrowButtonright" @click="rightnext" :disabled="currentIndex === success.length - 1">-->
              <!--            <i class="el-icon-arrow-right"></i>-->
              <!--          </el-button>-->
            </div>

          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <resultTip
            :dialogVisible="dialogVisible"
            @hideDialog="hideDialog"
        ></resultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import kefu from "@/views/kefu.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import bottomTip from "@/views/bottomTip.vue";
import resultTip from "@/components/ResultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {PartnerTip, resultTip, headtip,bottomTip,kefu,kefuMobile},

  data() {
    return {
      changeVisable:1,
      pageTitle: '地层监测解决方案_油气开发作业GPT应用项目服务-油气通GPT',
      pageDescription: '安东深地智光数据技术公司以定制化、系统化为特色，提供油气开发全过程数据采集及解释服务，业务包括光纤监测技术、数字岩石技术、油气藏监测技术、测井技术。',
      pageKeywords: '地层监测，光纤监测技术，数字岩石技术，油气藏检测技术，测井技术，油气开发作业GPT，油气通GPT',
 scrollback:false,
      model:false,
      dialogconsult:false,
      dialogVisible:false,
      currentIndex: 0 ,
      success:[{
        img:require("@/assets/success9.jpg"),
        title1:"光纤漏点监测GPT",
        title2:"DAS分析",
        title3:"根据温度、声音信息，准确判断漏点位置。",
             },{
        img:require("@/assets/success10.png"),
        title1:"智能压裂GPTs系统",
        title2:"DTS分析",
        title3:"依据声音强度和频带信息，评价漏点形状及大小，并给出漏点修复方案建议。",

      },
      ]
    }

  },

  methods: {     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    topTop() {
      console.log("fuzujianchuanzhi")
      const element = this.$refs.total;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    leftnext() {
      if (this.currentIndex > 0) {
        this.currentIndex--; // 减少索引以向左移动
      } else {
        // 如果 currentIndex 已经是0，则将其设置为最后一个索引
        this.currentIndex = this.success.length - 1;
      }
    },
    rightnext() {
      if (this.currentIndex < this.success.length - 1) {
        this.currentIndex++; // 增加索引以向右移动
      } else {
        // 如果 currentIndex 已经是最后一个索引，则将其设置为0
        this.currentIndex = 0;
      }
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    handleScroll() {
      if(this.model===false){
        this.scrollHeight = this.$refs.total.scrollTop;
      }
      else{
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if( this.scrollHeight<100){
        this.scrollTrue=true
      }
      else{
        this.scrollTrue=false
      }
      if( this.scrollHeight<50){
        this.scrollback=false
      }
      else{
        this.scrollback=true
      }
      console.log("gaodu",this.scrollHeight<100)
    },
  },
  beforeDestroy() {

    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);

    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);

    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }

  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '地层监测解决方案_油气开发作业GPT应用项目服务-油气通GPT',
        pageDescription: '安东深地智光数据技术公司以定制化、系统化为特色，提供油气开发全过程数据采集及解释服务，业务包括光纤监测技术、数字岩石技术、油气藏监测技术、测井技术。',
        pageKeywords: '地层监测，光纤监测技术，数字岩石技术，油气藏检测技术，测井技术，油气开发作业GPT，油气通GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .topback{
      position: fixed;
      z-index:999999999999;
      width: 100vw;
      padding: 0 0 0 2.86077411900635vw;
    }
    .background{
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      width: 100%;
      height: 72.41285537700865vh;
      background-image: url("../../assets/menage1.jpg");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 15.09270704573548vh;
        text-align: center;
        font-size: 3.0151935298vw;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }
      .title4 {
        text-align: center;
        margin: 8.82076637824475vh auto 0 auto;
        width: 38.45869439630272vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 8.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      } .titlle3:hover {

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }

    }
    .screenmake21 {
      padding: 7.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;
      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Roboto,sans-serif;
        line-height: 4.23733003708282vh;
      }
      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Roboto,sans-serif;
      }
      .line{
        height: 1vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);
        border-bottom: dashed black 1px;
        position: absolute;
        top:11vh;
        z-index: 1;
      }
      .grid {
        z-index: 2;
        margin-top: 0vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) ;
        column-gap: 2vw;
        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 5vh;
              height: 5vh;
              margin:0 auto 2vh auto;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 auto;
              color: black;
              float: right;

              .title1 {
                margin-top: 0;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: left;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake0{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 8.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .heightLight3{
        margin: 3.78739184177998vh auto 0 auto;
        //cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1709416522vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #2f6eff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 2.1989601386vw;
        height:7.5624227441vh;
        background: #B3D0FF;
        border: #B3D0FF 1px solid;
      }
      .line{
         height: 5.831891223733vh;
         width: 4px;
         left: 50%;
        margin:0 auto;
         background-color: #B3D0FF;
         margin:0 auto;
       }
      .line0{
        width:45.7vw;
        border-top-right-radius: 63px;
        border-top-left-radius: 63px;
        border-top:  #B3D0FF 4px solid;
        border-left:  #B3D0FF 4px solid;
        border-right:  #B3D0FF 4px solid;;
        height: 5.831891223733vh;
        margin:0 auto;
      }
      .grid0{
        display: grid;
        width: 60vw;
        margin:0 auto;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 1vw;
        .card{
          position: relative;
          margin: 0 auto;
          text-align: center;
          .line{
            position: absolute;
            height:5.831891223733vh;
            width: 4px;
            left: 50%;
            top:-5.831891223733vh;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }

          .heightLight3{
            margin:0 auto;

            // cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.1709416522vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2f6eff;
            line-height: 3.3374536465vh;
            text-align: center;
            border-radius: 53px;
            width: fit-content;
            padding: 2.4624227441vh 2.1989601386vw;
            height:8.5624227441vh;
            background: transparent;
            border: #B3D0FF 4px solid;
          }
        }


      }
      .grid{
        margin-top:4.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 1vw;
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:30.72311495673671vh ;
            background: #2F6EFF linear-gradient(to right, #CFE1FF 0%, #F0F6FF 100%);
            display: flex;

            .titleDesign{
              .Title1{
                text-align: center;
                margin:5.8124845488vh 2.5597920277vw 1.1124845488vh 2.155401502vw;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin:4.8124845488vh 2.5597920277vw 1.1124845488vh 2.155401502vw;
                text-align: justify;
                height: 4.6971569839vh;
                font-size:0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                //color:#2F6EFF;
                line-height: 3.194066749vh;
              }
            }
          }



        }
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background: #FFFFFF;
            margin-bottom: 1.5248454883vh;
            .imgDesign{
              text-align: center;
              height:54vh;
              width: 100%;
              background-image: url("../../assets/serve1.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              background-color: #2F6EFF;
              .Title1{
                color: white;
                text-align: center;
                font-size: 1.1709416522vw;

                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
              }

            }
            .backconsult{
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left:5.55414091470952vw ;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }

        }

      }
    }
    .screenmake{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .Light{
        margin:0 auto;
        text-align: center;
        height: 8.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }.grid{
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap:2.21317157712305vw ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:40.19653893695921vh ;
            background: #FFFFFF ;
            .imgDesign{
              width: 100%;
              height:30vh;
              opacity: 1;border:1px solid blue;
              background-image: url("../../assets/menage2.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 3.2124845488vh 5.5597920277vw 1.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                text-align: justify;
                margin-top: 3.8124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 2.7194066749vh;
              }
            }
          }
        }

        .card1{
          // cursor: pointer;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:40.19653893695921vh ;
            background: #FFFFFF ;
            .imgDesign{
              width: 100%;
              height:30vh;
              opacity: 1;
              border:1px solid blue;
              background-image: url("../../assets/menage3.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 3.2124845488vh 5.5597920277vw 1.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                text-align: justify;
                margin-top: 3.8124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 2.7194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake2{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .Light{
        margin:0 auto;
        text-align: center;
        height: 8.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap:2.21317157712305vw ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          width: fit-content;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            background: #FFFFFF ;
            .imgDesign{
              width: 35vw;
              height: 55vh;
              opacity: 1;
              border:1px solid blue;
              background-image: url("../../assets/menage4.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 3.2124845488vh 5.5597920277vw 3.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin-left: 1vw;
                margin-top: 2.8124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card1{
          // cursor: pointer;
          height:fit-content ;
          width: fit-content;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            background: #FFFFFF ;
            .imgDesign{
              width: 35vw;
              height: 56vh;
              opacity: 1;
              border:1px solid blue;
              background-image: url("../../assets/menage4.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 3.2124845488vh 5.5597920277vw 3.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin-left: 10vw;
                margin-top: 2.8124845488vh;

                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake3{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height:fit-content;
      background:#ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .Light{
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap:2.21317157712305vw ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:45.19653893695921vh ;
            background: #FFFFFF ;
            .imgDesign{
              width: 100%;
              height: 35vh;
              opacity: 1;
              border:1px solid blue;
              background-image: url("../../assets/menage10.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 3.2124845488vh 5.5597920277vw 1.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin-left: 1vw;
                margin-top: 2.8124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card1{
          // cursor: pointer;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:45.19653893695921vh ;
            background: #FFFFFF ;
            .imgDesign{
              width: 100%;
              height: 35vh;
              opacity: 1;
              border:1px solid blue;
              background-image: url("../../assets/menage9.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 3.2124845488vh 5.5597920277vw 1.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin-left: 10vw;
                margin-top: 2.8124845488vh;

                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake4{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .Light{
        margin:0 auto;
        text-align: center;
        height: 8.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        //margin-top:3.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap:2.21317157712305vw ;
        .card{
          border-radius: 9px;
          // cursor: pointer;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:42.19653893695921vh ;
            background: #FFFFFF ;
            border-radius: 9px;
            .imgDesign{
              width: 100%;
              height: 33.06056860321384vh;
              opacity: 1;
              border:1px solid blue;
              background-image: url("../../assets/menage11.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 3.2124845488vh 5.5597920277vw 1.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                text-align: justify;
                margin-top: 3.8124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 2.7194066749vh;
              }
            }
          }
        }

        .card1{
          // cursor: pointer;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:42.19653893695921vh ;
            background: #FFFFFF ;
            .imgDesign{
              width: 100%;
              height: 33.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/menage12.jpg");
              background-position: center;
              background-size:cover;
              border:1px solid blue;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 3.2124845488vh 5.5597920277vw 1.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1.1709416522vw;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                text-align: justify;
                margin-top: 3.8124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 2.7194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake5{
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;
      //background-color: #ebf1fd;
      .heighLight{
        color:white;
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        .card{
          //// cursor: pointer;
          margin:0 auto;
          height:65vh;
          width: 86%;
          padding:0.98887515451174vh 0 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:64vh;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: fit-content;
              height:100%;
              opacity: 1;

              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              color: white;
              width: 30vw;
              margin:auto;
              float: right;
              .title1{
                font-size: 1.1709416522vw;
              }
              .title2{
                margin-top:25px;
                line-height: 4vh;
                font-size: 0.9243212016vw;
              }
              .success{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                column-gap: 1vw;
                margin-top: 2vh;
                .suc1{
                  width: 100%;
                  height: 8vh;
                  .title3{
                    text-align: center;
                    margin-top: 2vh;
                    font-size: 0.8243212016vw;
                  }

                }

              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:68vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 550px;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
          // cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }
  }

}
.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .background{
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 460px;
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      background-image: url("../../assets/gptModel71.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin:50px auto;
        width: fit-content;
        text-align: center;
        height: 23px;
        font-size: 23px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #111111;
        line-height: 8.2373300371vh;
      }
      .titlle3{
        position: absolute;
        bottom:75px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }
      .title4{
        position: absolute;
        left:10%;
        bottom:-35px;
        margin: 35px auto 0 auto;
        width: 80%;
        height: fit-content;
        font-size: 12px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 18px;
        border-radius: 15px;
        padding: 15px;
        background:white;
        border: #e8eefa 1px solid;
      }
    }
    .screenmake21{
      width: 100vw;
      height: fit-content;
      padding:15px  4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            width: 90%;
            margin: 0 0 0 15px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 10px 0 10px;
            height:fit-content;
            display: flex;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake0{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:50px 4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .line{
        height: 25px;
        width: 4px;
        left: 50%;
        margin:0 auto;
        background-color: #B3D0FF;
      }
      .heightLight3{
        margin: 0 auto 0 auto;
        // cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size:16px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #2f6eff;
        line-height: 22px;
        text-align: center;
        border-radius: 9px;
        width: fit-content;
        padding: 10px 15px;
        height:40px;
        background: #B3D0FF;
        border: #B3D0FF 1px solid;
      }
      .line0{
        width: 245px;
        border-top-right-radius: 63px;
        border-top-left-radius: 63px;
        border-top:  #B3D0FF 4px solid;
        border-left:  #B3D0FF 4px solid;
        border-right:  #B3D0FF 4px solid;;
        height: 35px;
        margin:0 auto;
      }
      .grid0{
        width: 350px;
        margin:0 auto;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 5px;

        .card{
          position: relative;
          margin: 0 auto;
          text-align: center;
          .line{
            position: absolute;
            height: 35px;
            width: 4px;
            left: 50%;
            top:-35px;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }
          .line1{
            position: absolute;
            height: 75px;
            width: 4px;
            left: 50%;
            top:-75px;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }
          .heightLight3{
            margin:0 auto;

            // cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 8px;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #2f6eff;
            line-height: 16px;
            text-align: center;
            border-radius: 9px;
            width: fit-content;
            padding: 5px 5px;
            height:45px;
            background: transparent;
            border: #B3D0FF 4px solid;
          }
        }


      }

    }
    .screenmake{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:   #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 26px;

      }

      .Light{
        margin:42px 2vw 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:white;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              border:1px solid #2537a8;
              height:155px;
              width: 100%;
              background-image: url("../../assets/menage2.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
                height: 20px;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:white;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              border:1px solid #2537a8;
              height:155px;
              width: 100%;
              background-image: url("../../assets/menage3.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
                height: 20px;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
      }
    }
    .screenmake2{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:   white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 26px;

      }

      .Light{
        margin:19px 2vw 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:#ebf1fd;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              border:1px solid #2537a8;
              height:285px;
              width: 100%;
              background-image: url("../../assets/menage4.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
                height: 20px;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:#ebf1fd;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              border:1px solid #2537a8;
              height:285px;
              width: 100%;
              background-image: url("../../assets/menage5.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
                height: 20px;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
      }
    }
    .screenmake3{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:   #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 26px;

      }

      .Light{
        margin:19px 2vw 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:white;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              border:1px solid #2537a8;
              height:225px;
              width: 100%;
              background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
                height: 20px;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:white;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              border:1px solid #2537a8;
              height:185px;
              width: 100%;
              background-image: url("../../assets/menage7.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
                height: 20px;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
      }
    }
    .screenmake4{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:   white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FF6400;
        line-height: 26px;

      }

      .Light{
        margin:19px 2vw 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:#ebf1fd;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              border:1px solid #2537a8;
              height:185px;
              width: 100%;
              background-image: url("../../assets/menage8.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
                height: 20px;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:#ebf1fd;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              border:1px solid #2537a8;
              height:185px;
              width: 100%;
              background-image: url("../../assets/menage9.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
                height: 20px;
                font-size: 16px;
                font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:14px;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
      }
    }
    .screenmake5{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding:20px 15px;
      background: #212a3c;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;

        .card{
          // cursor: pointer;
          width: 98%;
          margin:0 auto;
          min-height: 250px ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            min-height: 360px ;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:254px;
              opacity: 1;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 15px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:15px;
                font-size: 14px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:12px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 430px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -68px -10px  ;
          // cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}
</style>
